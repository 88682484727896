export var StorageType;
(function (StorageType) {
    StorageType[StorageType["local"] = 0] = "local";
    StorageType[StorageType["session"] = 1] = "session";
})(StorageType || (StorageType = {}));
function getStorageTarget(type) {
    return type === StorageType.local ? window.localStorage : window.sessionStorage;
}
function setStorage(key, value, concat, type = StorageType.local) {
    if (!value || !key)
        return null;
    let res;
    const storage = getStorageTarget(type);
    if (concat) {
        let v, putValue;
        const oldData = storage.getItem(key);
        if (oldData) {
            v = JSON.parse(oldData);
        }
        else {
            v = [];
        }
        if (!Array.isArray(value)) {
            putValue = [value];
        }
        else {
            putValue = value;
        }
        const t = Array.isArray(v) ? v.concat(putValue) : putValue;
        res = storage.setItem(key, getStorageValue(t));
    }
    else {
        res = storage.setItem(key, getStorageValue(value));
    }
    return res;
}
function getStorageValue(value) {
    return JSON.stringify(value);
}
function getStorage(key, type = StorageType.local) {
    const storage = getStorageTarget(type);
    const item = storage.getItem(key);
    return item != null ? JSON.parse(item) : item;
}
function removeStorage(key, type = StorageType.local) {
    const storage = getStorageTarget(type);
    storage.removeItem(key);
}
export { getStorage, setStorage, removeStorage };
