import commonMixIn from "./../common.mixin"

import Collection from "./Collection"
import CollectionMixIn from "~@/components/functions/collection.mixin"

export default {
    mixins: [commonMixIn, CollectionMixIn],
    props: {
        data: {}
    },
    components: {
        Collection
    },
    data() {
        return {}
    },
    computed: {
        getContainerStyle() {
            return Object.assign({}, this.data.style.floatButtonStyle.background, {
                    "borderRadius": this.data.style.floatButtonStyle.border.radius + "px",
                    "borderStyle": this.data.style.floatButtonStyle.border.style,
                    "borderWidth": this.data.style.floatButtonStyle.border.height + "px",
                    "borderColor": this.data.style.floatButtonStyle.border.color
                }
            )
        }
    },
    methods: {
        handleCollect() {
            let _info = {
                name: document.title,
                url: window.location.href.split("?")[0],
                detail: {
                    siteType: "system",
                    type: this._siteData.type === "template" ? "templatePage" : "sitePage",
                    siteId: this._siteId,
                    pageId: this._pageId,
                    dataRowId: this._siteData.type === "template" ? this._pageCMSData.id : "",
                    title: document.title,
                    name: document.title,
                    url: window.location.href.split("?")[0],
                    banner: this._pageData.image || this._siteData.siteInfo.siteData.settings.wxShareInfo.image,
                    desc: this._pageData.description
                }
            }
            let _id = this._siteData.type==='template'?`${this._pageId}_${this._pageCMSData.id}`:`${this._pageId}`
            this.collectionStatusChange({
                id: null,
                collectionId:_id,
                info: _info
            })
            // this.collectionStatusChange(null, this._pageId, _info)

        }
    },
    watch: {}
}
