<template>
    <div class="minisite-video-01">
        <div class="template01-videos00">
            <div class="minisite-video-item template01-videos00" :style="getCommonStyle">
                <div class="video-container video-item-0">
                    <basic-video :data="data.contents[0].video"
                                 :video-style="data.style.videoStyle"
                                 :video-title-style="data.style.videoTitleFontStyle"
                    ></basic-video>
                </div>
            </div>
        </div>
        <div class="template01-videos01">
            <div class="minisite-video-item template01-videos01" :style="getCommonStyle">
                <div class="video-container video-item-1">
                    <basic-video :data="data.contents[1]?data.contents[1].video:{}"
                                 :video-style="data.style.videoStyle"
                                 :video-title-style="data.style.videoTitleFontStyle"
                    ></basic-video>
                </div>
            </div>
            <div class="minisite-video-item template01-videos02" :style="getCommonStyle">
                <div class="video-container video-item-2">
                    <basic-video :data="data.contents[2]?data.contents[2].video:{}"
                                 :video-style="data.style.videoStyle"
                                 :video-title-style="data.style.videoTitleFontStyle"
                    ></basic-video>
                </div>
            </div>
        </div>
        <!--        <div class="minisite-video-item" v-for="(item,itemIndex) in data.content" :key="item.id"-->
        <!--             :style="{width:data.style.common.width+'%'}">-->
        <!--            <div class="main-item-content" :style="getCommonStyle">-->
        <!--                <div class="video-wrapper"-->
        <!--                     :style="{width:data.style.videoStyle.width+'%'}">-->
        <!--                    <div class="template-01-video-00">-->
        <!--                        <div class="video-container" :class="'video-item-' + itemIndex">-->
        <!--                            <basic-video :data="item.video"-->
        <!--                                         :video-style="data.style.videoStyle"-->
        <!--                                         :video-title-style="data.style.videoTitleFontStyle"-->
        <!--                            ></basic-video>-->
        <!--                        </div>-->
        <!--                    </div>-->

        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
    import listMixin from './videos.mixin'

    export default {
        name: "VideosTemplate01",
        mixins: [listMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less">

    .minisite-video-01 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .minisite-video-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .video-container {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                overflow: hidden;
            }
        }

        .main-item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .videos-wrapper {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .template01-videos00, .template01-videos01 {
            width: 50%;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .template01-videos00 {
            padding-bottom: 0 !important;

            .minisite-video-item {
                height: 100%;
            }

            .minisite-video-container {
                height: 100%;

                .minisite-video {
                    flex-grow: 1;
                }
            }
        }


        .template01-videos01 {
            .minisite-video-item {
                /*height: 50%;*/
                flex-grow: 1;
                flex-shrink: 0;
                /*max-height: 50%;*/
                overflow: hidden;

            }
        }

        .template01-videos02 {
            padding-bottom: 0 !important;
        }
    }

</style>
