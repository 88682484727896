<template>
    <div class="inner-list" v-if="data && data.children">
        <template v-for="(listItem,listItemIndex) in data.children">
            <inner-component-item
                type="page"
                :item-index="listItemIndex"
                :data="listItem"
                :key="`${data.id}_${listItemIndex}`"
                :data-source="dataSource"
                :hier-detail="data.detail"
                :hier-id="data.id"
                :search-word="searchWord"></inner-component-item>
        </template>
    </div>
</template>

<script>
    import InnerComponentItem from "./InnerComponentItem"
    import _cloneDeep from "lodash/cloneDeep"

    export default {
        name: "InnerList",
        mixins: [],
        props: {
            data: {},
            dataPath: {},
            dataId: {},
            dataSource: {},
            searchWord: {}
        },
        components: {
            InnerComponentItem
        },
        data() {
            return {
            }
        },
        created() {

        },
        computed: {},
        methods: {},
        watch: {}
    }
</script>

<style lang="less" scoped>


</style>
