const GetQueryString = function (name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
    let r = window.location.search.substr(1).match(reg)
    if (r != null) {
        return unescape(r[2])
    }
    return null
}

const ReplaceParamVal = function (paramName, replaceWith, link) {
    let url = link || window.location.href.toString()
    let newUrl = ''
    let pattern = paramName + '=([^&]*)'
    let replaceText = paramName + '=' + encodeURIComponent(replaceWith)
    if (url.match(pattern)) {
        newUrl = '/(' + paramName + '=)([^&]*)/gi'
        newUrl = url.replace(eval(newUrl), replaceText)
    } else {
        if (url.indexOf('?') > -1) {
            newUrl = url + '&' + replaceText
        } else {
            newUrl = url + '?' + replaceText
        }
    }
    return newUrl
}

export {
    GetQueryString,
    ReplaceParamVal
}
