import {BasicText, BasicImage} from './../basic/index'
import commonMixIn from './../common.mixin'

export default {
    mixins: [commonMixIn],
    components: {
        BasicText,
        BasicImage
    },
    computed: {
        getImageContainerStyle() {
            return Object.assign(
                {},
                {
                    ...this.getPadding(this.data.style.common.margin)
                }
            )
        },
        getCommonStyle() {
            return Object.assign({},
                this.data.style.common,

                this.getBackground(this.data.style.common.background),
                this.getPadding(this.data.style.common.padding),
                {
                    width: this.data.style.common.width + '%',
                    borderRadius: (this.data.style.common && this.data.style.common.borderRadius ? this.data.style.common.borderRadius : 0) + 'px',
                }
            )
        }
    }
}
