<template>
    <div class="minisite-tab-item tab-00" :style="getTabStyle">
<!--        {{_pageId}}-->
        <template v-for="(tabItem,tabIndex) in data.contents">
            <click-action :data="tabItem.clickAction" :key="tabItem.id" class="tab-item"
                          :class="{'active':_pageId && tabItem.clickAction.target===_pageId}"
                          :style="[getTabComponentStyle]">
                <div class="tab-icon-container">
                    <img class="tab-icon" :style="getTabIconStyle" :src="tabItem.iconActive"
                         alt="" v-if="_pageId && tabItem.clickAction.target===_pageId">
                    <img class="tab-icon" :style="getTabIconStyle" :src="tabItem.iconNormal"
                         alt="" v-else>
                </div>
                <div class="tab-title">
                    <basic-text :data="tabItem.text"
                                :font-style="data.style.activeStyle.textStyle"
                                v-if="_pageId && tabItem.clickAction.target===_pageId"
                    ></basic-text>
                    <basic-text :data="tabItem.text"
                                :font-style="data.style.normalStyle.textStyle"
                                v-else
                    ></basic-text>
                </div>
            </click-action>
            <div v-if="tabIndex < data.contents.length-1" class="divide-line"
                 v-show="getShowedComponent.divideLine"
                 :style="getDivideLineStyle"
                 :key="'divide-' + tabItem.id"></div>
        </template>
    </div>
</template>

<script>
    import tabMixin from './tab.mixin'

    export default {
        name: "Template-00",
        mixins: [tabMixin],
        props: {
            data: {}
        },
        data() {
            return {}
        },
        computed: {},
        mounted() {

        }
    }
</script>

<style lang="less">

    .tab-00 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        position: relative;

        .tab-item {
            height: 100%;
            flex: 1;
            flex-shrink: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            overflow: hidden;
            /*.minisite-link {*/

            /*}*/
        }

        .tab-title {
            flex-shrink: 0;
            vertical-align: bottom;
        }

        .tab-icon-container {
            flex-grow: 2;
            flex-shrink: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .tab-icon {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .divide-line {
            height: 100%;
            border-right-style: none !important;
        }

    }

</style>
