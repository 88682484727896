<template>
    <div class="minisite-image-02" v-show="isReady">
        <div class="group-item" v-for="(item,itemIndex) in imageList" :key="itemIndex" :style="{
                    marginBottom: itemIndex<imageList.length?imageSpace:0
                }">
            <div class="first-col col-item" :style="{marginBottom: item.length>3?imageSpace:0}">
                <div class="template02-images-group00" :style="{
                    marginRight: item.length>1?imageSpace:0
                }">
                    <div class="minisite-image-item template02-images00" :style="getCommonStyle">
                        <div class="image-container image-item-0">
                            <click-action :data="item[0].image.clickAction">
                                <basic-image :data="item[0].image"
                                             :image-style="data.style.imageStyle"
                                             :image-title-style="data.style.imageTitleFontStyle"
                                             image-fit="cover"
                                ></basic-image>
                            </click-action>
                        </div>
                    </div>
                </div>
                <div class="template02-images-group01" v-if="item.length>1">
                    <div class="minisite-image-item template02-images01" :style="[getCommonStyle,
                    {marginBottom: item.length>2?imageSpace:0}]">
                        <div class="image-container image-item-1">
                            <click-action :data="item[1].image.clickAction">
                                <basic-image :data="item[1].image"
                                             :image-style="data.style.imageStyle"
                                             :image-title-style="data.style.imageTitleFontStyle"
                                ></basic-image>
                            </click-action>
                        </div>
                    </div>
                    <div class="minisite-image-item template02-images02" :style="[getCommonStyle,{paddingRight:0}]"
                         v-if="item.length>2">
                        <div class="image-container image-item-2">
                            <click-action :data="item[2].image.clickAction">
                                <basic-image :data="item[2].image"
                                             :image-style="data.style.imageStyle"
                                             :image-title-style="data.style.imageTitleFontStyle"
                                             image-fit="cover"
                                ></basic-image>
                            </click-action>
                        </div>
                    </div>
                </div>
            </div>
            <div class="second-col col-item" v-if="item.length>3">
                <div class="template02-images-group02" :style="{
                                   marginRight: item.length>5?imageSpace:0,
                                   flexDirection: item.length===5?'row':'column',
            }">
                    <div class="minisite-image-item template02-images01" :style="[
                        getCommonStyle,
                        {
                                   marginBottom: item.length>5?imageSpace:0,
                                   marginRight: item.length>4?imageSpace:0,
                                   width:  item.length===5?'50%':'100%',
                                   flexShrink:item.length>5?0:1
            }]">
                        <div class="image-container image-item-1">
                            <click-action :data="item[3].image.clickAction">
                                <basic-image :data="item[3].image"
                                             :image-style="data.style.imageStyle"
                                             :image-title-style="data.style.imageTitleFontStyle"
                                             image-fit="cover"
                                ></basic-image>
                            </click-action>
                        </div>
                    </div>
                    <div v-if="item.length>4" class="minisite-image-item template02-images02"
                         :style="[
                             getCommonStyle,
                             {
                                 paddingRight:0,
                                 width: item.length===5?'50%':'100%',
                                 flexShrink:item.length>5?0:1
                             }
                         ]"
                    >
                        <div class="image-container image-item-2">
                            <click-action :data="item[4].image.clickAction">
                                <basic-image :data="item[4].image"
                                             :image-style="data.style.imageStyle"
                                             :image-title-style="data.style.imageTitleFontStyle"
                                             image-fit="cover"
                                ></basic-image>
                            </click-action>
                        </div>
                    </div>
                </div>

                <div class="template02-images-group03" v-if="item.length>5">
                    <div class="minisite-image-item template02-images00" :style="getCommonStyle">
                        <div class="image-container image-item-0">
                            <click-action :data="item[5].image.clickAction">
                                <basic-image :data="item[5].image"
                                             :image-style="data.style.imageStyle"
                                             :image-title-style="data.style.imageTitleFontStyle"
                                             image-fit="cover"
                                ></basic-image>
                            </click-action>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import imagesMixin from "./images.mixin"

    export default {
        name: "ImagesTemplate02",
        mixins: [imagesMixin],
        props: {
            data: {}
        },
        data() {
            return {
                isReady: false
            }
        },
        mounted() {
            setTimeout(() => {
                this.isReady = true
            }, 100)
        },
        computed: {
            imageList() {
                let _res = []
                let num = 6

                for (let i = 0; i < this.data.contents.length; i += num) {
                    _res.push(this.data.contents.slice(i, i + num))
                }
                return _res
            },
            imageSpace() {
                return `${this.data.style.common.space}px`
            }
        }
    }
</script>

<style lang="less">

    .minisite-image-02 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .group-item {
            width: 100%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .col-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .minisite-image-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            overflow: hidden;

            .image-container {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                overflow: hidden;
            }
        }

        .minisite-link {
            height: 100%;
            width: 100%;
        }

        .main-item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .images-wrapper {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .template02-images-group00, .template02-images-group01, .template02-images-group02, .template02-images-group03 {
            width: 50%;
            flex-grow: 1;
            flex-shrink: 1;
        }

        .template02-images-group00, .template02-images-group03 {
            //padding-bottom: 0 !important;
            &.right {
                order: 2;
            }

            .minisite-image-item {
                height: 100%;
            }

            .minisite-image-container {
                height: 100%;

                .minisite-image {
                    flex-grow: 1;
                }
            }
        }

        .template02-images-group01,
        .template02-images-group02 {
            display: flex;
            flex-direction: column;

            .minisite-image-item {
                flex-grow: 1;

                .minisite-image-container {
                    width: 100%;
                    height: 100%;
                }

                .minisite-image {
                    width: 100%;
                    height: 100% !important;
                }
            }
        }


        .template02-images01 {
            &.minisite-image-item {
                flex-grow: 1;
                flex-shrink: 0;
            }
        }

        .template02-images02 {
            padding-bottom: 0 !important;
        }
    }

</style>
