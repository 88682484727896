<template>
    <click-action v-if="!_isEmpty(cubeNavStyle)" class="cube-nav-item" :data="itemData.clickAction">
        <div
            class="main-item-content"
            :class="getCubeNavItemClass"
            :style="{
                ...getCubeNavStyle,
                ...(_has(cubeNavStyle, 'background') ? { backgroundImage: itemData.background.backgroundImage } : {}),
                backgroundColor: itemData.background.backgroundColor,
                ...patchStyle
            }">
            <div
                v-show="checkShowComponent.image && itemData.image.imageSrc"
                class="image-container"
                :style="[{ width: getImageWidth(cubeNavStyle.imageStyle) }, getImageStyle]">
                <basic-image
                    :data="itemData.image"
                    :image-style="cubeNavStyle.imageStyle"
                    :is-round="isRound"></basic-image>
            </div>
            <div class="text-container" :class="getTextContentClass">
                <div v-show="checkShowComponent.title || checkShowComponent.subtitle">
                    <div v-show="checkShowComponent.title" class="title-container">
                        <basic-text :data="itemData.title.content" :font-style="cubeNavStyle.titleStyle"></basic-text>
                    </div>
                    <div v-show="checkShowComponent.subtitle" class="desc-container">
                        <basic-text
                            :data="itemData.subtitle.content"
                            :font-style="cubeNavStyle.subtitleStyle"></basic-text>
                    </div>
                </div>
                <div v-if="checkShowComponent.button" class="button-container">
                    <BasicButtonImageCollection
                        :data="itemData.button.buttonText"
                        :button-style="cubeNavStyle.buttonStyle"></BasicButtonImageCollection>
                </div>
            </div>
        </div>
    </click-action>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import _has from "lodash/has";
    import ClickAction from "@/components/common/ClickAction.vue"

    export default {
        name: "CubeNavItem",
        mixins: [cubeMixin],
        components: {
            ClickAction
        },
        props: {
            data: {},
            itemData: {},
            patchStyle: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {}
        },
        computed: {
            // 图片是否为圆形
            isRound() {
                return !!this.cubeNavStyle.imageStyle.background?.isRound
            }
        },
        mounted() {},
        methods: {
            _has,
            _isEmpty,
            getImageWidth(style) {
                return  style.width + (style?.widthUnit ?? '%')
            }
        }
    }
</script>

<style lang="less" scoped>

    .main-item-content {
        display: flex;
        //justify-content: space-between;
        flex-grow: 1;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
    }

    .image-container {
        flex-shrink: 0;
        overflow: hidden;
    }

    .text-content-right {
        align-self: flex-end;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .text-right {
        text-align: right;
    }

    .text-content-center {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image-location-right {
        flex-direction: row-reverse;
    }

    .image-location-left,
    .image-location-right {
        &.row-start {
            justify-content: flex-start;
        }

        &.row-center {
            justify-content: center;
        }

        &.row-end {
            justify-content: flex-end;
        }

        &.row-between {
            justify-content: space-between;
        }

        &.col-start {
            align-items: flex-start;
        }

        &.col-center {
            align-items: center;
        }

        &.col-end {
            align-items: flex-end;
        }
    }

    .image-location-right {
        &.row-start {
            justify-content: flex-end;
        }

        &.row-end {
            justify-content: flex-start;
        }
    }

    .image-location-top,
    .image-location-bottom {
        flex-direction: column;

        &.row-start {
            align-items: flex-start;
        }

        &.row-center {
            align-items: center;
        }

        &.row-end {
            align-items: flex-end;
        }

        &.row-between {
            //justify-content: space-between;
            > :last-child {
                align-self: flex-end;
            }
        }

        &.col-start {
            justify-content: flex-start;
        }

        &.col-center {
            justify-content: center;
        }

        &.col-end {
            justify-content: flex-end;
        }

        &.col-between {
            justify-content: space-between;
        }
    }

    .image-location-bottom {
        flex-direction: column-reverse;

        &.row-between {
            //justify-content: space-between;
            > :first-child {
                align-self: flex-end;
            }

            > :last-child {
                align-self: flex-start;
            }
        }

        &.col-start {
            justify-content: flex-end;
        }

        &.col-end {
            justify-content: flex-start;
        }
    }
</style>
