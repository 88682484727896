<template>
    <div class="minisite-float-button">
        <template-00 v-if="data.style.templateId === '00'" :data="data"></template-00>
        <template-01 v-if="data.style.templateId === '01'" :data="data"></template-01>
    </div>
</template>
<script>
    import floatButtonTemplate from './floatButton'

    export default {
        name: "float-buttonComponent",
        props: {
            data: {}
        },
        components: {
            'template-00': floatButtonTemplate.Template00,
            'template-01': floatButtonTemplate.Template01
        }
    }
</script>

<style lang="less" scoped>

    .minisite-float-button {
        width: 100%;
        height: 100%;
    }

</style>
