var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"langs-list template-00"},_vm._l((_vm.langs.list),function(item,itemIndex){return _c('div',{key:item.id,staticClass:"langs-item",class:{}},[_c('click-action',{staticClass:"langs-link",class:{
            'current': _vm.langs.current === item.id,
            'last-item': itemIndex === _vm.langs.list.length-1
        },attrs:{"data":{
            type:'sitePages',
            content:{
                type:'langs',
                content:[
                    {
                        url:item.id
                    }
                ]
            }
        }}},[_vm._v(" "+_vm._s(item.name)+" ")]),(itemIndex < _vm.langs.list.length-1)?_c('span',{staticClass:"divide-line"}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }