import { BasicText, BasicImage, BasicButton } from "./../basic/index"
import commonMixIn from "./../common.mixin"

export default {
    mixins: [commonMixIn],
    props: {},
    data() {
        return {
            listStyle: {
                common: {}
            }
        }
    },
    components: {
        BasicText,
        BasicImage,
        BasicButton
    },
    methods: {},
    mounted() {
        this.listStyle = this.data.style
    },
    computed: {
        // getImageStyle() {
        //     return Object.assign({},
        //         this.getBackground(this.listStyle.imageStyle.background),
        //         this.getPadding(this.listStyle.imageStyle.padding)
        //     )
        // },
        getDivideStyle() {
            if (this.listStyle.divideLineStyle) {
                return {
                    "width": this.listStyle.divideLineStyle.width + "%",
                    "border-bottom-style": this.listStyle.divideLineStyle.style,
                    "border-bottom-width": this.listStyle.divideLineStyle.height + "px",
                    "border-bottom-color": this.listStyle.divideLineStyle.color,
                    "align-self": this.listStyle.divideLineStyle.alignSelf
                }
            }
            return {}
        },
        getCommonStyle() {
            if (this.listStyle.common.padding) {
                return Object.assign(
                    {},
                    this.listStyle.common,
                    this.getBackground(this.listStyle.common.background),
                    this.getPadding(this.listStyle.common.padding),
                    this.getMargin(this.listStyle.common.margin)
                )
            }
        },
        getShowedComponent() {
            return {}
        }
    }
}
