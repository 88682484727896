<template>
    <div class="minisite-button-00" :class="'template-' + listStyle.templateId"
         v-if="listStyle.common">
        <div class="minisite-button-item" v-for="item in data.contents"
             :key="item.id"
             :style="{width:listStyle.common.width+'%'}">
            <click-action class="main-item-content" :style="getCommonStyle"
                          :action-remark="item.button.buttonText"
                          :data="item.clickAction" :key="item.id">
                <div class="button-container">
                    <basic-button :data="item.button.buttonText" v-if="getShowedComponent.button"
                                  :button-style="listStyle.buttonStyle"
                    ></basic-button>
                </div>
            </click-action>
        </div>
    </div>
</template>

<script>
    import buttonMixin from './button.mixin'

    export default {
        name: "ButtonTemplate00",
        mixins: [buttonMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less">

    .minisite-button-00 {
        /*display: flex;*/
        /*flex-wrap: wrap;*/
        /*justify-content: space-between;*/

        .button-container {
            width: 100%;
        }

        &.template-00 {
            .minisite-link {
                display: flex;
            }
        }

        &.template-02 {
            .minisite-link {
                display: flex;
            }
        }

        /*.minisite-link {*/
        /*    display: flex;*/
        /*}*/

        .minisite-button-item {
            display: flex;
            flex-direction: column;
        }

        .main-item-content {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
        }


        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .right-container {
            flex-grow: 1;
            margin-left: 10px;
            vertical-align: top;

            .button-container {
                margin-top: 10px;
            }
        }

        .right-icon {
            margin-left: 10px;
            flex-shrink: 0;
        }
    }

</style>
