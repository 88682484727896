import { proxyApi } from "@/service/proxy"
import { mapState, mapActions, mapGetters } from "vuex"
import { getUtm } from "@/utils/preflight"
import commonMixIn from "./../common.mixin"

export default {
    mixins: [commonMixIn],
    computed: {
        ...mapGetters("collection", ["collectionList", "collectionIdList", "collectionIdDir"]),
        isLogin() {
            return this.WechatInfo && this.WechatInfo.openid
        }
    },
    data() {
        return {}
    },
    mounted() {
        // this.getBookmark()
    },
    methods: {
        ...mapActions("collection", ["setList", "deleteCollectionItem", "addCollectionItem"]),
        // 检查是否为会员
        async checkMember() {
            try {
                let _res = await proxyApi("membership", "CheckMember")
                return _res
            } catch (e) {
                console.log(e)
            }
        },
        collectionStatusChange({ item, collectionId, info, showedStatus }) {
            let _collectionId = collectionId || `${this._pageId}_${this.data.id}_${item.id}`
            if (this.collectionIdList.indexOf(_collectionId) > -1) {
                this.deleteBookmark(_collectionId)
            } else {
                let _formatData = info || {
                    name: item.title,
                    url: item.clickAction.content.content[0].url,
                    detail: {
                        siteType: "system",
                        siteId: this._siteId,
                        pageId: this._pageId,
                        componentId: this.data.id,
                        itemId: item.id,
                        title: showedStatus.title ? item.title : "",
                        desc: showedStatus.desc ? item.desc : "",
                        icon: item.icon.imageSrc,
                        clickAction: item.clickAction,
                        button: item.button.buttonText,
                        banner: showedStatus.image ? item.image.imageSrc : "",
                        image: showedStatus.image ? {
                            src: item.image.imageSrc,
                            alt: item.image.imageAlt,
                            title: item.image.imageTitle
                        } : {}
                    }
                }
                this.addBookmark(_formatData)
            }
        },
        getBookmark(data) {
            let _this = this
            proxyApi("membership", "GetBookmarks", {
                index: 1,
                size: 9999,
                desc: true
            }).then((data) => {
                let _res = []
                data.data.forEach((item) => {
                    if (item.detail && item.detail.siteType === "system" && item.detail.siteId === _this._siteId) {
                        _res.push(item)
                    }
                    _this.setList(_res)
                })
            })
        },
        addBookmark(data) {
            proxyApi("membership", "AddBookmark", {
                input: data
            }).then((res) => {
                this.addCollectionItem(res)
                window.cw("action", "collectview", {
                    name: document.title,
                    url: window.location.href.split("?")[0],
                    originOpenId: this.originOpenId,
                    ...getUtm(),
                    pageId: this._pageId,
                    siteId: this._siteId,
                    path: window.location.href,
                    title: document.title,
                    tagIds: this._tagIds,
                    uaId: this._UAId
                })
            })
        },
        deleteBookmark(id) {
            let _bookmarkId = this.collectionIdDir[id.toString()]
            proxyApi("membership", "DeleteBookmark", {
                BookmarkId: _bookmarkId
            }).then((res) => {
                this.deleteCollectionItem(_bookmarkId)
                window.cw("action", "uncollectview", {
                    name: document.title,
                    url: window.location.href.split("?")[0],
                    originOpenId: this.originOpenId,
                    ...getUtm(),
                    pageId: this._pageId,
                    siteId: this._siteId,
                    path: window.location.href,
                    title: document.title,
                    uaId: this._UAId,
                    tagIds: this._tagIds
                })
            })
        }
    }
}
