import commonMixIn from "@/components/common.mixin";

export default {
    mixins: [commonMixIn],
    props: {
        data: {
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            rootFontSize: 14
        }
    },
    mounted() {

    },
    components: {},
    computed: {
        getFontStyle() {
            if (this.fontStyle) {
                return Object.assign({}, this.fontStyle, {
                    // fontSize: this.fontStyle.fontSize / this.ROOTFONTSIZE + 'rem',
                    fontSize: this.fontStyle.fontSize + 'px',
                    // lineHeight: this.fontStyle.fontSize + 'px',
                    fontWeight: this.fontStyle.fontWeight ? 'bold' : 'normal',
                    paddingTop: (this.fontStyle.padding ? this.fontStyle.padding.top : 0) + 'px',
                    paddingBottom: (this.fontStyle.padding ? this.fontStyle.padding.bottom : 0) + 'px',
                    paddingLeft: (this.fontStyle.padding ? this.fontStyle.padding.left : 0) + 'px',
                    paddingRight: (this.fontStyle.padding ? this.fontStyle.padding.right : 0) + 'px',
                    // marginTop: this.data.style.common.margin.top + 'px',
                    // marginBottom: this.data.style.common.margin.bottom + 'px',
                    // marginLeft: this.data.style.common.margin.left + 'px',
                    // marginRight: this.data.style.common.margin.right + 'px',
                    // paddingTop: this.fontStyle.paddingTop + 'px',
                    // paddingBottom: this.fontStyle.paddingBottom + 'px',
                    // paddingLeft: this.fontStyle.paddingLeft + 'px',
                    // paddingRight: this.fontStyle.paddingRight + 'px',
                    lineHeight: this.fontStyle.lineHeight + 'px',
                })
            }
        },
        getImageStyle() {
            return Object.assign({}, this.imageStyle, {
                    width: this.imageStyle && this.imageStyle.imageWidth ? this.imageStyle.imageWidth + '%' : '',

                    borderRadius: (this.imageStyle && this.imageStyle.borderRadius ? this.imageStyle.borderRadius : 0) + 'px'
                },
                // this.getPadding(this.imageStyle ? this.imageStyle.padding : null)
            )
        },
        getImageItemStyle() {
            return {
                // width: this.imageStyle && this.imageStyle.imageWidth ? this.imageStyle.imageWidth + '%' : '',
                // borderRadius: (this.imageStyle && this.imageStyle.borderRadius ? this.imageStyle.borderRadius : 0) + 'px'
            }
        },
        getButtonStyle() {
            if (this.buttonStyle) {
                return Object.assign({}, this.buttonStyle, {
                    fontSize: this.buttonStyle.fontSize + 'px',
                    borderRadius: this.buttonStyle.borderRadius + 'px',
                    borderWidth: this.buttonStyle.borderWidth + 'px',
                    paddingTop: this.buttonStyle.padding.top + 'px',
                    paddingBottom: this.buttonStyle.padding.bottom + 'px',
                    paddingLeft: this.buttonStyle.padding.left + 'px',
                    paddingRight: this.buttonStyle.padding.right + 'px',
                })
            }
            return {}
        },
        getVideoStyle() {
            return {}
        }
    },
    methods: {}
}
