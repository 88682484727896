<template>
    <div class="minisite-text-item text-00 ck-content" ref="text" :style="getTextStyle"
         v-html="textContent"></div>
</template>

<script>

    export default {
        name: "Template-00",
        props: {
            data: {}
        },
        data() {
            return {
                textContent: ""
            }
        },
        computed: {
            getTextStyle() {
                let fontStyle = this.data.style.fontStyle
                if (fontStyle) {
                    return Object.assign({}, fontStyle, {
                        fontSize: fontStyle.fontSize + "px",
                        fontWeight: fontStyle.fontWeight ? "bold" : "normal",
                        paddingTop: (fontStyle.padding ? fontStyle.padding.top : 0) + "px",
                        paddingBottom: (fontStyle.padding ? fontStyle.padding.bottom : 0) + "px",
                        paddingLeft: (fontStyle.padding ? fontStyle.padding.left : 0) + "px",
                        paddingRight: (fontStyle.padding ? fontStyle.padding.right : 0) + "px"

                    })
                }
                return {}
            }
        },
        mounted() {
            this.renderText()
        },
        methods: {
            renderText() {
                let _content = this.data.contents[0].text.contents
                if (_content) {
                    _content = _content.replace(/src="https:\/\/statics.xiumi.us/g, "src=\"https://images.weserv.nl/?url=https://statics.xiumi.us")
                    _content = _content.replace(/src="https:\/\/img.xiumi.us/g, "src=\"https://images.weserv.nl/?url=https://img.xiumi.us")
                    _content = _content.replace(/src="\/\/statics.xiumi.us/g, "src=\"https://images.weserv.nl/?url=https://statics.xiumi.us")
                    _content = _content.replace(/powered-by="xiumi.us"/g, "")
                    // textRender.render(this.$refs.text, {
                    //     data: _content
                    // })
                }
                this.textContent = _content

            }
        },
        watch: {
            "data": {
                deep: true,
                handler() {
                    this.renderText()
                }
            }
        }

    }
</script>

<style lang="less">

    .minisite-text-item {
        overflow: hidden;

        .image_resized {
            width: initial;
            display: flex;
            margin: 0;

            img {
                width: 100%;
                height: initial;
            }
        }

        figure {

            &.image {
                margin: 0;
                height: fit-content;
                display: flex;
                align-items: center;

                img {
                    margin: 0 auto;
                }
            }
        }

        p {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

</style>
