<template>
    <div class="minisite-bg-music-item bg-music-00" :style="getBgMusicStyle">
        <div class="bg-music-item">
            <div class="bg-music-icon-container" :class="{'pause':!isPlaying,'playing':isPlaying}">
                <img class="bg-music-icon" @click="toggleStatus"
                     :src="data.contents.imageSrc" alt="">
                <audio :src="data.contents.audioSrc" ref="audio" loop></audio>
                <div class="is-paused" v-if="!isPlaying">
                    <div class="pause-icon"></div>
                </div>
            </div>
            <div class="bg-music-title">
                <basic-text data=""
                            :font-style="data.style.fontStyle"
                ></basic-text>
            </div>
        </div>
    </div>
</template>

<script>
    import bgMusicMixin from './bgMusic.mixin'

    export default {
        name: "Template-00",
        mixins: [bgMusicMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .bg-music-00 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /*box-shadow: 0 2px 8px #8e8e8e;*/

        .bg-music-icon-container {
            position: relative;

            .bg-music-icon {
                max-width: 100%;
                max-height: 100%;
            }

            .is-paused {
                pointer-events: none;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                overflow: hidden;
                padding: 18px 0;

                .pause-icon {
                    color: #fff;
                    transform: rotate(45deg);
                    width: 100%;
                    height: 2px;
                    background: #fff;
                }
            }

            @-webkit-keyframes rotation {

                from {
                    -webkit-transform: rotate(0deg);
                }

                to {
                    -webkit-transform: rotate(360deg);
                }

            }


            &.playing {
                .bg-music-icon {
                    animation: rotation 5s linear infinite;
                }
            }

        }

    }

</style>
