<template>
    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-06" ref="cubeNavWrapper">
        <div
            class="list-content"
            :class="'cube-nav-box-' + data.contents.length"
            :style="{ marginBottom: spacing, height: listHeight + 'px' }">
            <cube-nav-item
                v-for="(item, index) in data.contents"
                :key="item.id"
                ref="cubeNavItem"
                :data="data"
                :item-data="item"
                :style="{ width: size, height: size, ...getCubeNavSite(data.contents.length, index) }"
                :class="'cube-nav-item-0' + index"
                class="minisite-cube-nav-item">
            </cube-nav-item>
        </div>
    </div>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import CubeNavItem from "@/components/cubeNav/CubeNavItem.vue"

    export default {
        name: "Template06",
        components: { CubeNavItem },
        mixins: [cubeMixin],
        props: {
            data: {}
        },
        data() {
            return {
                listWrapperFontSize: 16,
                listHeight: 0,
                // 防止用户移动数据位置，导致获取不到正确的 top
                cubeNavSiteMap: {
                    3: {
                        "00": {
                            left: 5.75,
                            top: 0
                        },
                        "01": {
                            left: 11.75,
                            top: 6.44
                        },
                        "02": {
                            left: 11.13,
                            top: 15
                        }
                    },
                    4: {
                        "00": {
                            left: 9.44,
                            top: 0
                        },
                        "01": {
                            left: 15.94,
                            top: 3.06
                        },
                        "02": {
                            left: 1.56,
                            top: 5.31
                        },
                        "03": {
                            left: 9.56,
                            top: 7.31
                        },
                    },
                    5: {
                        "00": {
                            left: 9.44,
                            top: 0
                        },
                        "01": {
                            left: 15.94,
                            top: 3.06
                        },
                        "02": {
                            left: 1.56,
                            top: 5.31
                        },
                        "03": {
                            left: 9.56,
                            top: 7.31
                        },
                        "04": {
                            left: 15.31,
                            top: 12.44
                        },
                    },
                    6: {
                        "00": {
                            left: 9.44,
                            top: 0
                        },
                        "01": {
                            left: 15.94,
                            top: 3.06
                        },
                        "02": {
                            left: 1.56,
                            top: 5.31
                        },
                        "03": {
                            left: 9.56,
                            top: 7.31
                        },
                        "04": {
                            left: 15.31,
                            top: 12.44
                        },
                        "05": {
                            left: 13.44,
                            top: 19.81
                        }
                    }
                }
            }
        },
        computed: {
            boxSize() {
                return +this.data.style.options.componentSize / 16 * this.listWrapperFontSize
            },
            spacing() {
                return this.cubeNavStyle.gap + "px"
            },
            size() {
                return this.boxSize + "px"
            }
        },
        watch: {
            boxSize: {
                immediate: true,
                handler() {
                    this.getListHeight()
                }
            }
        },
        mounted() {
            this.init()

            window.onresize = this.getListHeight
        },
        methods: {
            _isEmpty,
            getListHeight() {
                this.$nextTick(() => {
                    this.listWrapperFontSize = +getComputedStyle(this.$refs.cubeNavWrapper).fontSize.split('px')[0]
                    const topList = this.$refs.cubeNavItem.map((vnode) => vnode.$el.style.top.split("em")[0])
                    this.listHeight = Math.max(...topList) * this.listWrapperFontSize + this.boxSize
                })
            },
            getCubeNavSite(length, index) {
                const { left=0, top=0 } = this.cubeNavSiteMap?.[length]?.["0" + index] ?? {}
                return {
                    left: left + "em",
                    top: top + "em"
                }
            },
            init() {
                this.$nextTick(() => {
                    this.listWrapperFontSize = +getComputedStyle(this.$refs.cubeNavWrapper).fontSize.split('px')[0]
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    @media (min-width: 768px) {
        .minisite-cube-nav-06 {
            font-size: 32px;
        }
    }
    @media (max-width: 768px) {
        .minisite-cube-nav-06 {
            font-size: 4.26666667vw;
        }
    }

    .minisite-cube-nav-06 {
        .list-content {
            position: relative;
            height: 322px;
        }

        .minisite-cube-nav-item {
            display: flex;
            position: absolute;
        }

        /deep/ .text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        /deep/ .main-item-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            border-radius: 100% !important;
            overflow: hidden;
            height: 100%;
        }

        .cube-nav-box-3 {
            .cube-nav-item-00 {
                left: 92px;
            }

            .cube-nav-item-01 {
                left: 188px;
                top: 92px;
            }

            .cube-nav-item-02 {
                left: 178px;
                top: 242px;
            }
        }

        .cube-nav-box-4 {
            .cube-nav-item-00 {
                left: 151px;
            }

            .cube-nav-item-01 {
                left: 255px;
                top: 50px;
            }

            .cube-nav-item-02 {
                left: 25px;
                top: 85px;
            }

            .cube-nav-item-03 {
                left: 153px;
                top: 117px;
            }
        }

        .cube-nav-box-5 {
            .cube-nav-item-00 {
                left: 151px;
            }

            .cube-nav-item-01 {
                left: 255px;
                top: 50px;
            }

            .cube-nav-item-02 {
                left: 25px;
                top: 85px;
            }

            .cube-nav-item-03 {
                left: 153px;
                top: 117px;
            }

            .cube-nav-item-04 {
                left: 245px;
                top: 200px;
            }
        }

        .cube-nav-box-6 {
            .cube-nav-item-00 {
                left: 151px;
            }

            .cube-nav-item-01 {
                left: 255px;
                top: 50px;
            }

            .cube-nav-item-02 {
                left: 25px;
                top: 85px;
            }

            .cube-nav-item-03 {
                left: 153px;
                top: 117px;
            }

            .cube-nav-item-04 {
                left: 245px;
                top: 200px;
            }

            .cube-nav-item-05 {
                left: 215px;
                top: 317px;
            }
        }
    }
</style>
