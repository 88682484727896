<template>
    <div class="langs-list template-01">
        <multiselect class="langs-select" v-model="currentLang" track-by="id" label="name"
                     :options="langs.list"
                     :searchable="false"
                     :allow-empty="false">
            <template slot="option" slot-scope="{ option }">
                <click-action class="langs-link"
                              :class="{'current': langs.current === option.id}"
                              :data="{
                                    type:'sitePages',
                                    content:{
                                        type:'langs',
                                        content:[
                                            {
                                                url:option.id
                                            }
                                        ]
                                    }
                                }">
                    {{option.name}}
                </click-action>
            </template>
        </multiselect>
    </div>
</template>

<script>
    import langsMixIn from './langs.mixin'
    import Vue from 'vue'
    import Multiselect from 'vue-multiselect'

    // register globally
    Vue.component('multiselect', Multiselect)


    export default {
        name: "Template-01",
        components: {
            Multiselect
        },
        mixins: [langsMixIn],
        computed: {
            currentLang: {
                get() {
                    return this.langs.list.filter((_ => _.id === this.langs.current))
                },
                set() {

                }
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style lang="less">
    @option-line-height: 32px;

    .multiselect {
        font-size: 14px;

        .multiselect__tags, .multiselect__single, .multiselect__select {
            border: none;
            background: none;
            margin: 0;
            padding: 0;
        }

        .multiselect__single {
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            text-align: right;
            /*padding-right: 20px;*/
        }

        .multiselect__tags {
            display: flex;
            align-items: center;

            .multiselect__placeholder {
                display: none;
            }
        }

        .multiselect__select {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;

            &:before {
                position: static;
                margin: 0;
            }
        }

        .multiselect__option {
            min-height: @option-line-height;
            height: @option-line-height;
            line-height: @option-line-height;
            padding: 0 6px;

            &:after {
                content: none;
                background: none;
                min-height: @option-line-height;
                height: @option-line-height;
                line-height: @option-line-height;
            }
        }

        .multiselect__content-wrapper {
            border-radius: 0;
            max-width: 100px;
            right: 0px;
        }

        .multiselect__option--highlight {
            background: rgba(255, 255, 255, 0);
            min-height: @option-line-height;
            height: @option-line-height;
            line-height: @option-line-height;
            padding: 0 6px;
            color: initial;

            &:after {
                content: none;
                background: none;
                min-height: @option-line-height;
                height: @option-line-height;
                line-height: @option-line-height;
                /*padding: 6px;*/
            }
        }

        .multiselect__option--selected {
            background: #f3f3f3;
            color: #35495e;
            font-weight: 700;
        }

    }
</style>
<style lang="less" scoped>
    .template-01 {
        display: flex;
        align-items: center;

        &.langs-list {
            position: relative;
            height: 100%;
            width: 100%;

            .langs-select {
                //清除select的边框样式
                border: none;
                //清除select聚焦时候的边框颜色
                outline: none;
                background: none;
                border-radius: 0;
                width: 100px;
                max-width: 100px;
            }

            .select-icon {
                color: initial;
                position: absolute;
                display: block;
                width: 26px;
                vertical-align: middle;
                text-align: center;
                top: 0;
                right: 0;
                font-size: 14px;
                transform: rotate(90deg) scaleY(1.5);
                pointer-events: none;
            }

        }
    }


</style>
