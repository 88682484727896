<template>
    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-02">
        <div class="list-content">
            <cube-nav-item
                v-for="(item, itemIndex) in data.contents"
                :key="`${item.id}_${itemIndex}`"
                :style="listItemStyle"
                :class="checkRowPosition(itemIndex)"
                :data="data"
                :item-data="item"
                class="minisite-cube-nav-item">

            </cube-nav-item>
        </div>
    </div>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import CubeNavItem from "@/components/cubeNav/CubeNavItem.vue"

    export default {
        name: "Template02",
        mixins: [cubeMixin],
        components: { CubeNavItem },
        props: {
            data: {}
        },
        data() {
            return {
                maxWidth: "auto"
            }
        },
        computed: {
            listItemStyle() {
                return {
                    marginBottom: this.cubeNavStyle.gap + "px"
                }
            }
        },
        mounted() {},
        methods: {
            _isEmpty,
            checkRowPosition(index) {
                if(this.cubeNavStyle.startPosition === 'left') {
                    return  index%2 === 0 ? '' : 'child-to-right'
                } else if(this.cubeNavStyle.startPosition === 'right') {
                    return  index%2 === 0 ? 'child-to-right' : ''
                }
                return ''
            }
        }
    }
</script>

<style lang="less" scoped>
    .minisite-cube-nav-02 {
        .list-content {
            .minisite-cube-nav-item {
                width: 100%;
                //overflow: hidden;
            }
        }

        .child-to-right {
            display: flex;
            justify-content: end;
        }

        /deep/ .text-container {
            display: flex;
            flex-direction: column;
            //align-items: center;
        }

        /deep/ .main-item-content {
            display: flex;
            //justify-content: center;
            //align-items: center;
            flex-grow: 0;
            overflow: hidden;
        }
    }
</style>
