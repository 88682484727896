import {proxyApi} from "@/service/proxy";

function SiteApi(target, data) {
    return proxyApi('site', target, data, 1)
}

export const SearchPages = async ({siteId, index = 1, size = 999, q, lang}) => {
    return await SiteApi('searchPages', {
        siteId,
        index,
        size,
        q,
        lang
    })
}
