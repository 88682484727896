<template>
    <div class="minisite-search-list" :style="getListStyle">
        <template-00 @search="search" v-model="searchWord" :data="data?data.search:settings"
                     :search-style="searchStyle"></template-00>
        <template v-if="data && data.type==='search'">
            <div class="search-result">
                <van-empty v-if="searchRes.length===0" />
                <template v-else>
                    <div class="result-title">{{ data.search.resultTitle || "搜索结果" }}</div>
                    <div class="search-result-item" v-for="item in searchRes" :key="item.id">
                        <click-action :data="item">
                            {{ item.title }}
                        </click-action>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>
<script>
    import CommonMixIn from "./common.mixin"

    import SearchComponent from "./search"
    import { SearchPages } from "@/service/site"
    import ClickAction from "@/components/common/ClickAction"
    import Vue from "vue"
    import { Empty } from "vant"
    import { getUtm } from "@/utils/preflight"

    Vue.use(Empty)
    export default {
        name: "SearchComponent",
        mixins: [CommonMixIn],
        components: {
            "template-00": SearchComponent.Template00,
            ClickAction
        },
        props: {
            searchStyle: {},
            value: {},
            settings: {}
        },
        data() {
            return {
                searchRes: []
            }
        },
        computed: {
            getListStyle() {
                return {}
            },
            currentInfo() {
                let host = window.location.host
                let protocol = window.location.protocol
                let _pathList = window.location.pathname.split("/")
                return {
                    host: host,
                    protocol: protocol,
                    siteId: _pathList[1],
                    pageId: _pathList[2],
                    langId: this._lang
                }
            },
            searchWord: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit("input", val)
                }
            }
        },
        methods: {
            search(word) {
                // 站内搜索
                if (this.data && this.data.type === "search") {
                    this.searchInSite(word)
                } else {
                    this.$emit("search", word)
                }
            },
            async searchInSite(word) {
                try {
                    this.searchRes = []
                    if (word) {
                        let _params = {
                            siteId: this._siteId,
                            q: word,
                            lang: this._lang
                        }
                        let res = await SearchPages(_params)
                        res.data.forEach((item) => {
                            item.type = "sitePages"
                            let _link = this.currentInfo.protocol + "//" + this.currentInfo.host + "/" + this._siteId + "/"

                            item.content = {
                                content: [
                                    {
                                        url: _link + item.id + "/" + this._lang
                                    }
                                ]
                            }
                        })
                        const searchAction = {
                            originOpenId: this.originOpenId,
                            ...getUtm(),
                            title: this._pageTitle || document.title,
                            path: window.location.href,
                            searchVal: word,
                            searchFor: word,
                            uaId: this._UAId,
                            tagIds: this._tagIds,
                            pid: window.ca("get", "pid")
                        }
                        if (window.cw) {
                            window.cw("action", "searchview", searchAction)
                        }
                        this.searchRes = res.data
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }
</script>

<style lang="less">
    .minisite-search-list {

        .search-result {
            min-height: 100px;
            padding: 0 10px;

            .result-title {
                color: #333;
                margin-top: 8px;
                text-align: left;
            }
        }

        .search-result-item {
            text-align: left;
            padding: 12px 0;
            border-bottom: 1px solid #ddd;

            .minisite-link {
                font-weight: bold;
                font-size: 1.1em;
            }
        }

    }
</style>
