<template>
    <div class="minisite-list-00">
        <div class="minisite-list-item" v-for="item in data.contents" :key="item.id"
             :style="{width:data.style.common.width+'%'}">
            <div class="main-item-content" :style="getCommonStyle">
                <div class="video-container">
                    <basic-video :data="item.video"
                    ></basic-video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import listMixin from './videos.mixin'

    export default {
        name: "VideosTemplate00",
        mixins: [listMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .minisite-list-00 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .main-item-content {
            display: flex;
            justify-content: space-between;
        }

        .video-container {
            width: 100%;
        }

        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .right-container {
            flex-grow: 1;
            margin-left: 10px;
            vertical-align: top;

            .button-container {
                margin-top: 10px;
            }
        }

        .right-icon {
            margin-left: 10px;
            flex-shrink: 0;
        }
    }

</style>
