import {BasicText, BasicImage, BasicButton} from './../basic/index'
import commonMixIn from './../common.mixin'

export default {
    mixins: [commonMixIn],
    components: {
        BasicText
    },
    computed: {
        getFloatButtonStyle() {
            return Object.assign({},
                this.getBackground(this.data.style.floatButtonStyle.background),
                {
                    'borderRadius': this.data.style.floatButtonStyle.border.radius + 'px',
                    'borderStyle': this.data.style.floatButtonStyle.border.style,
                    'borderWidth': this.data.style.floatButtonStyle.border.height + 'px',
                    'borderColor': this.data.style.floatButtonStyle.border.color
                }
            )
            // return Object.assign({}, this.data.style.tabStyle, this.data.style.tabStyle.background, {
            //     paddingTop: this.data.style.tabStyle.padding.top + 'px',
            //     paddingBottom: this.data.style.tabStyle.padding.bottom + 'px',
            //     paddingLeft: this.data.style.tabStyle.padding.left + 'px',
            //     paddingRight: this.data.style.tabStyle.padding.right + 'px',
            //     'border-top-style': this.data.style.borderTopStyle.style,
            //     'border-top-width': this.data.style.borderTopStyle.height + 'px',
            //     'border-top-color': this.data.style.borderTopStyle.color
            // })
        },
        getFloatButtonIconStyle() {
            return {
                width: this.data.style.iconStyle.width + 'px',
                height: this.data.style.iconStyle.height + 'px',
            }
        }
    }
}
