<template>
    <div class="minisite-audio-list-00">
        <div v-for="item in data.contents" :key="item.id" class="minisite-audio-list-item"
             :style="{width:data.style.common.width+'%'}">
            <div class="main-item-content" :style="getCommonStyle">
                <div class="left-container"
                     :style="{width:data.style.audioStyle.width+'%'}">
                    <div class="audio-container">
                        <basic-text :data="item.audio.title"
                                    v-if="data.style.titleFontStyle.show"
                                    :font-style="data.style.titleFontStyle"></basic-text>
                        <basic-audio :data="item.audio"></basic-audio>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import listMixin from './audio.mixin'

    export default {
        name: "AudioTemplate00",
        mixins: [listMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .minisite-audio-list-00 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .minisite-audio-list-item {
            width: 100%;
        }

        .main-item-content {
            display: flex;
            justify-content: space-between;
        }

        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .right-container {
            flex-grow: 1;
            margin-left: 10px;
            vertical-align: top;

            .button-container {
                margin-top: 10px;
            }
        }

        .right-icon {
            margin-left: 10px;
            flex-shrink: 0;
        }
    }

</style>
