<template>
    <div class="minisite-list-00">
        <swiper class="swiper" :options="swiperOption"
                :style="{'--swiper-theme-color':data.style.bannerSettings.themeColor}">
            <swiper-slide class="swiper-slide" v-for="item in data.contents" :key="item.id"
                          style="width: 100%;">
                <div class="minisite-list-item">
                    <click-action :data="item.image.clickAction">
                        <div class="main-item-content" :style="getCommonStyle">
                            <div class="left-container"
                                 :style="{}">
                                <div class="image-container">
                                    <basic-image :data="item.image"
                                                 :lazy-load="false"
                                                 :image-style="data.style.imageStyle"
                                                 :image-title-style="data.style.imageTitleFontStyle"
                                    ></basic-image>
                                </div>
                            </div>
                        </div>
                    </click-action>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!--                <van-swipe class="my-swipe" :autoplay="3000" :vertical="data.style.bannerSettings.direction === 'vertical'"-->
        <!--                           :show-indicators="data.style.bannerSettings.showDots">-->
        <!--                    <van-swipe-item v-for="item in data.contents" :key="item.id">-->
        <!--                        <div class="minisite-list-item"-->
        <!--                             :style="{width:data.style.common.width+'%'}">-->
        <!--                            <click-action :data="item.image.clickAction">-->
        <!--                                <div class="main-item-content" :style="getCommonStyle">-->
        <!--                                    <div class="left-container"-->
        <!--                                         :style="{}">-->
        <!--                                        <div class="image-container">-->
        <!--                                            <basic-image :data="item.image"-->
        <!--                                                         :image-style="data.style.imageStyle"-->
        <!--                                                         :image-title-style="data.style.imageTitleFontStyle"-->
        <!--                                            ></basic-image>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </click-action>-->
        <!--                        </div>-->
        <!--                    </van-swipe-item>-->
        <!--                </van-swipe>-->
    </div>
</template>

<script>
    import bannerMixin from "./banner.mixin"

    import { Swiper, SwiperSlide } from "vue-awesome-swiper"
    import "swiper/swiper-bundle.css"

    import SwiperCore, { Autoplay, Pagination } from "swiper"

    SwiperCore.use([Autoplay])
    SwiperCore.use([Pagination])

    import Vue from "vue"
    import { Swipe, SwipeItem } from "vant"

    Vue.use(Swipe)
    Vue.use(SwipeItem)
    export default {
        name: "BannerTemplate00",
        mixins: [bannerMixin],
        props: {
            data: {}
        },
        components: {
            Swiper,
            SwiperSlide
        },
        computed: {
            swiperOption() {
                return {
                    direction: this.data.style.bannerSettings.direction,
                    loop: true,
                    pagination: {
                        el: this.data.style.bannerSettings.showDots ? ".swiper-pagination" : "",
                        clickable: true
                    },
                    // autoplay: false
                    autoplay: this.data.style.bannerSettings.autoPlay ? {
                        delay: (this.data.style.bannerSettings.autoPlayDelay * 1000) || 3000,
                        disableOnInteraction: false
                    } : false
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .minisite-list-00 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .swiper-slide {
            width: 100% !important;
        }

        .main-item-content {
            width: 100% !important;
            display: flex;
            justify-content: space-between;
        }

        .left-container {
            width: 100%;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .right-container {
            flex-grow: 1;
            margin-left: 10px;
            vertical-align: top;

            .button-container {
                margin-top: 10px;
            }
        }

        .right-icon {
            margin-left: 10px;
            flex-shrink: 0;
        }
    }

</style>
