<template>
    <div class="minisite-float-button-item float-button-00" :style="getContainerStyle">
        <collection @collection-status-change="handleCollect()"
                    class="collection-icon icon-image"
                    :item-id="_siteData.type==='template'?`${_pageId}_${_pageCMSData.id}`:`${_pageId}`"
                    :collection-style="data.style.collectionStyle"></collection>
    </div>
</template>

<script>
    import collectionMixin from './collection.mixin'

    export default {
        name: "Template-00",
        mixins: [collectionMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .float-button-00 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /*box-shadow: 0 2px 8px #8e8e8e;*/

        .float-button-icon-container {
            .float-button-icon {
                max-width: 100%;
                max-height: 100%;
            }
        }

    }

</style>
