/**
 * 将数组拼接为符串，用symbol链接
 * @description 用于pageview.title
 * @export
 * @param {((string | undefined)[])} arr
 * @param {string} [symbol='-'] 拼接符号
 */
export function concatWithSymbol(arr, symbol = '-') {
    let res = '',
        lastChar = ''
    arr.forEach((t) => {
        if (t != null) {
            lastChar = res.charAt(res.length - 1)
            if (lastChar && lastChar !== symbol) {
                res += symbol + t
            } else {
                res += t
            }
        }
    })
    return res
}
export function concatURI(link, params) {
    if (!params)
        return link;
    const [pureUrl, searchParams] = link.split('?');
    if (!pureUrl)
        return '';
    const query = [], qs = new URLSearchParams(searchParams), qsDic = {};
    qs.forEach((v, k) => {
        qsDic[k] = v;
    });
    Object.assign(qsDic, params);
    for (const [key, value] of Object.entries(qsDic)) {
        let val = value;
        if (typeof value !== 'string') {
            val = value === null || value === void 0 ? void 0 : value.toString();
        }
        if (val != null) {
            query.push(`${key}=${encodeURIComponent(val)}`);
        }
    }
    return pureUrl + '?' + query.join('&');
}
