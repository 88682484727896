<template>
    <div class="minisite-collection" @click.stop="handleClick"
         :style="getCollectionStyle">
        <svg-icon
            :style="{width: this.collectionStyle.width + 'px',height: this.collectionStyle.width + 'px'}"
            :icon-class="collectionStyle.type" :svg-type="getType"
            :svg-style="svgStyle"></svg-icon>
    </div>
</template>

<script>
    import SvgIcon from "~@/assets/icons/SvgIcon"
    import CollectionMixIn from "~@/components/functions/collection.mixin"

    export default {
        name: "Collection",
        mixins: [CollectionMixIn],
        props: {
            collectionStyle: {},
            itemId: {
                type: String,
                default: ""
            }
        },
        components: {
            SvgIcon
        },
        data() {
            return {}
        },
        mounted() {
        },
        computed: {
            getCollectionStyle() {
                let _target = {}
                if (this.collectionStyle?.listPosition) {
                    switch (this.collectionStyle?.listPosition) {
                        case "button":
                            _target = {
                                justifyContent: "flex-end",
                                alignItems: "right"
                            }
                            break
                        case "image":
                            _target = {
                                justifyContent: "center",
                                alignItems: "right"
                            }
                            break
                    }
                } else {
                    switch (this.collectionStyle.direction) {
                        case "horizontal":
                            switch (this.collectionStyle.position) {
                                case "left":
                                    _target = {
                                        justifyContent: "flex-start",
                                        alignItems: "center"
                                    }
                                    break
                                case "center":
                                    _target = {
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }
                                    break
                                case "right":
                                    _target = {
                                        justifyContent: "flex-end",
                                        alignItems: "center"
                                    }
                                    break
                            }
                            break
                        case "vertical":
                            switch (this.collectionStyle.position) {
                                case "top":
                                    _target = {
                                        alignItems: "flex-start",
                                        justifyContent: "center"
                                    }
                                    break
                                case "center":
                                    _target = {
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }
                                    break
                                case "bottom":
                                    _target = {
                                        alignItems: "flex-end",
                                        justifyContent: "center"
                                    }
                                    break
                            }
                            break
                    }
                }
                return _target
            },
            isActive() {
                return this.collectionIdList.indexOf(this.itemId) > -1
            },
            getType() {
                return this.isActive ? "fill" : ""
            },
            svgStyle() {
                return {
                    color: this.isActive ? this.collectionStyle.active.color : this.collectionStyle.normal.color
                }
            }
        },
        methods: {
            handleClick() {
                if (!this.$ISMEMBER) {
                    alert("您还不是会员，暂时无法收藏")
                } else {
                    this.$emit("collection-status-change")
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .minisite-collection {
        //width: 100%;
        //height: 100%;
        flex-grow: 1;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .collection-icon {
        max-width: 100%;
    }
</style>
