<template>
    <div class="minisite-list" :style="getListStyle" v-if="!data.style.innerListStyle">
        <template-00 :data="data"></template-00>
    </div>
</template>

<script>
    import CommonMixIn from "./common.mixin"
    import ToggleListTemplate from "./toggleList"

    export default {
        name: "ToggleList",
        mixins: [CommonMixIn],
        props: {},
        components: {
            "template-00": ToggleListTemplate.Template00,
        },
        mounted() {
            this.getInnerListStyle()
        },
        computed: {
            getListStyle() {
                if (this.data.style && this.data.style.background) {
                    return Object.assign(
                        {},
                        this.getBackground(this.data.style.background),
                        this.getPadding(this.data.style.background.padding),
                        this.getMargin(this.data.style.background.margin)
                    )
                }
                return {}
            }
        },
        methods: {
            getInnerListStyle() {
                if (this.data.style.innerListStyle) {
                    this.data.contents.forEach((component) => {
                        component.children.forEach((child) => {
                            this.$set(child, "style", this.data.style.innerListStyle)
                        })
                    })
                    this.$delete(this.data.style, "innerListStyle")
                }
            }
        },
        watch: {
            data: {
                deep: true,
                handler() {
                    this.getInnerListStyle()
                }
            }
        }

    }
</script>

<style lang="less" scoped>

    .minisite-list {
        width: 100%;
    }

</style>
