<template>
    <div class="minisite-text-list wechat-article" :style="getListStyle">
        <template-00 v-if="data.style.templateId === '00'" :data="data"></template-00>
    </div>
</template>
<script>
    import CommonMixIn from './common.mixin'

    import TextComponent from './text'

    export default {
        name: "TextComponent",
        mixins: [CommonMixIn],
        components: {
            'template-00': TextComponent.TemplatePlain,
        },
        computed: {
            getListStyle() {
                if (this.data.style && this.data.style.background) {
                    return Object.assign(
                        {},
                        this.getBackground(this.data.style.background),
                        this.getPadding(this.data.style.background.padding),
                        this.getMargin(this.data.style.background.margin)
                    )
                }
                return {}
            }
        }
    }
</script>

<style lang="less">
    .minisite-text-list {
        text-align: left;

        p {
            margin-top: 0;
            margin-bottom: 1em;
        }

        img {
            max-width: 100%;
            height: unset;
        }

        section, div {
            box-sizing: border-box;
        }

        .js_img_loading {
            .img_loading {
                display: none;
            }
        }


        mpvideosnap {
            display: none;
        }
    }
</style>
