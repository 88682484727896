<template>
    <div class="minisite-tag-list tag-list-04" v-if="showedData" :style="getToggleListStyle">
        <div class="list-search"
             v-show="getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              v-model="searchWord"
                              :search-style="showedData.style.searchStyle"
            ></search-component>
        </div>
        <div class="custom-tab"
             v-if="showedData && showedData.contents&& showedData.contents.length>0">
            <van-sidebar
                v-model="activeKey"
                animated
                class="custom-side"
                :style="{
                    '--c-site-active-background-color': data.style.listStyle.active.background.backgroundColor || `var(--c-site-primary-color-)`,
                    '--c-site-sidebar-background-color': data.style.listStyle.normal.background.backgroundColor,
                    '--c-site-active-color': data.style.listStyle.active.color || '#fff',
                    '--c-site-normal-color': data.style.listStyle.normal.color,
                    backgroundColor:  '#fff'
                }">
                <van-sidebar-item
                    :style="{
                        fontSize: data.style.listStyle.normal.fontSize + 'px',
                        fontWeight: data.style.listStyle.normal.fontWeight ? 'bold' : 'normal'
                    }"
                    @click="tabChange"
                    v-for="(item,itemIndex) in showedData.contents"
                    :key="itemIndex"
                    :title="item.title.content" />
            </van-sidebar>
            <inner-list class="inner-list" :data="showedData.contents[activeKey]" :data-source="data.dataSource"
                        :search-word="searchWord"></inner-list>
        </div>
    </div>
</template>

<script>
    import tagList from "./tagList.mixin"
    import { Sidebar, SidebarItem } from "vant"


    export default {
        name: "Template-04",
        mixins: [tagList],
        props: {
            data: {}
        },
        data() {
            return {}
        },
        components: {
            VanSidebar: Sidebar,
            VanSidebarItem: SidebarItem
        },
        computed: {
            getToggleListStyle() {
                return {}
            }
        },
        mounted() {

        }
    }
</script>

<style lang="less">
    .minisite-tag-list {

        .image_resized {
            width: initial;
            display: flex;
            margin: 0;

            img {
                width: 100%;
                height: initial;
            }
        }

        figure {

            &.image {
                margin: 0;
                height: fit-content;
                display: flex;
                align-items: center;

                img {
                    margin: 0 auto;
                }
            }
        }

    }

    .tag-list-04 {
        max-height: 100%;

        .custom-tab {
            display: flex;
            flex-direction: row;

            .custom-side {
                flex-grow: 0;
                flex-shrink: 0;
                width: 110px;
            }
        }

        .van-sidebar-item {
            padding: 12px 16px;
            background-color: var(--c-site-sidebar-background-color);
            color: var(--c-site-normal-color);
            text-align: left;
        }

        .van-sidebar-item--select {
            background-color: var(--c-site-active-background-color);
            color: var(--c-site-active-color);
            font-weight: normal;

            &:before {
                content: none;
            }
        }

        .inner-list {
            flex-shrink: 1;
            overflow: hidden auto;
            flex-grow: 1;
        }

    }

</style>
