import Template00 from "./Template-00"
import Template01 from "./Template-01"
import Template02 from "./Template-02"
import Template03 from "./Template-03"
import Template04 from "./Template-04"
import Template05 from "./Template-05"
import Template06 from "./Template-06"
import Template07 from "./Template-07"
import Template08 from "./Template-08"

export default {
    Template00,
    Template01,
    Template02,
    Template03,
    Template04,
    Template05,
    Template06,
    Template07,
    Template08,
}
