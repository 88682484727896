<template>
    <div class="component-item" :style="getListStyle" v-if="!isLoading">
        <text-component
            class="text-item-wrapper"
            :data="data"
            v-if="data.type === 'text'"
            :key="hierId || data.id"></text-component>
        <text-component
            class="text-item-wrapper"
            :data="data"
            v-if="data.type === 'richText'"
            :key="hierId || data.id"></text-component>
        <button-component
            class="button-item-wrapper"
            :data="data"
            v-if="data.type === 'button'"
            :key="hierId || data.id"></button-component>
        <divide-component
            class="divide-item-wrapper"
            :data="data"
            v-if="data.type === 'divide'"
            :key="hierId || data.id"></divide-component>
        <image-component
            class="images-item-wrapper"
            :data="data"
            v-if="data.type === 'image'"
            :key="hierId || data.id"></image-component>
        <banner-component
            class="images-item-wrapper"
            :data="data"
            v-if="data.type === 'banner'"
            :key="hierId || data.id"></banner-component>
        <video-component
            class="images-item-wrapper"
            :data="data"
            v-if="data.type === 'video'"
            :key="hierId || data.id"></video-component>
        <audio-component
            class="images-item-wrapper"
            :data="data"
            v-if="data.type === 'audio'"
            :key="hierId || data.id"></audio-component>
        <list-component
            class="list-item-wrapper"
            :data="data"
            v-if="data.type === 'list'"
            type="inner"
            :search-word="searchWord"
            :hier-id="hierId"
            :data-source="dataSource"
            :key="hierId || data.id"></list-component>
    </div>
</template>

<script>
    import CommonMixIn from "./common.mixin"

    import TextComponent from "./TextComponent"
    import ButtonComponent from "./ButtonComponent"
    import DivideComponent from "./DivideComponent"
    import ImageComponent from "./ImageComponent"
    import BannerComponent from "./BannerComponent"
    import VideoComponent from "./VideoComponent"
    import AudioComponent from "./AudioComponent"
    import ListComponent from "./ListComponent"
    import { getDataRows } from "@custouch/proxy-use"
    import _cloneDeep from "lodash/cloneDeep"

    export default {
        name: "InnerComponentItem",
        mixins: [CommonMixIn],
        props: {
            langs: {},
            navHeight: {},
            hierDetail: {},
            hierId: {},
            dataSource: {},
            searchWord: {}
        },
        data() {
            return {
                FLOAT_TYPE_LIST: ["floatButton"],
                isLoading: true,
                listItemTemp: {},
                componentField: {
                    text: "text",
                    image: "imageSrc",
                    banner: "imageSrc",
                    button: "buttonLink",
                    floatButton: "buttonLink",
                    video: "videoSrc",
                    audio: "audioSrc",
                    bgMusic: "audioSrc"
                }
            }
        },
        components: {
            TextComponent,
            ButtonComponent,
            DivideComponent,
            ImageComponent,
            BannerComponent,
            AudioComponent,
            VideoComponent,
            ListComponent
        },
        computed: {
            getListStyle() {
                // 浮动组件
                if (this.FLOAT_TYPE_LIST.indexOf(this.data.type) > -1) {
                    return {
                        position: "fixed",
                        width: this.data.style.floatButtonStyle.width + "px",
                        height: this.data.style.floatButtonStyle.height + "px",
                        top: this.data.style.floatButtonStyle.position.top + "px",
                        left: this.data.style.floatButtonStyle.position.left + "px",
                        right: this.data.style.floatButtonStyle.position.right + "px",
                        bottom: this.data.style.floatButtonStyle.position.bottom + "px",
                        zIndex: 999
                    }
                } else if (this.data.style && this.data.style.listStyle) {
                    return Object.assign(
                        {},
                        this.data.style.listStyle,
                        { borderRadius: this.data.style.listStyle.borderRadius + "px" },
                        this.getBackground(this.data.style.listStyle.background),
                        this.getPadding(this.data.style.listStyle.padding),
                        this.getMargin(this.data.style.listStyle.margin)
                    )
                }
                return {}
            }
        },
        created() {
            if (this.data.type === "list" && !this.listItemTemp) {
                this.listItemTemp = _cloneDeep(this.data.contents[0])
            }
        },
        async mounted() {
            await this.getInnerData()
        },
        methods: {
            async getInnerData() {
                let _this = this

                if (
                    _this.dataSource &&
                    _this.dataSource.type === "cms" &&
                    _this.data.dataSource &&
                    _this.data.dataSource.fieldSource === "field"
                ) {
                    let cmsConfigs = _this.data.dataSource.cms

                    let _content = []
                    let fieldSettings = _this.data.dataSource.cms.fields || {}
                    if (
                        (_this.componentField[_this.data.type] &&
                            fieldSettings[_this.componentField[_this.data.type]]) ||
                        this.data.type === "list"
                    ) {
                        let _dataSrc
                        let _target
                        switch (_this.data.type) {
                            case "text":
                            case "richText":
                                _dataSrc = _this.hierDetail[fieldSettings[_this.componentField[_this.data.type]]]
                                this.data.contents[0].text.contents = _dataSrc
                                break
                            case "image":
                            case "banner":
                                _dataSrc = _this.hierDetail[fieldSettings[_this.componentField[_this.data.type]]]
                                _content = _dataSrc?.split(";").map((_) => {
                                    return {
                                        // id: generateUUID(12),
                                        image: {
                                            show: true,
                                            imageSrc: _,
                                            imageTitle: "图片标题",
                                            imageAlt: "图片说明",
                                            clickAction: {
                                                type: "basic",
                                                remark: "",
                                                content: {
                                                    type: "",
                                                    content: [
                                                        {
                                                            title: "",
                                                            url: ""
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    }
                                })
                                this.$set(this.data, "contents", _content)
                                break
                            case "list":
                                // eslint-disable-next-line no-case-declarations
                                let { data } = await getDataRows({
                                    dataHierarchyId: this.hierId,
                                    dataTemplateId: this.dataSource.cms.templateId,
                                    index: 1,
                                    size: 999,
                                    searchKey: this.searchWord
                                })
                                this.$set(this.data, "contents", [])

                                // eslint-disable-next-line no-case-declarations
                                // let _itemTemp = this.listItemTemp
                                data.forEach((item) => {
                                    _content.push({
                                        id: item.id,
                                        image: Object.assign({}, this.listItemTemp.image, {
                                            imageSrc: item.detail[fieldSettings.image]?.split(";")[0]
                                        }),
                                        video: {
                                            // createTime: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
                                            createTime: "",
                                            count: 0,
                                            hashId: ""
                                        },
                                        title: Object.assign({}, this.listItemTemp.title, {
                                            content: item.detail[fieldSettings.title] || ""
                                        }),
                                        desc: Object.assign({}, this.listItemTemp.desc, {
                                            content: item.detail[fieldSettings.desc] || ""
                                        }),
                                        button: {
                                            show: true,
                                            buttonText: "点击查看"
                                        },
                                        icon: {
                                            show: true,
                                            imageSrc:
                                                "https://custouch-testbucket.oss-cn-hangzhou.aliyuncs.com/res/125/right-1.png"
                                        },
                                        clickAction:
                                            cmsConfigs.detailType === "link"
                                                ? {
                                                      type: "basic",
                                                      remark: "",
                                                      content: {
                                                          type: "",
                                                          content: [
                                                              {
                                                                  title: item.detail[fieldSettings.title] || "",
                                                                  url: item.detail[fieldSettings.link] || ""
                                                              }
                                                          ]
                                                      }
                                                  }
                                                : {
                                                      type: "detailLink",
                                                      remark: "",
                                                      content: {
                                                          type: "",
                                                          content: [
                                                              {
                                                                  title: item.detail[fieldSettings.title],
                                                                  url: "",
                                                                  dataRowId: item.id
                                                              }
                                                          ]
                                                      }
                                                  }
                                    })
                                })

                                this.$set(this.data, "contents", _content)

                                break
                        }
                    }
                    this.isLoading = false
                } else {
                    this.isLoading = false
                }
            }
        },
        watch: {
            searchWord() {
                this.getInnerData()
            },
            hierId() {
                this.getInnerData()
            }
        }
    }
</script>

<style lang="less" scoped></style>
