<template>
    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-08" :style="{paddingTop: fillHeight + 'px'}">
        <!--    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-08">-->
        <div class="list-content">
            <template v-for="(_, index) in listCount">
                <div
                    v-if="cubeNavList[index]"
                    :class="`cube-nav-item-0${index}`"
                    :style="{ backgroundColor: cubeNavList[index].background.backgroundColor }"
                    :key="index">
                    <div class="limit-box">
                        <cube-nav-item
                            :data="data"
                            class="minisite-cube-nav-item"
                            :item-data="cubeNavList[index]"></cube-nav-item>
                    </div>
                </div>
            </template>
            <div
                v-if="cubeNavList.length >= 4"
                class="cube-nav-item-05"
                :style="{ backgroundColor: cubeNavList[3].background.backgroundColor }">
            </div>
        </div>
    </div>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import CubeNavItem from "@/components/cubeNav/CubeNavItem.vue"

    export default {
        name: "Template08",
        components: { CubeNavItem },
        mixins: [cubeMixin],
        props: {
            data: {}
        },
        data() {
            return {
                listHeight: 0,
                listCount: 5,
                fillHeight: 0
            }
        },
        computed: {
            spacing() {
                return this.cubeNavStyle.gap + "px"
            },
            cubeNavList() {
                return this.data.contents
            },
            isBottom() {
                return this.data.style.listStyle?.isBottom ?? true
            }
        },
        watch: {
            isBottom: {
                handler(nv) {
                    if(nv) {
                        this.$nextTick(() => {
                            this.listenImageLoad()
                        })
                    }
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            _isEmpty,
            // 更改填充的高度
            changeFillHeight() {
                const contentHeight = document.getElementById("page-content").offsetHeight
                const pageHeight = document.getElementById("app").offsetHeight
                this.fillHeight = pageHeight > contentHeight ? pageHeight - contentHeight : 0
            },
            // 在所有图片加载完成后，才开始计算高度
            listenImageLoad() {
                const imageNodes = document.querySelectorAll("img")
                if(!imageNodes.length) {
                    return this.changeFillHeight()
                }

                let loadFlag = 0,
                    countFlag = 0
                imageNodes.forEach(node => {
                    const lazy = node.getAttribute('lazy')
                    if(lazy && lazy !== 'loaded') {
                        return
                    }

                    countFlag++
                    node.onload = () => {
                        loadFlag++;
                        if(loadFlag >= countFlag) {
                            this.changeFillHeight()
                        }
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    @media (min-width: 768px) {
        .minisite-cube-nav-08 {
            font-size: 32px;
        }
    }

    @media (max-width: 768px) {
        .minisite-cube-nav-08 {
            font-size: 4.26666667vw;
        }
    }

    .minisite-cube-nav-08 {
        .list-content {
            position: relative;
            height: 30.19em;
            overflow: hidden;

            .minisite-cube-nav-item {
                max-width: 100%;
                max-height: 100%;
                overflow: hidden;
            }

            /deep/ .text-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            /deep/ .main-item-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
                overflow: hidden;
                height: 100%;
                background-color: transparent !important;
            }

            > * {
                position: absolute;
                clip-path: polygon(0 50%, 50% 0, 100% 50%, 50% 100%);
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .limit-box {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .cube-nav-item-00 {
                left: 0.44em;
                width: 14.5em;
                height: 14.5em;
                background-color: #f4f4f4;

                .limit-box {
                    width: 7em;
                    height: 7.5em;
                }
            }

            .cube-nav-item-01 {
                top: 2.06em;
                left: -24.56em;
                width: 37.12em;
                height: 37.12em;
                background-color: #f4f4f4;

                .limit-box {
                    position: absolute;
                    top: 14.75em;
                    right: 3.94em;
                    width: 7.875em;
                    height: 7.625em;
                }
            }

            .cube-nav-item-02 {
                left: 8.26em;
                top: 10.31em;
                width: 9.72em;
                height: 9.72em;
                background-color: #f4f4f4;

                .limit-box {
                    width: 4.125em;
                    height: 5.625em;
                }
            }

            .cube-nav-item-03 {
                left: 3.86em;
                top: 16.75em;
                width: 27.4em;
                height: 27.4em;
                background-color: #f4f4f4;

                .limit-box {
                    position: absolute;
                    top: 3.75em;
                    left: 9.95em;
                    width: 7.5em;
                    height: 6.625em;
                }
            }

            .cube-nav-item-04 {
                left: 13.68em;
                top: -10.13em;
                width: 24.75em;
                height: 24.75em;
                background-color: #f4f4f4;
                clip-path: polygon(0 80%, 80% 0, 100% 20%, 20% 100%);

                .limit-box {
                    position: absolute;
                    width: 4.25em;
                    height: 5.5em;
                    left: 2.76em;
                    bottom: 2.12em;
                }
            }

            .cube-nav-item-05 {
                left: 18.19em;
                top: 7.87em;
                width: 16.62em;
                height: 16.62em;
                background-color: #f4f4f4;
            }
        }
    }
</style>
