import {BasicImage} from './../basic/index'
import CommonMixIn from './../common.mixin'

export default {
    mixins: [CommonMixIn],
    components: {
        BasicImage
    },
    props: {
        langs: {}
    },
    data() {
        return {
            pageId: this._pageId,
            siteId: this._siteId,
        }
    },
    computed: {}
}
