<template>
    <div class="minisite-divide-00" :class="'template-' + listStyle.templateId">
        <div class="minisite-divide-item" :style="{width:listStyle.common.width+'%'}"
             :key="itemIndex"
             v-for="(item,itemIndex) in data.contents">
            <div class="main-item-content" :style="getCommonStyle">
                <div class="divide-line" :style="getDivideStyle">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import divideMixin from "./divide.mixin"

    export default {
        name: "DivideTemplate00",
        mixins: [divideMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less">

    .minisite-divide-00 {
        /*display: flex;*/
        /*flex-wrap: wrap;*/
        /*justify-content: space-between;*/

        .main-item-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .divide-container {
            width: 100%;
        }

        &.template-00 {

        }

        .divide-line {
            width: 100%;
        }
    }

</style>
