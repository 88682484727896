<template>
    <div class="minisite-audio-container" :style="getVideoStyle">
        <audio-player v-if="data.audioSrc"
                      ref="audioPlayer"
                      :isLoop="false"
                      :show-prev-button="false"
                      :show-next-button="false"
                      :audio-list="[data.audioSrc]"
                      :show-volume-button="isPc"
                      class="audio-player"
                      theme-color="#7f8388" style="width: 100%;"
        >
            <svg-icon slot="play-start"
                      className="play"
                      iconClass="play"></svg-icon>
            <svg-icon slot="play-pause"
                      className="pause"
                      iconClass="pause"></svg-icon>
        </audio-player>
        <div v-else>暂无音频</div>
    </div>
</template>

<script>
    import componentMinIn from './component.mixin'
    import AudioPlayer from '@liripeng/vue-audio-player'
    import SvgIcon from "~@/assets/icons/SvgIcon";

    export default {
        name: "BasicAudio",
        components: {
            AudioPlayer,
            SvgIcon
        },
        mixins: [componentMinIn],
        props: {},
        computed: {
            isPc() {
                let userAgentInfo = navigator.userAgent
                let Agents = ['Android', 'iPhone',
                    'SymbianOS', 'Windows Phone',
                    'iPad', 'iPod']
                let flag = true
                for (let v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        flag = false
                        break
                    }
                }
                return flag
            },
        }
    }
</script>

<style lang="less" scoped>

    .minisite-audio-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        padding: 0 16px;

        .audio-player {
            margin-top: 10px;
        }

        .minisite-audio {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            pointer-events: none;
        }

        .audio-title {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        .empty-audio {
            font-size: 36px;
            margin: 0 auto;
        }
    }

</style>
<style lang="less">
    .minisite-audio-container {
        .audio__play-rate__dropdown, .audio__play-volume-wrap {
            border-radius: 0.25rem;
            background-color: rgba(55, 65, 81, 1);
            top: 100%;
            bottom: auto;
            z-index: 9;
        }

        .audio__play-volume-wrap {
            height: 100px !important;
            border-radius: 0.25rem !important;

            .audio__play-volume {
                border-radius: 0.25rem !important;
            }
        }
    }

</style>
