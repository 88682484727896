import { BasicText, BasicImage, BasicButton } from "./../basic/index"
import SearchComponent from "../SearchComponent"
import commonMixIn from "./../common.mixin"
import InnerList from "./../InnerList"
import ComponentItem from "./../../components/ComponentItem"
import { ReplaceParamVal, GetQueryString } from "@/utils/linkParams"
import _cloneDeep from "lodash/cloneDeep"
import { SearchInnerList } from "../search/search"
import { getDataModule, searchDataRowsInGlobal } from "@custouch/proxy-use"

export default {
    mixins: [commonMixIn],
    components: {
        BasicText,
        BasicImage,
        BasicButton,
        ComponentItem,
        InnerList,
        SearchComponent
    },
    data() {
        return {
            showedData: null,
            activeKey: 0,
            searchWord: "",
            moduleDetail: {}
        }
    },
    async mounted() {
        await this.getListData()
        this.data.contents.forEach((content) => {
            content.matched = true
            content.children.forEach((child) => {
                child.contents.forEach((_ => {
                    this.$set(_, "matched", true)
                }))
            })
        })
        this.$set(this, "showedData", _cloneDeep(this.data))
        this.activeKey = parseInt(GetQueryString(this.data.id)) || 0
    },
    methods: {
        async getListData() {
            let _dataSource = this.data.dataSource ? this.data.dataSource.type : "default"
            switch (_dataSource) {
                case "default":
                    this.getNormalData()
                    break
                case "cms":
                    await this.getCMSData()
                    break
                default:
                    this.getNormalData()
                    break

            }
        },
        getNormalData() {
            this.listData = this.data.contents.map((_) => {
                let _item = {}
                _item.id = _.id
                if (_.title.show) {
                    _item.title = _.title.content
                }
                if (_.desc.show) {
                    _item.desc = _.desc.content
                }
                if (_.button && _.button.show) {
                    _item.button = _.button.buttonText
                }
                if (_.icon.show) {
                    _item.icon = {
                        imageSrc: _.icon.imageSrc
                    }
                }
                if (_.image.show) {
                    _item.image = {
                        imageSrc: _.image.imageSrc,
                        imageAlt: _.image.imageAlt,
                        imageTitle: _.image.imageTitle
                    }
                }
                if (_.count) {
                    _item.count = _.count
                }
                if (_.createTime) {
                    _item.createTime = _.createTime
                }
                if (_.formatCreateTime) {
                    _item.formatCreateTime = _.formatCreateTime
                }
                _item.clickAction = _.clickAction
                return _item
            })

        },
        async getCMSData() {
            try {
                let cmsConfigs = this.data.dataSource.cms
                let data = await getDataModule({
                    dataModuleId: cmsConfigs.moduleId
                })
                this.moduleDetail = data

                let _target = []
                let _itemTemplate = this.data.contents[0]
                data.dataHierarchies.forEach((item) => {
                    _target.push(Object.assign({}, _itemTemplate, {
                        id: item.id,
                        title: {
                            content: cmsConfigs.fields.title === "hierName" ? item.name : item.detail[cmsConfigs.fields.title] || "默认标题"
                        },
                        detail: item.detail
                    }))
                })
                this.data.contents = _target
            } catch (e) {
                console.log(e)
            }
        },
        tabChange(activeIndex, title) {
            if (window.history) {
                // 支持History API
                history.replaceState(null, "", ReplaceParamVal(this.data.id, activeIndex))
            }
        },
        async search(word) {
            if (!word || word.length < 1) {
                this.showedData = _cloneDeep(this.data)
            } else {
                // this.showedData = _cloneDeep(this.data)
                //
                // this.showedData = SearchInnerList(this.data, word)
                let _dataSource = this.data.dataSource ? this.data.dataSource.type : "default"
                switch (_dataSource) {
                    case "default":
                        this.showedData = SearchInnerList(this.data, word)
                        break
                    case "cms":
                        await this.searchInCMS(word)
                        break
                    default:
                        this.showedData = SearchInnerList(this.data, word)
                        break
                }
            }
        },
        async searchInCMS(word) {
            try {
                let cmsConfigs = this.data.dataSource.cms

                let _res = await searchDataRowsInGlobal(
                    {
                        dataHierarchies: this.moduleDetail.dataHierarchies.map(_ => _.id),
                        /** SearchGroupBy 需和 dataModules｜dataHierarchies 对应*/
                        groupBy: ["dataHierarchy"],
                        searchKey: word
                        /** 正序｜倒序 */
                    }
                )
            } catch (e) {
                console.log(e)
            }
        }
    },
    computed: {
        getTitleWidth() {
            if (this.showedData.style.listStyle.tabs) {
                let _tabsStyle = this.showedData.style.listStyle.tabs
                return (_tabsStyle.wrap.enable && _tabsStyle.wrap.col) ? 100 / _tabsStyle.wrap.col + "%" : "auto"
            }
        },
        getShowedComponent() {
            if (this.data.style) {
                return {
                    search: this.data.style.searchStyle ? this.data.style.searchStyle.show : false
                }
            }
            return {}
        }
    }
}
