var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showedData)?_c('div',{staticClass:"minisite-tag-list tag-list-02",style:(_vm.getToggleListStyle)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getShowedComponent.search),expression:"getShowedComponent.search"}],staticClass:"list-search"},[_c('search-component',{attrs:{"settings":_vm.data.search,"search-style":_vm.showedData.style.searchStyle},on:{"search":_vm.search},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1),(_vm.showedData && _vm.showedData.contents&& _vm.showedData.contents.length>0)?[_c('van-tabs',{staticClass:"custom-tab",class:{
            wrap: _vm.showedData.style.listStyle.tabs ? _vm.showedData.style.listStyle.tabs.wrap.enable : false,
            grow: _vm.showedData.style.listStyle.tabs ? _vm.showedData.style.listStyle.tabs.wrap.col : false
        },style:({
            '--active-color': _vm.showedData.style.listStyle.background.color || "var(--c-site-primary-color-)",
            '--c-site-tab-height': ((_vm.showedData.style.listStyle.tabs.height) + "px")
        }),attrs:{"type":"card","color":_vm.showedData.style.listStyle.active.background.backgroundColor || "var(--c-site-primary-color-)","background":_vm.showedData.style.listStyle.normal.background.backgroundColor,"title-inactive-color":_vm.showedData.style.listStyle.normal.color,"title-active-color":_vm.showedData.style.listStyle.active.color,"animated":"","ellipsis":false,"swipeable":"","lazy-render":false},on:{"click":_vm.tabChange},model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},_vm._l((_vm.showedData.contents),function(item){return _c('van-tab',{key:item.id,attrs:{"title":item.title.content,"title-style":{
                fontSize: _vm.showedData.style.listStyle.normal.fontSize + 'px',
                fontWeight: _vm.showedData.style.listStyle.normal.fontWeight ? 'bold' : 'normal',
                width: _vm.getTitleWidth
            }}},[_c('inner-list',{attrs:{"data":item,"data-source":_vm.data.dataSource,"search-word":_vm.searchWord}})],1)}),1)]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }