<template>
    <div class="minisite-list minisite-list-list" :style="getListStyle">
        <template-00 v-if="listStyle.templateId === '00'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-00>
        <template-01 v-if="listStyle.templateId === '01'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-01>
        <template-02 v-if="listStyle.templateId === '02'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-02>
        <template-03 v-if="listStyle.templateId === '03'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-03>
        <template-04 v-if="listStyle.templateId === '04'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-04>
        <template-05 v-if="listStyle.templateId === '05'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-05>
        <template-06 v-if="listStyle.templateId === '06'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-06>
        <template-07 v-if="listStyle.templateId === '07'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-07>
        <template-08 v-if="listStyle.templateId === '08'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-08>
        <template-08 v-if="listStyle.templateId === '09'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-08>
        <template-08 v-if="listStyle.templateId === '10'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-08>
        <template-11 v-if="listStyle.templateId === '11'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-11>
        <template-11 v-if="listStyle.templateId === '12'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-11>
        <template-11 v-if="listStyle.templateId === '13'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-11>
        <template-14 v-if="listStyle.templateId === '14'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-14>
        <template-15 v-if="listStyle.templateId === '15'" :data="data" :data-source="dataSource" :type="type"
                     :hier-id="hierId"></template-15>
    </div>
</template>

<script>
    import CommonMixIn from "./common.mixin"
    import ListTemplate from "./list"

    export default {
        name: "List",
        mixins: [CommonMixIn],
        props: {
            data: {},
            type: {},
            searchWord: {},
            dataSource: {},
            hierId: {}
        },
        components: {
            "template-00": ListTemplate.Template00,
            "template-01": ListTemplate.Template01,
            "template-02": ListTemplate.Template02,
            "template-03": ListTemplate.Template03,
            "template-04": ListTemplate.Template04,
            "template-05": ListTemplate.Template05,
            "template-06": ListTemplate.Template06,
            "template-07": ListTemplate.Template07,
            "template-08": ListTemplate.Template08,
            "template-11": ListTemplate.Template11,
            "template-14": ListTemplate.Template14,
            "template-15": ListTemplate.Template15
        },
        computed: {
            listStyle() {
                return this.data.style
            },
            getListStyle() {
                if (this.data.style && this.data.style.background) {
                    return Object.assign(
                        {},
                        this.getBackground(this.data.style.background),
                        this.getPadding(this.data.style.background.padding),
                        this.getMargin(this.data.style.background.margin)
                    )
                }
                return {}
            }
        }

    }
</script>

<style lang="less">

    .minisite-list-list {
        width: 100%;

        .desc-container {
            margin: 0;

            p {
                margin: 0;
            }
        }
    }

</style>
