<template>
    <div class="minisite-float-button" v-if="isLogin">
        <template-00 v-if="data.style.templateId === '00'" :data="data"></template-00>
    </div>
</template>
<script>
    import collectionTemplate from './collection'
    import { mapGetters } from "vuex";

    export default {
        name: "float-buttonComponent",
        props: {
            data: {}
        },
        components: {
            'template-00': collectionTemplate.Template00,
        },
        computed:{
            ...mapGetters('common', ['WechatInfo']),
            isLogin() {
                return this.WechatInfo && this.WechatInfo.openid
            }
        }
    }
</script>

<style lang="less" scoped>

    .minisite-float-button {
        width: 100%;
        height: 100%;
    }

</style>
