<template>
    <div class="minisite-list" :style="getListStyle" v-if="!data.style.innerListStyle">
        <template-00 v-if="data.style.templateId === '00'" :data="data"></template-00>
        <template-01 v-if="data.style.templateId === '01'" :data="data"></template-01>
        <template-02 v-if="data.style.templateId === '02'" :data="data"></template-02>
        <template-03 v-if="data.style.templateId === '03'" :data="data"></template-03>
        <template-04 v-if="data.style.templateId === '04'" :data="data"></template-04>
    </div>
</template>

<script>
    import CommonMixIn from "./common.mixin"
    import TagListTemplate from "./tagList"

    export default {
        name: "TagList",
        mixins: [CommonMixIn],
        props: {},
        components: {
            "template-00": TagListTemplate.Template00,
            "template-01": TagListTemplate.Template01,
            "template-02": TagListTemplate.Template02,
            "template-03": TagListTemplate.Template03,
            "template-04": TagListTemplate.Template04
        },
        mounted() {
            this.getInnerListStyle()

        },
        computed: {
            getListStyle() {
                if (this.data.style && this.data.style.background) {
                    return Object.assign(
                        {},
                        this.getBackground(this.data.style.background),
                        this.getPadding(this.data.style.background.padding),
                        this.getMargin(this.data.style.background.margin)
                    )
                }
                return {}
            }
        },
        methods: {
            getInnerListStyle() {
                if (this.data.style.innerListStyle) {
                    this.data.contents.forEach((component) => {
                        component.children.forEach((child) => {
                            this.$set(child, "style", this.data.style.innerListStyle)
                        })
                    })
                    this.$delete(this.data.style, "innerListStyle")
                }
            }
        },
        watch: {
            data: {
                deep: true,
                handler() {
                    this.getInnerListStyle()
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .minisite-list {
        width: 100%;
    }

</style>
<style lang="less">
    @import "./tagList/CustomTabs.less";
</style>
