//proxy
export const proxyApi = function (module, target, data, freeLogin = 0) {
    return new Promise((resolve, reject) => {
        window.cw(
            'ajax',
            'proxy',
            data => {
                if (data.result && data.result.error) {
                    reject(data.result.error)
                } else {
                    resolve(data.result)
                }
            },
            err => reject(err),
            {
                module,
                target,
                data,
                freeLogin: freeLogin
            }
        )
    })
}

export const findLimit = async uaId => {
    return await proxyApi('site', 'findLimit', {
        type: 'site',
        uaId: uaId || window.ca('get', 'tid')
    }, 1)
}
