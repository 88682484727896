<template>
    <div id="app" :style="siteCommonStyles">
        <!--        <div class="error-cover"-->
        <!--             v-if="!loading && !isWx && structure.siteInfo.siteData.settings.wechat && structure.siteInfo.siteData.settings.wechat.wechatOnly">-->
        <!--            请在微信中访问该页面-->
        <!--        </div>-->
        <div class="custouch-minisite-wrap" id="custouchSite" :style="getPageStyle">
            <!--            <div class="loading-cover" v-if="loading"></div>-->
            <div class="error-cover" v-if="!loading && !isValid">
                <cc-error-page code="403">
                    <div slot="title">您暂时没有访问权限</div>
                </cc-error-page>
                <!--                您暂时没有权限访问该页面-->
            </div>
            <template v-if="!pageLoading">
                <!--顶部导航-->
                <component-item
                    class="page-nav"
                    ref="pagenav"
                    :nav-height="navHeight"
                    :langs="langs"
                    :class="{
                        float: pageNav.navFloat,
                        split: pageNav.navSplit
                    }"
                    style="z-index: 9999"
                    :data="pageNav.nav"
                    v-if="pageNav.hasNav"></component-item>
                <div class="page-content" id="page-content" :style="[pageNav.navStyle, pageTab.tabStyle]">
                    <template v-for="component in targetData.contents">
                        <component-item :data="component" v-if="component" :key="component.id"></component-item>
                    </template>
                </div>
                <!--标签栏-->
                <component-item
                    class="page-tab"
                    ref="pagetab"
                    :class="{ float: pageTab.tabFloat }"
                    :data="pageTab.tab"
                    v-if="pageTab.hasTab"></component-item>

                <div class="open-on-phone" v-if="isPc" @click.stop="openOnPhone">
                    <svg
                        viewBox="64 64 896 896"
                        data-icon="qrcode"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                        class="">
                        <path
                            d="M468 128H160c-17.7 0-32 14.3-32 32v308c0 4.4 3.6 8 8 8h332c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-56 284H192V192h220v220zm-138-74h56c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm194 210H136c-4.4 0-8 3.6-8 8v308c0 17.7 14.3 32 32 32h308c4.4 0 8-3.6 8-8V556c0-4.4-3.6-8-8-8zm-56 284H192V612h220v220zm-138-74h56c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm590-630H556c-4.4 0-8 3.6-8 8v332c0 4.4 3.6 8 8 8h332c4.4 0 8-3.6 8-8V160c0-17.7-14.3-32-32-32zm-32 284H612V192h220v220zm-138-74h56c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm194 210h-48c-4.4 0-8 3.6-8 8v134h-78V556c0-4.4-3.6-8-8-8H556c-4.4 0-8 3.6-8 8v332c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V644h78v102c0 4.4 3.6 8 8 8h190c4.4 0 8-3.6 8-8V556c0-4.4-3.6-8-8-8zM746 832h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm142 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                    </svg>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Vue from "vue"
    import commonMixIn from "./components/common.mixin"
    import tabMixIn from "./components/tab.mixin"
    import CollectionMixIn from "~@/components/functions/collection.mixin"
    import ComponentItem from "./components/ComponentItem"
    import { getAccess, findLimit } from "@/proxy"
    import axios from "axios"
    import { ErrorPage, register } from "@custouch/ui-components/dist-global/index.mjs"

    import ClickAction from "./components/common/ClickAction"
    import { getDataRow, getSubmitResults, getFormDetail, AuditStatus } from "@custouch/proxy-use"

    Vue.component(ClickAction.name, ClickAction)

    import store from "./store"
    import { GetQueryString, ReplaceParamVal } from "@/utils/linkParams"
    import { Config } from "@/utils/configs"

    import "@/utils/preflight"
    import { getOriginOpenId, getUtm, analyzeSearchParams } from "@/utils/preflight"
    import { concatURI } from "@/utils/common"
    import { getADParams } from "@/utils/preflight"
    import { setConfig } from "@custouch/resource-video"
    // import VConsole from 'vconsole'
    // let vConsole = new VConsole() // 初始化
    register()
    export default {
        name: "app",
        mixins: [commonMixIn, CollectionMixIn, tabMixIn],
        components: {
            ComponentItem
            // CcErrorPage: ErrorPage
        },
        store,
        data() {
            return {
                feat_minisite_auth_mutiple_questionnaire: false,
                langs: {
                    current: "default",
                    list: []
                },
                // lang: 'default',
                targetData: {},
                pageStyle: {},
                tabHeight: 0,
                navHeight: 0,
                loading: true,
                isValid: false,
                needLogin: false,
                freeLogin: false,
                originOpenId: "",
                platformLimit: {},
                innerHeight: window.innerHeight
            }
        },
        props: ["structure", "pageId", "siteId", "lang", "appid", "noTrack", "noShare", "tagIds"],
        async created() {
            let _this = this
            this.structure.style = JSON.parse(this.structure.style)
            this.langs.list = this.structure.siteInfo.siteData.settings.langs.filter((_) => _.visible)
            let currentLang = this.$root.lang || this.lang || "default"
            this.targetData = this.structure.contents[currentLang] || this.structure.contents.default
            this.setPageData(this.targetData)
            this.setSiteData(this.structure)
            this.setPageId(this.pageId || this.$root.pageId)
            this.setTagIds(this.tagIds || this.$root.tagIds)
            this.setSiteId(this.siteId || this.$root.siteId)
            this.setAppId(this.$root.appid || this.appid)
            this.setLang(this.langs.list.filter((_) => _.id === currentLang).length > 0 ? currentLang : "default")

            if (this.structure.type === "template") {
                await this.getCMSData()
            }

            this.setPageTitle(this.targetData.title)

            analyzeSearchParams(this._UAId)

            this.langs.current = this._lang
            document.title = this.targetData.title

            if (this._appId) {
                window.cw("appid", this._appId)
            }
            await this.getPageAccess()
        },
        async mounted() {
            window.addEventListener("resize", this.handleResize)
            let _this = this
            _this.getWechatInfo(false)
            _this.$nextTick(() => {
                document.getElementsByTagName("body")[0].addEventListener(
                    "touchmove",
                    function (ev) {
                        ev.stopPropagation()
                    },
                    false
                )
            })
            Vue.prototype.$ISMEMBER = false
        },
        computed: {
            // 页面是否处于加载状态
            pageLoading() {
                return this.loading || !this.isValid
            },
            isWx() {
                return /MicroMessenger/.test(window.navigator.userAgent)
            },
            siteCommonStyles() {
                let _res = {}
                let _site = this._siteData.siteInfo.siteData
                if (_site.styles) {
                    if (_site.styles.color) {
                        Object.assign(_res, {
                            "--c-site-primary-color-": _site.styles?.color
                        })
                    }
                }
                return _res
            },
            isPc() {
                let userAgentInfo = navigator.userAgent
                let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
                let flag = true
                for (let v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        flag = false
                        break
                    }
                }
                return flag
            },
            getPageStyle() {
                if (this.targetData) {
                    return Object.assign({}, this.getPageBackground)
                }
                return {}
            },
            getPageHeight() {
                // 页面高度减去tab
                // let height = window.innerHeight - this.tabHeight
                let height = this.innerHeight
                /*页面高度减去nav*/
                if (this.navHeight && this.navIsSplit && this.navIsFloat) {
                    // height -= this.navHeight
                }

                return {
                    overflowY: "hidden",
                    height: this.structure.settings.type === "singlePage" ? height + "px" : "auto",
                    minHeight: height + "px"
                }
            },
            getPageBackground() {
                let _res = {}

                if (this.structure.style && this.structure.style.background) {
                    _res = Object.assign(
                        {},
                        this.getBackground(this.structure.style.background),
                        this.getPageHeight,
                        this.getPadding(this.structure.style.background.padding),
                        this.getMargin(this.structure.style.background.margin)
                    )
                }
                let _site = this._siteData.siteInfo.siteData
                if (!(_res.backgroundColor || _res.backgroundImage) && _site.styles && _site.styles.background) {
                    _res = Object.assign({}, _res, _site.styles?.background)
                }
                return _res
            }

            // tabHeight() {
            //     this.$nextTick(() => {
            //         console.log(this.$refs)
            //         return this.$refs.pagetab ? this.$refs.pagetab.$el.offsetHeight + 'px' : 0
            //     })
            //     return 0
            // },
        },
        watch: {
            pageLoading(nv) {
                if (!nv) {
                    this.$nextTick(() => {
                        this.getTabNavHeight()
                    })
                }
            }
        },
        methods: {
            axios,
            setConfig,
            async getCMSData() {
                try {
                    let _dataRowId = window.location.href.split("/")[6]
                    let _data = await getDataRow({ dataRowId: _dataRowId })
                    console.log(_data)
                    let _cmsConfigs = this.structure.settings.fields
                    this.targetData.title = _data.detail[_cmsConfigs.title]
                    await this.setPageCMSData(_data)
                    this.setTagIds(_data.tagIds)
                } catch (e) {
                    console.log(e)
                }
            },
            async getPageAccess() {
                let _this = this
                let platformLimit = await findLimit("UA-" + _this._siteId)
                //兼容之前数据
                if (!(platformLimit.detail && platformLimit.detail.wechatLimit)) {
                    let { wechat } = this.structure.siteInfo.siteData.settings
                    _this.$set(
                        platformLimit,
                        "detail",
                        Object.assign({
                            wechatLimit: "userinfo",
                            notWechatLimit: wechat && wechat.wechatOnly ? "userinfo" : "nothing"
                        })
                    )
                }
                _this.platformLimit = platformLimit
                _this.checkAccess()
            },
            async login() {
                let _this = this
                return new Promise((resolve, reject) => {
                    if (_this.freeLogin) {
                        resolve(true)
                    } else {
                        _this.getUserInfo().then(async (info) => {
                            _this.setWechatInfo(info)
                            _this.getWechatInfo(true)
                            Vue.prototype.$ISMEMBER = await _this.checkMember()
                            if (_this.$ISMEMBER) {
                                _this.getBookmark()
                            }
                            getAccess(this._UAId).then((res) => {
                                if (res.code == 200) {
                                    resolve(true)
                                } else if (res.code == 302) {
                                    // 需要填表 redirect
                                    // let _link = concatURI(res.redirect, getADParams())
                                    // window.location.href = res.redirect
                                    // window.location.href = ReplaceParamVal("returnUrl", window.location.href, _link)
                                    window.location.href = ReplaceParamVal(
                                        "returnUrl",
                                        window.location.href,
                                        res.redirect
                                    )

                                    resolve(false)
                                } else if (res.code == 306) {
                                    // 需要关注 mpQrCode
                                    resolve(true)
                                } else {
                                    // 黑名单...
                                    resolve(false)
                                }
                            })
                        })
                    }
                })
            },
            openOnPhone() {
                window.cw(
                    "qrcode",
                    function () {},
                    function () {},
                    {
                        path: window.location.href
                    },
                    function () {}
                )
            },
            getUserInfo() {
                let _this = this
                return new Promise((resolve, reject) => {
                    window.cw(
                        "info",
                        function (info) {
                            resolve(info)
                        },
                        function () {
                            if (!_this.freeLogin) {
                                window.cw("login")
                            }
                        }
                    )
                })
            },
            async checkAccess() {
                let _this = this

                if (_this.isWx) {
                    switch (this.platformLimit.detail.wechatLimit) {
                        case "userinfo":
                            window.cw("login_scope", "snsapi_userinfo")
                            break
                        case "base":
                            window.cw("login_scope", "snsapi_base")
                            break
                        case "nothing":
                            _this.freeLogin = true
                            break
                    }
                } else {
                    switch (this.platformLimit.detail.notWechatLimit) {
                        case "userinfo":
                            window.cw("login_scope", "snsapi_userinfo")
                            window.cw("allowScanLogin", 1)
                            break
                        case "base":
                            window.cw("login_scope", "snsapi_base")
                            break
                        case "nothing":
                            _this.freeLogin = true
                            break
                    }
                }
                _this.login().then(async (valid) => {
                    let _selfValid = await _this.checkSelfValid()
                    _this.getValidInfo(valid && _selfValid)
                })
            },
            async checkSelfValid() {
                let _res = true
                if (this.structure.settings.afterSubmit && this.structure.settings.afterSubmit.enable) {
                    // 判断forms是否有提交的问卷；没有则跳转form
                    if (this.structure.settings.afterSubmit.forms) {
                        const forms = this.structure.settings.afterSubmit.forms.split(",")
                        let formIds = []
                        forms.forEach((form) => {
                            const splitForm = form.split("/")
                            formIds.push(splitForm[splitForm.length - 1])
                        })
                        try {
                            // 问卷审核状态
                            const formNeedAuditMap = (
                                await Promise.all(await formIds.map(async (id) => await getFormDetail(id)))
                            ).reduce((prev, cur) => {
                                prev[cur.structure.id] = cur.structure.needAudit
                                return prev
                            }, {})

                            let { data } = await getSubmitResults(formIds, 1, 999)
                            if (data.length) {
                                _res = !data.some((item) => {
                                    console.log(item.auditStatus.status)
                                    return (
                                        formNeedAuditMap[item.formId] &&
                                        item.auditStatus.status !== AuditStatus.fullfilled
                                    )
                                })
                            } else {
                                const link = concatURI(this.structure.settings.afterSubmit.form, {
                                    c_default_redirect: window.location.href
                                })
                                parent.window.location.href = link
                                _res = false
                            }
                        } catch (e) {
                            if (e === 401) {
                                _res = true
                            }
                        }
                    }
                    // 兼容旧数据
                    else if (this.structure.settings.afterSubmit.form) {
                        let form = this.structure.settings.afterSubmit.form
                        form = form.split("/")[form.split("/").length - 1]
                        try {
                            let formDetail = await getFormDetail(form)
                            let needAudit = formDetail.structure.needAudit
                            let { data } = await getSubmitResults([form])
                            if (data.length > 0) {
                                if (needAudit && data[0].auditStatus.status != "1") {
                                    _res = false
                                }
                            } else {
                                const link = concatURI(this.structure.settings.afterSubmit.form, {
                                    c_default_redirect: window.location.href
                                })
                                parent.window.location.href = link
                                _res = false
                            }
                        } catch (e) {
                            if (e === 401) {
                                _res = true
                            }
                        }
                    }
                }
                return _res
            },
            getValidInfo(valid) {
                let _this = this
                _this.isValid = valid
                _this.loading = false
                if (valid) {
                    window.ca("set", "appid", window.cw("get", "appid"))
                    window.ca("set", "openid", _this.WechatInfo.openid)
                    window.ca("set", "nickname", _this.WechatInfo.nickname)
                    if (!_this.noTrack) {
                        this.originOpenId = getOriginOpenId()

                        window.ca("send", "pageview", {
                            originOpenId: this.originOpenId,
                            title: _this.targetData.title
                        })
                        let page = {
                            originOpenId: this.originOpenId,
                            ...getUtm(),
                            title: _this.targetData.title,
                            path: window.location.href,
                            uaId: _this._UAId,
                            tagIds: _this._tagIds,
                            pageId: _this._pageId
                        }
                        window.cw("action", "pageview", page)
                        if (this.originOpenId) {
                            window.ca("set", "originOpenId", this.originOpenId)
                            window.cw("action", "recommendview", {
                                originOpenId: this.originOpenId,
                                ...getUtm(),
                                recommenderOpenid: this.originOpenId,
                                title: _this.targetData.title,
                                path: window.location.href,
                                uaId: _this._UAId,
                                tagIds: _this.tagIds,
                                pageId: _this._pageId
                            })
                        }
                    }
                    // 检查是否展示订阅二维码
                    _this.checkSubscribeStatus()
                }
            },
            getTabNavHeight() {
                if (this.pageTab.hasTab) {
                    this.tabHeight = this.$refs.pagetab ? this.$refs.pagetab.$el.offsetHeight : 0
                }
                if (this.pageNav.hasNav && this.pageNav.nav.style) {
                    this.navHeight = this.$refs.pagenav
                        ? this.$refs.pagenav.$el.offsetHeight
                        : parseInt(this.pageNav.nav.style.navStyle.height) +
                          parseInt(this.pageNav.nav.style.navStyle.padding.top) +
                          parseInt(this.pageNav.nav.style.navStyle.padding.bottom)
                }
            },
            getWechatInfo(withOrigin) {
                // let _utmSource = GetQueryString('utm_source')
                // if (this.isWx && !_utmSource && !this.noShare) {
                if (this.isWx && !this.noShare) {
                    if (window.cw) {
                        const link = concatURI(window.location.href, Object.assign(getUtm() || {}))
                        let _info = {
                            title: this.targetData.title,
                            link,
                            desc: this.targetData.description || "点击了解详情",
                            image: this.targetData.image || this.structure.siteInfo.siteData.settings.wxShareInfo.image,
                            withOrigin
                        }
                        window.cw("share", _info)
                        if (this.structure.siteInfo.siteData.settings.wechat.hideOptionMenu) {
                            window.cw("hideOptionMenu")
                        }
                    }
                }
            },
            checkSubscribeStatus() {
                const hasSubscribed = localStorage.getItem("site_has_subscribed")
                let { wechat } = this.structure.siteInfo.siteData.settings
                if (
                    wechat &&
                    wechat.subscribe &&
                    wechat.subscribe.isActive &&
                    !hasSubscribed &&
                    !this.WechatInfo.subscribe
                ) {
                    window.cw(
                        "showQrcode",
                        function () {},
                        function () {},
                        {
                            url: wechat.subscribe.qrcodeUrl,
                            hint: wechat.subscribe.text || "关注公众号获取更多资讯"
                        },
                        function () {
                            localStorage.setItem("site_has_subscribed", true)
                        }
                    )
                }
            },
            handleResize() {
                this.innerHeight = window.innerHeight
            }
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.handleResize)
        }
    }
</script>

<style lang="less">
    @import "./styles/richText.less";

    @page-max-width: 768px;

    html,
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        /*overflow: hidden;*/
        background: #ccc;
        font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC,
            miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
    }

    * {
        -webkit-overflow-scrolling: touch;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        box-sizing: border-box;
    }

    body {
        max-width: @page-max-width;
        min-height: 100%;
        margin: 0 auto;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        width: 100%;
        min-height: 100%;
        /*max-height: 100%;*/
        margin: 0 auto;
        position: relative;
        background: #fff;
        font-size: 14px;
    }

    img {
        -webkit-transform: scale(1) rotate(0) translate3d(0, 0, 0);
        transform: scale(1) rotate(0) translate3d(0, 0, 0);
    }

    /*&::-webkit-scrollbar {*/
    /*    width: 6px;*/
    /*    height: 6px;*/
    /*    background: transparent;*/
    /*    position: absolute;*/
    /*}*/

    /*&::-webkit-scrollbar-thumb {*/
    /*    background: transparent;*/
    /*    border-radius: 4px;*/
    /*}*/

    /*&:hover::-webkit-scrollbar-thumb {*/
    /*    background: hsla(0, 0%, 53%, 0.4);*/
    /*}*/

    /*&:hover::-webkit-scrollbar-track {*/
    /*    background: hsla(0, 0%, 53%, 0.1);*/
    /*}*/
    .loading-cover {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(255, 255, 255, 1);
        color: #a0a0a0;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }

    .error-cover {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #fff;
        color: #fff;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99999;
        padding: 0 48px;
    }

    .custouch-minisite-wrap {
        /*min-height: 100%;*/
        height: 100%;
        /*overflow: auto;*/
        position: static;

        .open-on-phone {
            font-size: 36px;
            background: #fff;
            padding: 6px;
            border-radius: 3px;
            position: absolute;
            top: 10px;
            right: -60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .image-container,
        .page-list-item,
        .minisite-image-container {
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
        }
    }

    .page-nav {
        //width: @iphone-width;
        width: 100%;

        &.float {
            position: fixed;
            top: 0;
            left: 50%;
            width: 100%;
            z-index: 300;
            max-width: @page-max-width;
            margin: 0 auto;
            transform: translateX(-50%);
            /*position: absolute;*/
            /*top: 0;*/
            /*left: 0;*/
            /*width: 100%;*/
        }
    }

    .page-tab {
        &.float {
            position: fixed;
            bottom: 0;
            left: 50%;
            width: 100%;
            z-index: 300;
            max-width: @page-max-width;
            margin: 0 auto;
            transform: translateX(-50%);
        }
    }

    .page-content {
        overflow: hidden;
    }

    figure {
        img {
            max-width: 100%;
        }
    }

    ol,
    ul {
        margin: initial;
        padding: initial;
        list-style: auto;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
</style>
