var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showedData)?_c('div',{staticClass:"minisite-tag-list tag-list-04",style:(_vm.getToggleListStyle)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getShowedComponent.search),expression:"getShowedComponent.search"}],staticClass:"list-search"},[_c('search-component',{attrs:{"settings":_vm.data.search,"search-style":_vm.showedData.style.searchStyle},on:{"search":_vm.search},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1),(_vm.showedData && _vm.showedData.contents&& _vm.showedData.contents.length>0)?_c('div',{staticClass:"custom-tab"},[_c('van-sidebar',{staticClass:"custom-side",style:({
                '--c-site-active-background-color': _vm.data.style.listStyle.active.background.backgroundColor || "var(--c-site-primary-color-)",
                '--c-site-sidebar-background-color': _vm.data.style.listStyle.normal.background.backgroundColor,
                '--c-site-active-color': _vm.data.style.listStyle.active.color || '#fff',
                '--c-site-normal-color': _vm.data.style.listStyle.normal.color,
                backgroundColor:  '#fff'
            }),attrs:{"animated":""},model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},_vm._l((_vm.showedData.contents),function(item,itemIndex){return _c('van-sidebar-item',{key:itemIndex,style:({
                    fontSize: _vm.data.style.listStyle.normal.fontSize + 'px',
                    fontWeight: _vm.data.style.listStyle.normal.fontWeight ? 'bold' : 'normal'
                }),attrs:{"title":item.title.content},on:{"click":_vm.tabChange}})}),1),_c('inner-list',{staticClass:"inner-list",attrs:{"data":_vm.showedData.contents[_vm.activeKey],"data-source":_vm.data.dataSource,"search-word":_vm.searchWord}})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }