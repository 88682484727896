<template>
    <!--     非站内跳转，(微信环境下跳小程序另外处理)-->
    <div class="minisite-link" @click="handleActionClick"
       v-if="data.content.content.length && data.content.content[0].url.length && data.type !== 'sitePages' && !(data.content.type === 'miniProgram' && isWeixin() )">
        <slot></slot>
    </div>
    <!--站内跳转-->
    <div class="minisite-link" @click="handleLink"
         :class="{'current-lang': currentInfo.langId ===data.content.content[0].url}"
         v-else-if="data.type === 'sitePages'">
        <slot></slot>
    </div>
    <!--跳转至模板详情页-->
    <div class="minisite-link" @click="handleDetailLink"
         :class="{'current-lang': currentInfo.langId ===data.content.content[0].url}"
         v-else-if="data.type === 'detailLink'">
        <slot></slot>
    </div>
    <!--    链接为空-->
    <div class="minisite-link"
         v-else-if="!(data.content.content.length && data.content.content[0].url.length)">
        <slot></slot>
    </div>
    <!--    跳转小程序(仅微信环境下)-->
    <div class="minisite-link relative"
        ref="mpWrapper"
         v-else-if="data.content.type === 'miniProgram' && isWeixin()">
        <slot></slot>
        <wx-open-launch-weapp
			:appid="data.content.content[0].appId"
			:path="data.content.content[0].path"
            id="openMPBtn"
			class="mp-wrapper">
            <script type="text/wxtag-template">
                <style>
                    .open-weapp-btn {
                        color: #fff;
                        background-color: transparent;
                        border: none;
                        width:10000px;
                        height:10000px;
                    }
                </style>
                <button class="open-weapp-btn"></button>
            </script>
		</wx-open-launch-weapp>
    </div>
</template>
<slot v-else></slot>

<script>
    import { getADParams, getUtm } from "@/utils/preflight"
    import commonMixIn from "./../common.mixin"
    import { concatURI } from "@/utils/common"
    import { isWeixin } from '@/utils/userAgent'

    export default {
        name: "ClickAction",
        mixins: [commonMixIn],
        props: {
            data: {},
            actionRemark: {}
        },
        mounted() {
            const _this = this
            if(this.data.content.type === 'miniProgram' && isWeixin()) [
                this.$nextTick(() => {
                    const openMPBtn = _this.$refs.mpWrapper?.querySelector('#openMPBtn')
                    if(openMPBtn) {
                        openMPBtn.addEventListener('error', () => {
                            _this.handleActionClick()
                        })
                    }
                })
            ]
        },
        methods: {
            isWeixin,
            handleLink() {
                if (window.location.href !== this.getLink) {
                    window.location.href = this.getLink
                }
            },
            handleActionClick() {
                const click = {
                    originOpenId: this.originOpenId,
                    ...getUtm(),
                    title: this._pageTitle || document.title,
                    path: window.location.href,
                    name: this.data.remark || this.actionRemark,
                    to: this.getLink,
                    uaId: this._UAId,
                    pid: window.ca("get", "pid"),
                    tagIds: this._tagIds
                }
                if (window.cw) {
                    window.cw("action", "clickview", click)
                }
                parent.window.location.href = concatURI(this.getLink, getADParams())
            },
            handleDetailLink() {
                if (this._siteData) {
                    let _childPage = this._siteData.childPages?.filter((_) => {
                        return _.parentComponentId === this.$parent.data.id
                    })
                    let _link = `${this.currentInfo.protocol}//${this.currentInfo.host}/${this._siteId}/gmp/${_childPage[0].pageId}/${this.data.content.content[0].dataRowId}/${this._lang}`
                    // parent.window.location.href = _link
                }
            }
            // getUtmParams() {
            //     let _target = []
            //     _target.push({
            //         name: 'utm_source',
            //         value: GetQueryString('utm_source'),
            //     })
            //     _target.push({
            //         name: 'utm_campaign',
            //         value: GetQueryString('utm_campaign'),
            //     })
            //     _target.push({
            //         name: 'utm_medium',
            //         value: GetQueryString('utm_medium'),
            //     })
            //     _target.push({
            //         name: 'utm_term',
            //         value: GetQueryString('utm_term'),
            //     })
            //     _target.push({
            //         name: 'utm_content',
            //         value: GetQueryString('utm_content'),
            //     })
            //     let _val = _target.map((_) => {
            //         return _.value ? `${_.name}=${_.value}` : ''
            //     })
            //     _val = _compact(_val).join('&')
            //     return _val.length > 0 ? `?${_val}` : ''
            // }
        },
        computed: {
            getLink() {
                let querystring = window.location.href.split("?")[1]
                let _targetLink = this.data.content.content[0].url
                let _target = ""
                switch (this.data.type) {
                    case "sitePages":
                        _target = this.currentInfo.protocol + "//" + this.currentInfo.host + "/" + this._siteId + "/"
                        switch (this.data.content.type) {
                            // 站内页面跳转取消qs继承
                            case "langs":
                                if (this.currentInfo.langId && this.currentInfo.langId !== this.data.content.content[0].url) {
                                    if (this.currentInfo.pageId !== "index") {
                                        _target = _target + this._pageId + "/" + this.data.content.content[0].url
                                    } else {
                                        _target = _target + "index" + "/" + this.data.content.content[0].url
                                    }
                                    // window.location.href = querystring ? (_target + '?' + querystring) : _target
                                    // window.location.href = _target
                                } else return window.location.href
                                break
                            case "home":
                                // 当前不是首页
                                if (this.currentInfo.pageId !== "index") {
                                    _target = _target + "index" + "/" + this._lang
                                    // window.location.href = querystring ? (_target + '?' + querystring) : _target
                                    // window.location.href = _target
                                }
                                break
                            default:
                                if (this.data.content.content[0].lang) {
                                    _target = this.data.content.content[0].url + "/" + this.data.content.content[0].lang
                                } else {
                                    _target = this.data.content.content[0].url
                                }
                                // + '/' + (this.data.content.content[0].lang || this._lang)
                                // window.location.href = querystring ? (_target + '?' + querystring) : _target
                                // window.location.href = _target
                                break
                        }

                        // 站内页面跳转需要继承utm参数
                        return _target
                    default:
                        switch (this.data.content.type) {
                            case "phone":
                                return "tel:" + _targetLink
                            case "email":
                                return "mailto:" + _targetLink
                            default:
                                _targetLink = querystring ? (_targetLink + (_targetLink.indexOf("?") > -1 ? "&" : "?") + querystring) : _targetLink
                                return _targetLink
                        }

                }
            },
            currentInfo() {
                let host = window.location.host
                let protocol = window.location.protocol
                let _pathList = window.location.pathname.split("/")
                return {
                    host: host,
                    protocol: protocol,
                    siteId: _pathList[1],
                    pageId: _pathList[2],
                    langId: this._lang
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .minisite-link {
        text-decoration: none;
        cursor: pointer;

        &.langs-link {
            color: #0366d6;
        }

        &.current-lang {
            color: initial;
        }
        .mp-wrapper {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 10;
            overflow:hidden;
        }
    }
    .relative {
        position:  relative;
    }
</style>
