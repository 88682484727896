import CommonMixIn from "./../common.mixin"
import { BasicImage } from "@/components/basic"

export default {
    mixins: [CommonMixIn],
    components: {
        BasicImage
    },
    props: {
        data: {},
        searchStyle: {},
        value: {}
    },
    data() {
        return {}
    },
    created() {
        this.search()
    },
    computed: {
        getSearchStyle() {
            let _searchStyle = this.searchStyle ? this.searchStyle : this.data?.style?.searchStyle
            return Object.assign({},
                _searchStyle,
                {
                    borderColor: _searchStyle?.borderColor,
                    borderRadius: _searchStyle?.borderRadius + "px",
                    borderWidth: _searchStyle?.borderWidth + "px"
                }
            )
        },
        getSearchContainerStyle() {
            let _searchStyle = this.searchStyle ? this.searchStyle : this.data?.style?.searchStyle
            return this.getMargin(_searchStyle?.margin)
        },
        searchWord: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit("input", val.trim().toLowerCase())
            }
        }
    },
    methods: {
        search() {
            this.$emit("search", this.searchWord?.trim().toLowerCase())
        }
    },
    watch: {
        searchWord(val) {
            this.search()
        }
    }
}
