import CommonMixIn from './../common.mixin'
import { BasicImage } from "@/components/basic";

export default {
    mixins: [CommonMixIn],
    components: {
        BasicImage
    },
    props: {
        data: {},
        langs: {},
        navHeight: {}
    },
    computed: {
        getNavStyle() {
            return Object.assign(
                {},
                this.data.style.navStyle,
                this.getBackground(this.data.style.navStyle.background),
                this.getPadding(this.data.style.navStyle.padding),
                {
                    height: this.data.style.navStyle.height + 'px',
                    'box-sizing': 'content-box'
                }
            )
        },
        getNavComponentStyle() {
            return {
                maxHeight: (this.data.style.navStyle.height) + 'px'
            }
        }
    }
}
