<template>
    <div class="minisite-nav" :style="getNavSplit">
        <template-00 v-if="data.style.templateId === '00'" :data="data"
                     :nav-height="navHeight"
                     :langs="langs"></template-00>
        <template-01 v-if="data.style.templateId === '01'" :data="data"
                     :langs="langs"></template-01>
    </div>
</template>

<script>
    import CommonMixIn from './common.mixin'
    import NavTemplate from './nav'

    export default {
        name: "NavComponent",
        mixins: [CommonMixIn],
        props: {
            langs: {},
            navHeight: {}
        },
        components: {
            'template-00': NavTemplate.Template00,
            'template-01': NavTemplate.Template01
        },
        computed: {
            getNavSplit() {
                return {
                    marginBottom: this.data.style.navStyle.split ? '' : -this.navHeight + 'px'
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .minisite-nav {
        .minisite-nav-item {
            z-index: 9999;
        }
    }

</style>
