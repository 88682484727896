<template>
    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-03">
        <template v-for="(_item, i) in cubeNavListData">
            <div v-if="_item.length" :key="i" class="list-content last-not-margin" :style="{ marginBottom: spacing }">
                <template v-for="(boxItem,index) in _item">
                    <template v-if="boxItem.layout === 1">
                        <cube-nav-item
                            v-for="item in boxItem.data"
                            :key="item.id"
                            :data="data"
                            :item-data="item"
                            :style="{ marginRight: spacing }"
                            class="minisite-cube-nav-item">
                        </cube-nav-item>
                    </template>
                    <template v-if="boxItem.layout === 2">
                        <div :key="index" class="last-not-margin two-modules" :style="{ marginRight: spacing }">
                            <cube-nav-item
                                v-for="item in boxItem.data"
                                :key="item.id"
                                :style="{ marginBottom: spacing }"
                                :data="data"
                                :item-data="item"
                                class="minisite-cube-nav-item">
                            </cube-nav-item>
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import CubeNavItem from "@/components/cubeNav/CubeNavItem.vue"

    export default {
        name: "Template03",
        mixins: [cubeMixin],
        components: { CubeNavItem },
        props: {
            data: {}
        },
        data() {
            return {
                maxWidth: "auto",
                // 设备宽度
                deviceWidth: 375
            }
        },
        computed: {
            spacing() {
                return this.cubeNavStyle.gap + "px"
            },
            cubeNavListData() {
                const target = []
                const contents = this.data.contents
                const columns = 6
                for (let i = 0; i < contents.length; i += columns) {
                    target.push(...this.sliceCubeNavData(contents.slice(i, i + columns)))
                }
                return target
            }
        },
        mounted() {},
        methods: {
            _isEmpty,
            /**
             * 将数据按照 布局方式 进行分割处理
             * @param list
             * @returns {[][]}
             */
            sliceCubeNavData(list) {
                const layout = this.cubeNavStyle.layout.split("-").map((v) => +v)

                // 将数据分为上下两部分拆开处理
                const dataMap = {
                    top: list.slice(0, 3),
                    bottom: list.slice(3, 6)
                }
                const layoutMap = {
                    top: layout.slice(0, 2),
                    bottom: layout.slice(2, 4)
                }
                const targetMap = {
                    top: [],
                    bottom: []
                }

                Object.entries(layoutMap).forEach(([key, val]) => {
                    // 每三条数据，可以形成完整布局的一部分
                    if (dataMap[key].length === 3) {
                        targetMap[key].push({
                            layout: val[0],
                            data: dataMap[key].slice(0, val[0])
                        })
                        targetMap[key].push({
                            layout: val[1],
                            data: dataMap[key].slice(val[0], val[0] + val[1])
                        })
                        // 不够三条数据，就按照布局 1 进行处理
                    } else {
                        dataMap[key].forEach((_) =>
                            targetMap[key].push({
                                layout: 1,
                                data: [_]
                            })
                        )
                    }
                })
                return [targetMap.top, targetMap.bottom]
            }
        }
    }
</script>
<style lang="less" scoped>
    .minisite-cube-nav-03 {
        .list-content {
            display: flex;

            > * {
                width: 50%;
                flex-grow: 1;
                flex-shrink: 1;
            }

            .two-modules {
                display: flex;
                flex-direction: column;
                max-width: 50%;
            }
        }

        .last-not-margin {
            :last-child {
                margin: 0 !important;
            }
        }

        .minisite-cube-nav-item {
            display: flex;
            flex-grow: 1;
            //overflow: hidden;
        }

        /deep/ .text-container {
            display: flex;
            flex-direction: column;
            //align-items: center;
        }

        /deep/ .main-item-content {
            display: flex;
            //justify-content: center;
            //align-items: center;
            flex-grow: 1;
            overflow: hidden;
        }
    }
</style>
