<template>
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners" :style="getIconStyle">
        <use :xlink:href="iconName"/>
    </svg>
</template>

<script>
    const req = require.context("./", false, /\.svg$/);
    const requireAll = requireContext => requireContext.keys().map(requireContext)
    requireAll(req)

    export default {
        name: 'SvgIcon',
        props: {
            iconClass: {
                type: String,
                required: true
            },
            className: {
                type: String,
                default: ''
            },
            svgStyle: {
                type: Object
            },
            svgType: {
                type: String,
                default: ''
            }
        },
        computed: {
            iconName() {
                if (this.svgType === 'fill') {
                    return `#icon-${this.iconClass}-${this.svgType}`
                }
                return `#icon-${this.iconClass}`
            },
            svgClass() {
                if (this.className) {
                    return 'svg-icon ' + this.className + this.svgType
                } else {
                    return 'svg-icon'
                }
            },
            styleExternalIcon() {
                return {
                    mask: `url(${this.iconClass}) no-repeat 50% 50%`,
                    '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`
                }
            },
            getIconStyle() {
                return this.svgStyle
            }
        }
    }
</script>

<style scoped lang="less">
    .svg-icon {
        //max-width: 25px;
        //max-height: 25px;
        fill: currentColor;
        overflow: hidden;
        transition: all .3s ease;
    }
</style>
