import { BasicText, BasicImage, BasicButton } from "./../basic/index"
import SearchComponent from "../SearchComponent"
import commonMixIn from "./../common.mixin"
import InnerList from "./../InnerList"
import ComponentItem from "./../../components/ComponentItem"
import _cloneDeep from "lodash/cloneDeep"
import _has from "lodash/has"
import { SearchInnerList } from "../search/search"
import { GetQueryString } from "@/utils/linkParams"
import { getDataModule, searchDataRowsInGlobal } from "@custouch/proxy-use"

export default {
    mixins: [commonMixIn],
    components: {
        BasicText,
        BasicImage,
        BasicButton,
        ComponentItem,
        InnerList,
        SearchComponent
    },
    data() {
        return {
            showedData: null,
            searchWord: ""
        }
    },
    async mounted() {
        await this.getListData()
        this.data.contents.forEach((content) => {
            content.matched = true
            content.children.forEach((child) => {
                child.contents.forEach((_ => {
                    this.$set(_, "matched", true)
                }))
            })
        })
        this.$set(this, "showedData", _cloneDeep(this.data))
    },
    methods: { async getListData() {
            let _dataSource = this.data.dataSource ? this.data.dataSource.type : "default"
            switch (_dataSource) {
                case "default":
                    this.getNormalData()
                    break
                case "cms":
                    await this.getCMSData()
                    break
                default:
                    this.getNormalData()
                    break

            }
        },
        getNormalData() {
            this.listData = this.data.contents.map((_) => {
                let _item = {}
                _item.id = _.id
                if (_.title.show) {
                    _item.title = _.title.content
                }
                if (_.desc.show) {
                    _item.desc = _.desc.content
                }
                if (_.button && _.button.show) {
                    _item.button = _.button.buttonText
                }
                if (_.icon.show) {
                    _item.icon = {
                        imageSrc: _.icon.imageSrc
                    }
                }
                if (_.image.show) {
                    _item.image = {
                        imageSrc: _.image.imageSrc,
                        imageAlt: _.image.imageAlt,
                        imageTitle: _.image.imageTitle
                    }
                }
                if (_.count) {
                    _item.count = _.count
                }
                if (_.createTime) {
                    _item.createTime = _.createTime
                }
                if (_.formatCreateTime) {
                    _item.formatCreateTime = _.formatCreateTime
                }
                _item.clickAction = _.clickAction
                return _item
            })

        },
        async getCMSData() {
            try {
                let cmsConfigs = this.data.dataSource.cms
                let data = await getDataModule({
                    dataModuleId: cmsConfigs.moduleId
                })
                this.moduleDetail = data

                let _target = []
                let _itemTemplate = this.data.contents[0]
                data.dataHierarchies.forEach((item) => {
                    _target.push(Object.assign({}, _itemTemplate, {
                        id: item.id,
                        title: {
                            content: cmsConfigs.fields.title === "hierName" ? item.name : item.detail[cmsConfigs.fields.title] || "默认标题"
                        },
                        detail: item.detail
                    }))
                })
                this.data.contents = _target
            } catch (e) {
                console.log(e)
            }
        },
        async search(word) {
            if (!word || word.length < 1) {
                this.showedData = _cloneDeep(this.data)
            } else {
                // let _res = SearchInnerList(this.data, word)
                // this.showedData = _res
                let _dataSource = this.data.dataSource ? this.data.dataSource.type : "default"
                switch (_dataSource) {
                    case "default":
                        this.showedData = SearchInnerList(this.data, word)
                        break
                    case "cms":
                        await this.searchInCMS(word)
                        break
                    default:
                        this.showedData = SearchInnerList(this.data, word)
                        break
                }
            }
        },
        async searchInCMS(word) {
            try {
                let cmsConfigs = this.data.dataSource.cms

                let _res = await searchDataRowsInGlobal(
                    {
                        dataHierarchies: this.moduleDetail.dataHierarchies.map(_ => _.id),
                        /** SearchGroupBy 需和 dataModules｜dataHierarchies 对应*/
                        groupBy: ["dataHierarchy"],
                        searchKey: word
                        /** 正序｜倒序 */
                    }
                )
            } catch (e) {
                console.log(e)
            }
        },
        hasBg(bg) {
            let _bg = this.getBackground(bg)
            return _bg && (_bg.backgroundColor || _bg.backgroundImage.length > 7)
        }
    },
    computed: {
        getImageStyle() {
            return Object.assign({},
                this.getBackground(this.data.style.imageStyle.background),
                this.getPadding(this.data.style.imageStyle.padding)
            )
        },
        getDivideStyle() {
            return {
                // 'margin': this.data.style.divideLineStyle.margin + 'px 0',
                // 'width': this.data.style.divideLineStyle.width,
                "width": this.data.style.divideLineStyle.width + "%",
                "border-bottom-style": this.data.style.divideLineStyle.style,
                "border-bottom-width": this.data.style.divideLineStyle.height + "px",
                "border-bottom-color": this.data.style.divideLineStyle.color
            }
        },
        getCommonStyle() {
            return Object.assign(
                {},
                this.data.style.common,
                { borderRadius: this.data.style.common.borderRadius + "px" },
                this.getBackground(this.data.style.common.background),
                this.getPadding(this.data.style.common.padding),
                this.getMargin(this.data.style.common.margin)
            )
        },
        getHeaderStyle() {
            return Object.assign(
                {},
                this.data.style.headerStyle,
                this.getBackground(this.data.style.headerStyle.background),
                this.getPadding(this.data.style.headerStyle.padding),
                { borderRadius: this.data.style.headerStyle.borderRadius + "px" },
            )
        },
        getActiveHeaderStyle() {
            if (_has(this.data.style.headerStyle, "active")) {
                return Object.assign(
                    {},
                    this.getHeaderStyle,
                    this.data.style.headerStyle.active,
                    (this.hasBg(this.data.style.headerStyle.active?.background) ? this.getBackground(this.data.style.headerStyle.active?.background) : this.getBackground(this.data.style.headerStyle.background)),
                    this.getHeaderStyle.padding,
                    {
                        boxShadow:this.data.style.headerStyle.boxShadow,
                        border:this.data.style.headerStyle.border,
                    }
                )
            }
            return this.getHeaderStyle

        },
        getListStyle() {
            if (this.showedData.style) {
                return Object.assign(
                    {},
                    this.showedData.style.innerListStyle.listStyle,
                    this.getBackground(this.showedData.style.innerListStyle.listStyle.background),
                    this.getPadding(this.showedData.style.innerListStyle.listStyle.padding),
                    this.getMargin(this.showedData.style.innerListStyle.listStyle.margin),
                    { borderRadius: this.showedData.style.innerListStyle.listStyle.borderRadius + "px" }
                )
            }
            return {}
        },
        getShowedComponent() {
            if (this.data.style) {
                return {
                    image: this.data.style.imageStyle.show,
                    search: this.data.style.searchStyle ? this.data.style.searchStyle.show : false,
                    title: this.data.style.titleFontStyle.show,
                    desc: this.data.style.descFontStyle ? this.data.style.descFontStyle.show : false,
                    button: this.data.style.buttonStyle ? this.data.style.buttonStyle.show : false,
                    icon: this.data.style.iconStyle.show ? this.data.style.iconStyle.show : false,
                    divideLine: this.data.style.divideLineStyle ? this.data.style.divideLineStyle.show : false
                }
            }
            return {}
        }
    }
}
