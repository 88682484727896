<template>
    <div class="text-container ck-content" :style="getFontStyle" v-html="data">
    </div>
</template>

<script>
    import componentMixIn from './component.mixin'

    export default {
        name: "BasicText",
        mixins: [componentMixIn],
        props: {
            fontStyle: {}
        },
        computed: {

        }
    }
</script>

<style lang="less" scoped>

</style>
