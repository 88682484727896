<template>
    <div class="minisite-button-container" :class="'button-' + buttonStyle.position">
        <div :style="getButtonStyle">
            <basic-text :data="data"></basic-text>
        </div>
    </div>
</template>

<script>
    import BasicText from './Text'
    import componentMixIn from './component.mixin'

    export default {
        name: "BasicButton",
        props: {
            buttonStyle: {}
            // data: {}
        },
        mixins: [componentMixIn],
        components: {
            BasicText
        },
        computed: {}
    }
</script>

<style lang="less" scoped>
    .minisite-button-container {
        display: flex;
        text-align: center;
        color: var(--c-site-primary-color-);


        &.button-left {
            justify-content: flex-start;
        }

        &.button-center {
            justify-content: center;
        }

        &.button-right {
            justify-content: flex-end;
        }
    }

</style>
