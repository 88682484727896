import {
    BasicText,
    BasicImage,
    BasicButton,
    BasicButtonImageCollection
} from "~@/components/basic/index"
import _has from "lodash/has";
import CommonMixin from "@/components/common.mixin"

export default {
    mixins: [CommonMixin],
    props: {},
    components: {
        BasicText,
        BasicImage,
        BasicButton,
        BasicButtonImageCollection
    },
    data() {
        return {
            cubeNavStyle: {}
        }
    },
    mounted() {
        this.cubeNavStyle = this.data.style.cubeNavStyle
    },
    computed: {
        getImageStyle() {
            let target = {}
            if (this.cubeNavStyle.justifyContent === "space-between" && this.cubeNavStyle.imageLocation === "bottom") {
                target = {
                    alignSelf: "end"
                }
            }
            return Object.assign(
                {},
                this.getBackground(this.cubeNavStyle.imageStyle.background),
                this.getPadding(this.cubeNavStyle.imageStyle.padding),
                {
                    borderRadius: this.cubeNavStyle.imageStyle.background?.isRound
                        ? "100%"
                        : (this.cubeNavStyle.imageStyle.background?.borderRadius ?? 0) + "px"
                },
                target
            )
        },
        getCubeNavStyle() {
            return Object.assign({}, this.cubeNavStyle, this.cubeNavStyle.background, {
                ...this.getPadding(this.cubeNavStyle.padding),
                ...this.getMargin(this.cubeNavStyle.margin),
                borderRadius: this.cubeNavStyle.borderRadius + "px",
                width: _has(this.cubeNavStyle, "width") ? this.cubeNavStyle.width + "%" : "",
            })
        },
        getCubeNavItemClass() {
            const { imageLocation='empty', justifyContent='empty', alignItems='empty' } = this.cubeNavStyle
            return `image-location-${imageLocation} ${justifyContent} ${alignItems}`
        },
        getTextContentClass() {
            let target = ""
            if ((this.cubeNavStyle.justifyContent === "end" && ['top', 'bottom'].includes(this.cubeNavStyle.imageLocation)) ||
                (this.cubeNavStyle.justifyContent === "space-between" &&
                    !["right", "bottom", 'left'].includes(this.cubeNavStyle.imageLocation))
            ) {
                target = "text-content-right"
            } else if (this.cubeNavStyle.justifyContent === "center") {
                target = "text-content-center"
            } else if(this.cubeNavStyle.justifyContent === "end") {
                target = "text-right"
            }
            return target
        },
        checkShowComponent() {
            if (this.cubeNavStyle.imageStyle) {
                return {
                    image: this.cubeNavStyle.imageStyle && this.cubeNavStyle.imageStyle.show,
                    title: this.cubeNavStyle.titleStyle && this.cubeNavStyle.titleStyle.show,
                    subtitle: this.cubeNavStyle.subtitleStyle && this.cubeNavStyle.subtitleStyle.show,
                    button:
                        this.cubeNavStyle.buttonStyle &&
                        this.cubeNavStyle.buttonStyle.show &&
                        this.cubeNavStyle.imageLocation === "top",
                    icon: this.cubeNavStyle.iconStyle && this.cubeNavStyle.iconStyle.show
                }
            }
            return {}
        }
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.cubeNavStyle = this.data.style.cubeNavStyle
            }
        }
    }
}
