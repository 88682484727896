<template>
    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-01">
        <div class="list-wrapper" :key="cubeNavStyle.colums + data.contents.length">
            <div
                v-for="(arr, i) in cubeNavListData"
                :key="i"
                :style="listStyle"
                :class="i % 2 === 0 ? 'big-to-small' : 'small-to-big'"
                class="list-content">
                <cube-nav-item
                    v-for="(item, itemIndex) in arr"
                    :key="`${item.id}_${itemIndex}`"
                    :style="{
                        ...listItemStyle
                    }"
                    :data="data"
                    :item-data="item"
                    class="minisite-cube-nav-item">
                </cube-nav-item>
            </div>
        </div>
    </div>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import CubeNavItem from "@/components/cubeNav/CubeNavItem.vue"

    export default {
        name: "Template01",
        mixins: [cubeMixin],
        components: { CubeNavItem },
        props: {
            data: {}
        },
        data() {
            return {
                maxWidth: "auto",
                // 设备宽度
                deviceWidth: 375
            }
        },
        computed: {
            listItemStyle() {
                return {
                    marginRight: this.cubeNavStyle.gap + "px"
                }
            },
            listStyle() {
                return {
                    marginBottom: this.cubeNavStyle.gap + "px",
                    justifyContent: this.data.style.listStyle.justifyContent
                }
            },
            cubeNavListData() {
                const target = []
                const contents = this.$props.data.contents
                const columns = 2

                for (let i = 0; i < contents.length; i += columns) {
                    target.push(contents.slice(i, i + columns))
                }
                return target
            },
            // 计算模块宽度
            cubeNavItemWidth() {
                const { margin, padding: listPadding } = this.data.style.listStyle
                const { gap, columns, padding: itemPadding } = this.data.style.cubeNavStyle
                const count = this.data.contents.length
                const flag = Math.min(+columns, count)
                return (
                    (this.deviceWidth -
                        +gap * (flag - 1) -
                        listPadding.left -
                        listPadding.right -
                        margin.left -
                        margin.right) /
                    flag
                )
            }
        },
        mounted() {},
        methods: {
            _isEmpty
        }
    }
</script>

<style lang="less" scoped>
    .minisite-cube-nav-01 {
        .list-wrapper {
            > :last-child {
                margin-bottom: 0 !important;
            }

            .list-content {
                display: flex;

                > :last-child {
                    margin-right: 0 !important;
                }
            }
        }

        .big-to-small {
            > :first-child {
                width: 60%;
            }

            > :last-child {
                width: 40%;
            }
        }

        .small-to-big {
            > :first-child {
                width: 40%;
            }

            > :last-child {
                width: 60%;
            }
        }

        .minisite-cube-nav-item {
            display: flex;
            flex-grow: 1;
            //overflow: hidden;
        }

        /deep/ .text-container {
            display: flex;
            flex-direction: column;
            //align-items: center;
        }

        /deep/ .main-item-content {
            display: flex;
            //justify-content: center;
            //align-items: center;
            flex-grow: 1;
            overflow: hidden;
        }
    }
</style>
