<template>
    <div class="minisite-float-button-item float-button-00" :style="getFloatButtonStyle">
        <div class="float-button-item">
            <click-action :data="data.contents.clickAction">
                <div class="float-button-icon-container">
                    <img class="float-button-icon"
                         :src="data.contents.imageSrc" alt="">
                </div>
                <div class="float-button-title">
                    <basic-text data=""
                                :font-style="data.style.fontStyle"
                    ></basic-text>
                </div>
            </click-action>
        </div>
    </div>
</template>

<script>
    import floatButtonMixin from './floatButton.mixin'

    export default {
        name: "Template-00",
        mixins: [floatButtonMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .float-button-00 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /*box-shadow: 0 2px 8px #8e8e8e;*/

        .float-button-icon-container {
            .float-button-icon {
                max-width: 100%;
                max-height: 100%;
            }
        }

    }

</style>
