<template>
    <div class="minisite-button" :style="getButtonStyle">
        <template-00 v-if="listStyle.templateId === '00'" :data="data"></template-00>
    </div>
</template>

<script>
    import CommonMixIn from './common.mixin'
    import ButtonTemplate from './button'

    export default {
        name: "Button",
        mixins: [CommonMixIn],
        props: {
        },
        components: {
            'template-00': ButtonTemplate.Template00,
        },
        computed: {
            listStyle() {
                return this.data.style
            },
            getButtonStyle() {
                if (this.data.style && this.data.style.background) {
                    return Object.assign(
                        {},
                        this.getBackground(this.data.style.background),
                        this.getPadding(this.data.style.background.padding),
                        this.getMargin(this.data.style.background.margin)
                    )
                }
                return {}
            }
        }

    }
</script>

<style lang="less" scoped>

    .minisite-button {
        width: 100%;
    }

</style>
