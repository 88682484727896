<template>
    <div class="langs-list template-00">
        <div class="langs-item" v-for="(item,itemIndex) in langs.list" :key="item.id"
             :class="{}">
            <click-action
                class="langs-link" :class="{
                'current': langs.current === item.id,
                'last-item': itemIndex === langs.list.length-1
            }"
                :data="{
                type:'sitePages',
                content:{
                    type:'langs',
                    content:[
                        {
                            url:item.id
                        }
                    ]
                }
            }">
                {{item.name}}
            </click-action>
            <span class="divide-line" v-if="itemIndex < langs.list.length-1"></span>
        </div>
    </div>
</template>

<script>
    import langsMixIn from './langs.mixin'

    export default {
        name: "Template-00",
        mixins: [langsMixIn],
        mounted() {

        }
    }
</script>

<style lang="less">

    .template-00 {
        &.langs-list {
            display: flex;
            align-items: center;
            vertical-align: middle;
            font-size: 14px;
            height: 100%;
            width: 100%;
            overflow: hidden;

            .divide-line {
                border-right: 1px solid rgba(0, 0, 0, .65);
            }


            .langs-item {
                display: flex;
                height: 100%;
                align-items: center;
                flex-shrink: 0;

                .langs-link {
                    height: 100%;
                    vertical-align: middle;
                    display: flex;
                    align-items: center;
                    padding: 0 8px;

                    &.last-item {
                        /*padding-right: 0;*/
                    }
                }


                .divide-line {
                    height: 20px;
                }

                .current {
                    color: #2c3e50;
                }
            }
        }

    }


</style>
