import { getStorage, setStorage, StorageType } from "./storage"
import { concatWithSymbol } from "./common"
import _concat from "lodash/concat"

export let opKey
export let utmKey
export let adKey

// 百度通配符
const bdKeys = ["bd_vid"]
// 字节通配符
const byteKeys = ["adid", "creativeid", "creativetype", "clickid"]

const adParams = _concat(bdKeys, byteKeys)

export function analyzeSearchParams(uaId) {
    opKey = concatWithSymbol([uaId, "originOpenId"])
    utmKey = concatWithSymbol([uaId, "utm"])
    adKey = concatWithSymbol([uaId, "ad"])
    const query = window.location.href.split("?")[1]
    if (query) {
        const searchParams = new URLSearchParams(query)
        setUtm(searchParams)
        setOriginOpenId(searchParams)
        setADParams(searchParams)
    }
}

export function setOriginOpenId(searchParams) {
    let originOpenId = ""
    searchParams.forEach((v, k) => {
        if (v && (k === "c_source" || k === "originOpenId")) {
            originOpenId = decodeURIComponent(v)
        }
    })
    if (originOpenId) {
        setStorage(opKey, originOpenId, false, StorageType.session)
    }
}

export function getOriginOpenId() {
    return getStorage(opKey, StorageType.session)
}

export function setUtm(searchParams) {
    const res = {}
    searchParams.forEach((v, k) => {
        if (v && /^utm_|^source$/.test(k)) {
            res[k] = v
        }
    })
    if (Object.keys(res).length) {
        setStorage(utmKey, res, false, StorageType.session)
    }
}

export function setADParams(searchParams) {
    const res = {}
    searchParams.forEach((v, k) => {
        if (v && adParams.indexOf(k) > -1) {
            res[k] = v
        }
    })
    if (Object.keys(res).length) {
        setStorage(adKey, res, false, StorageType.session)
    }
}
export function getADParams() {
    return getStorage(adKey, StorageType.session)
}

export function getUtm() {
    return getStorage(utmKey, StorageType.session)
}

// setTimeout(analyzeSearchParams, 0);
