import { COMMON } from "~@/store/mutations_type"

const WECHATStore = {
    namespaced: true,
    state: {
        WechatInfo: {},
        _siteId: "",
        _pageId: "",
        _lang: "",
        _appId: "",
        _pageTitle: "",
        _UAId: "",
        _tagIds: "",
        _pageData: {},
        _pageCMSData: {},
        _siteData: {}
    },
    getters: {
        WechatInfo: (state) => {
            return state.WechatInfo
        },
        _siteId: state => state._siteId,
        _pageId: state => state._pageId,
        _lang: state => state._lang,
        _appId: state => state._appId,
        _pageTitle: state => state._pageTitle,
        _UAId: state => "UA-" + state._siteId,
        _tagIds: state => state._tagIds,
        _pageData: state => state._pageData,
        _pageCMSData: state => state._pageCMSData,
        _siteData: state => state._siteData
    },
    mutations: {
        [COMMON.SET_WECHAT_INFO](state, data) {
            state.WechatInfo = data
        },
        [COMMON.SET_SITE_ID](state, data) {
            state._siteId = data
        },
        [COMMON.SET_PAGE_ID](state, data) {
            state._pageId = data
        },
        [COMMON.SET_TAG_IDS](state, data) {
            state._tagIds = data
        },
        [COMMON.SET_LANG](state, data) {
            state._lang = data
        },
        [COMMON.SET_APP_ID](state, data) {
            state._appId = data
        },
        [COMMON.SET_PAGE_TITLE](state, data) {
            state._pageTitle = data
        },
        [COMMON.SET_PAGE_DATA](state, data) {
            state._pageData = data
        },
        [COMMON.SET_PAGE_CMS_DATA](state, data) {
            state._pageCMSData = data
        },
        [COMMON.SET_SITE_DATA](state, data) {
            state._siteData = data
        }
    },
    actions: {
        setWechatInfo({ state, commit }, data) {
            commit(COMMON.SET_WECHAT_INFO, data)
        },
        setSiteId({ state, commit }, data) {
            commit(COMMON.SET_SITE_ID, data)
        },
        setPageId({ state, commit }, data) {
            commit(COMMON.SET_PAGE_ID, data)
        },
        setTagIds({ state, commit }, data) {
            commit(COMMON.SET_TAG_IDS, data)
        },
        setLang({ state, commit }, data) {
            commit(COMMON.SET_LANG, data)
        },
        setAppId({ state, commit }, data) {
            commit(COMMON.SET_APP_ID, data)
        },
        setPageTitle({ state, commit }, data) {
            commit(COMMON.SET_PAGE_TITLE, data)
        },
        setPageData({ state, commit }, data) {
            commit(COMMON.SET_PAGE_DATA, data)
        },
        setPageCMSData({ state, commit }, data) {
            commit(COMMON.SET_PAGE_CMS_DATA, data)
        },
        setSiteData({ state, commit }, data) {
            commit(COMMON.SET_SITE_DATA, data)
        }
    }
}

export default WECHATStore
