<template>
    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-04">
        <div class="list-content">
            <div class="left-contents">
                <cube-nav-item
                    v-for="item in cubeNavListData[0]"
                    :key="item.id"
                    :data="data"
                    :item-data="item"
                    class="minisite-cube-nav-item">
                </cube-nav-item>
            </div>
            <div class=" right-contents" v-if="cubeNavListData[1].length">
                <cube-nav-item
                    v-for="item in cubeNavListData[1]"
                    :key="item.id"
                    :data="data"
                    class="minisite-cube-nav-item"
                    :item-data="item"></cube-nav-item>
            </div>
        </div>
    </div>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import CubeNavItem from "@/components/cubeNav/CubeNavItem.vue"

    export default {
        name: "Template07",
        components: { CubeNavItem },
        mixins: [cubeMixin],
        props: {
            data: {}
        },
        data() {
            return {}
        },
        computed: {
            spacing() {
                return this.cubeNavStyle.gap + "px"
            },
            cubeNavListData() {
                const target = [[], []]
                const contents = this.data.contents
                target[0] = contents.filter((v, i) => i % 2 === 0)
                target[1] = contents.filter((v, i) => i % 2 === 1)
                return target
            }
        },
        mounted() {},
        methods: {
            _isEmpty
        }
    }
</script>
<style lang="less" scoped>
    .minisite-cube-nav-04 {
        .list-content {
            display: flex;
            align-items: start;
            > * {
                width: 50%;
                flex: 1;
            }
        }

        .last-not-margin {
            :last-child {
                margin: 0 !important;
            }
        }

        .right-contents {
            padding-top: 48px;
        }

        .minisite-cube-nav-item {
            display: flex;
            flex-grow: 1;
            //overflow: hidden;
        }

        /deep/ .text-container {
            display: flex;
            flex-direction: column;
            //align-items: center;
        }

        /deep/ .main-item-content {
            display: flex;
            //justify-content: center;
            //align-items: center;
            flex-grow: 1;
            overflow: hidden;
            height: 100%;
        }
    }
</style>
