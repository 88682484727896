<template>
    <div class="component-item" :style="getListStyle"
         :class="`${data.type}-item`" v-if="!loading  && hasContents">
        <nav-component :nav-height="navHeight" :data="data" v-if="data.type === 'nav'"
                       :langs="langs"></nav-component>
        <text-component class="text-item-wrapper" :data="data"
                        style="position: relative"
                        v-if="data.type === 'text'"
                        :key="data.id"></text-component>
        <text-component class="text-item-wrapper" :data="data"
                        style="position: relative"
                        v-if="data.type === 'richText'"
                        :key="data.id"></text-component>
        <WechatArticleComponent class="text-item-wrapper" :data="data"
                        v-if="data.type === 'wechatArticle'"
                        :key="data.id"></WechatArticleComponent>
        <button-component class="button-item-wrapper" :data="data"
                          v-if="data.type === 'button'"
                          style="position: relative"
                          :key="data.id"></button-component>
        <divide-component class="divide-item-wrapper" :data="data"
                          v-if="data.type === 'divide'"
                          :key="data.id"></divide-component>
        <image-component class="images-item-wrapper" :data="data"
                         v-if="data.type === 'image'"
                         :key="data.id"></image-component>
        <banner-component class="images-item-wrapper" :data="data"
                          v-if="data.type === 'banner'"
                          :key="data.id"></banner-component>
        <video-component class="images-item-wrapper" :data="data"
                         v-if="data.type === 'video'"
                         :key="data.id"></video-component>
        <audio-component class="images-item-wrapper" :data="data"
                         v-if="data.type === 'audio'"
                         :key="data.id"></audio-component>
        <bg-music-component class="images-item-wrapper" :data="data"
                            v-if="data.type === 'bgMusic'"
                            :key="data.id"></bg-music-component>
        <list-component class="list-item-wrapper" :data="data"
                        v-if="data.type === 'list'"
                        :key="data.id"></list-component>
        <toggle-list-component class="list-item-wrapper" :data="data"
                               v-if="data.type === 'toggleList'"
                               :key="data.id"></toggle-list-component>
        <tag-list-component class="list-item-wrapper" :data="data"
                            v-if="data.type === 'tagList'"
                            :key="data.id"></tag-list-component>
        <float-button-component class="list-item-wrapper" :data="data"
                                v-if="data.type === 'floatButton'"
                                :key="data.id"></float-button-component>
        <tab-component :data="data" v-if="data.type==='tab'"></tab-component>
        <search-component :data="data" v-if="data.type==='search'" v-model="searchWord"></search-component>
        <collection-component v-if="data.type==='collection'"
                              :data="data"></collection-component>
        <cube-nav-component v-if="data.type==='cubeNav'"
                            :data="data"></cube-nav-component>
    </div>
</template>

<script>
    import CommonMixIn from "./common.mixin"

    import NavComponent from "./NavComponent"
    import TextComponent from "./TextComponent"
    import WechatArticleComponent from "./WechatArticleComponent"
    import ButtonComponent from "./ButtonComponent"
    import DivideComponent from "./DivideComponent"
    import ImageComponent from "./ImageComponent"
    import BannerComponent from "./BannerComponent"
    import VideoComponent from "./VideoComponent"
    import AudioComponent from "./AudioComponent"
    import BgMusicComponent from "./BgMusicComponent"
    import ListComponent from "./ListComponent"
    import ToggleListComponent from "./ToggleListComponent"
    import TagListComponent from "./TagListComponent"
    import TabComponent from "./TabComponent"
    import FloatButtonComponent from "./FloatButtonComponent"
    import SearchComponent from "./SearchComponent"
    import CollectionComponent from "./CollectionComponent"
    import CubeNavComponent from "@/components/CubeNavComponent.vue"

    import isEmpty from "lodash/isEmpty"

    export default {
        name: "ComponentItem",
        mixins: [CommonMixIn],
        props: {
            langs: {},
            navHeight: {}
        },
        data() {
            return {
                FLOAT_TYPE_LIST: ["floatButton", "collection", "bgMusic"],
                loading: true,
                searchWord: "",
                componentField: {
                    text: "text",
                    image: "imageSrc",
                    banner: "imageSrc",
                    button: "buttonLink",
                    floatButton: "buttonLink",
                    video: "videoSrc",
                    audio: "audioSrc",
                    bgMusic: "audioSrc"
                }
            }
        },
        components: {
            NavComponent,
            TextComponent,
            ButtonComponent,
            DivideComponent,
            ImageComponent,
            BannerComponent,
            VideoComponent,
            AudioComponent,
            BgMusicComponent,
            ListComponent,
            ToggleListComponent,
            TagListComponent,
            TabComponent,
            FloatButtonComponent,
            SearchComponent,
            CollectionComponent,
            CubeNavComponent,
            WechatArticleComponent
        },
        computed: {
            getListStyle() {
                // 浮动组件
                if (this.FLOAT_TYPE_LIST.indexOf(this.data.type) > -1) {
                    return {
                        position: "fixed",
                        width: this.data.style.floatButtonStyle.width + "px",
                        height: this.data.style.floatButtonStyle.height + "px",
                        top: this.data.style.floatButtonStyle.position.top + "px",
                        left: this.data.style.floatButtonStyle.position.left + "px",
                        right: this.data.style.floatButtonStyle.position.right + "px",
                        bottom: this.data.style.floatButtonStyle.position.bottom + "px",
                        zIndex: 999
                    }
                } else if (this.data.style && this.data.style.listStyle) {
                    return Object.assign(
                        {},
                        this.data.style.listStyle,
                        { borderRadius: this.data.style.listStyle.borderRadius + "px" },
                        this.getBackground(this.data.style.listStyle.background),
                        this.getPadding(this.data.style.listStyle.padding),
                        this.getMargin(this.data.style.listStyle.margin)
                    )
                }
                return {}
            },
            hasContents() {
                let noContentComponent = ["search", "collection"]
                if (noContentComponent.indexOf(this.data.type) > -1) {
                    return true
                } else {
                    return !isEmpty(this.data.contents)
                }
            }

        },
        async mounted() {
            let _this = this
            let _inTemplatePage = this._siteData.type === "template" &&
                this.data.dataSource && this.data.dataSource.fieldSource === "field"
            let bindCMS = this.data.dataSource && this.data.dataSource.type === "cms"
            if (_inTemplatePage && _this._pageCMSData && _this._pageCMSData.detail) {
                this.loading = true
                let _content = []
                let fieldSettings = _this.data.dataSource.cms.fields
                let _dataSrc = this._pageCMSData.detail[fieldSettings[_this.componentField[_this.data.type]]] || ""
                switch (this.data.type) {
                    case "image":
                    case "banner":
                        _content = _dataSrc?.split(";").map((_) => {
                            return {
                                // id: generateUUID(12),
                                image: {
                                    show: true,
                                    imageSrc: _,
                                    imageTitle: "图片标题",
                                    imageAlt: "图片说明",
                                    clickAction: {
                                        type: "basic",
                                        remark: "",
                                        content: {
                                            type: "",
                                            content: [
                                                {
                                                    title: "",
                                                    url: ""
                                                }
                                            ]
                                        }
                                    }
                                }
                            }
                        })
                        this.data.contents = _content
                        break
                    case "text":
                        this.data.contents = [{
                            // id: generateUUID(12),
                            text: {
                                contents: _dataSrc
                            }
                        }]
                        break
                    case "button":
                    case "floatButton":
                        _content = _dataSrc?.split(";").map((_) => {
                            return {
                                // id: generateUUID(8),
                                button: {
                                    show: true,
                                    buttonText: "点击查看"
                                },
                                clickAction: {
                                    type: "basic",
                                    remark: "",
                                    content: {
                                        type: "",
                                        content: [
                                            {
                                                title: "",
                                                url: _
                                            }
                                        ]
                                    }
                                }
                            }
                        })
                        this.data.contents = _content
                        break
                    case "video":
                        _content = _dataSrc?.split(";").map((_) => {
                            return {
                                // id: generateUUID(12),
                                video: {
                                    show: true,
                                    title: "",
                                    videoSrc: _,
                                    poster: ""
                                }
                            }
                        })
                        this.data.contents = _content
                        break
                    case "audio":
                    case "bgMusic":
                        _content = _dataSrc?.split(";").map((_) => {
                            return {
                                // id: generateUUID(12),
                                audio: {
                                    show: true,
                                    title: "",
                                    audioSrc: _
                                }
                            }
                        })
                        this.data.contents = _content
                        break
                    default:
                        break
                }
                this.loading = false
            } else {
                this.loading = false
            }
        }
    }
</script>

<style lang="less" scoped>
    .video-item {
        overflow: hidden;
    }
</style>
<style lang="less">
    .wechat-article {
        * {
            max-width: 100% !important;
        }
    }
</style>
