import Vue from 'vue'
import Vuex from 'vuex'
import collection from './collection'
import common from './common'

Vue.use(Vuex)

export default new Vuex.Store({
    // namespaced: true,
    modules: {
        collection,
        common
    },
    state: {},
    mutations: {},
    actions: {}
})
