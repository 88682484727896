<template>
    <div class="minisite-toggle-list toggle-list-00" v-if="showedData" :style="getToggleListStyle">
        <div class="list-search"
             v-show="getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              v-model="searchWord"
                              :search-style="showedData.style.searchStyle"
            ></search-component>
        </div>
        <template v-for="(item,itemIndex) in showedData.contents">
            <template v-if="item.matched">
                <div class="minisite-toggle-list-item"
                     :key="item.id" :style="getCommonStyle"
                     :class="{'open': item.toggle,closed:! item.toggle}">
                    <div class="list-header"
                         @click="item.toggle = !item.toggle"
                         :style="item.toggle?getActiveHeaderStyle:getHeaderStyle">
                        <div class="image-container" v-show="getShowedComponent.image"
                             :style="{width:data.style.imageStyle.width+'%'}">
                            <basic-image class="list-header-image" :data="item.image"
                                         :image-style="data.style.imageStyle"
                            ></basic-image>
                        </div>
                        <basic-text class="list-header-title" :data="item.title.content"
                                    v-show="getShowedComponent.title"
                                    :font-style="
                            Object.assign(
                                {},
                                showedData.style.titleFontStyle,
                                {
                                    color: (!!item.toggle)?(showedData.style.headerStyle.active? showedData.style.headerStyle.active.color:(showedData.style.titleFontStyle && showedData.style.titleFontStyle.color)):showedData.style.titleFontStyle.color || `var(--c-site-primary-color-)`
                                }
                            )
                        "></basic-text>
                        <div class="right-icon list-header-icon"
                             v-show="getShowedComponent.icon">
                            <basic-image :data="item.icon"></basic-image>
                        </div>
                    </div>
                    <inner-list :data="item" class="list-body" :data-source="data.dataSource"
                                :search-word="searchWord"></inner-list>
<!--                    <inner-list class="list-body" :data="item"></inner-list>-->
                </div>
                <div class="divide-line toggle-list-divide-line" :style="getDivideStyle"
                     :key="`${item.id}_divide`"
                     v-show="getShowedComponent.divideLine && itemIndex<data.contents.length-1">
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import toggleList from "./toggleList.mixin"

    export default {
        name: "Template-00",
        mixins: [toggleList],
        props: {
            data: {}
        },
        computed: {
            getToggleListStyle() {
                return {}
            }
        },
        mounted() {

        }
    }
</script>

<style lang="less">
    .minisite-toggle-list {

        .image_resized {
            width: initial;
            display: flex;
            margin: 0;

            img {
                width: 100%;
                height: initial;
            }
        }

        figure {

            &.image {
                margin: 0;
                height: fit-content;
                display: flex;
                align-items: center;

                img {
                    margin: 0 auto;
                }
            }
        }

        .minisite-toggle-list-item {

            .editor-item-content {
                line-height: 2;
            }

            .list-header {
                display: flex;
                align-items: center;
                cursor: pointer;

                .image-container {
                    margin-right: 10px;
                }

                .list-header-title {
                    flex-grow: 1;
                    vertical-align: middle;
                    line-height: 1.5;
                }

                .list-header-icon {
                    transition: all .3s ease;
                    flex-shrink: 0;
                    max-width: 30px;
                }
            }

            .list-body {
                max-height: 0;
                overflow: hidden;
                transition: height .3s ease;
                padding: 0;
                margin: 0;
            }

            &.closed {
                .list-body {
                    margin: 0 !important;
                    padding: 0 !important;
                    border: none !important;
                }
            }

            &.open {
                .list-body {
                    max-height: inherit;
                }

                .list-header-icon {
                    transform: rotate(90deg);
                }
            }
        }

    }

</style>
