<template>
    <div class="minisite-tab-item tab-01" :style="getTabStyle">
        <div class="tab-item" v-for="tabItem in data.content" :key="tabItem.id" :style="getTabComponentStyle">
            <div class="tab-icon-container">
                <img class="tab-icon" :style="getTabIconStyle" :src="tabItem.iconNormal" alt="">
            </div>
            <div class="tab-title">
                <basic-text :data="tabItem.text"
                            :font-style="data.style.normalStyle.textStyle"
                ></basic-text>
            </div>
        </div>
    </div>
</template>

<script>
    import tabMixin from './floatButton.mixin'

    export default {
        name: "Template-00",
        mixins: [tabMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .tab-01 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        position: relative;

        .tab-item {
            height: 100%;
            flex: 1;
            flex-shrink: 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            min-width: 30%;
            border-left: none!important;
            border-top: none!important;
        }

        .tab-title {
            flex-shrink: 0;
        }

        .tab-icon-container {
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .tab-icon {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

    }

</style>
