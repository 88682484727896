<template>
    <div class="minisite-banner-list" :style="getListStyle" @touchmove.stop>
        <template-00 v-if="data.style.templateId === '00'" :data="data"></template-00>
    </div>
</template>
<script>
    import CommonMixIn from './common.mixin'

    import BannerTemplate from './banner'

    export default {
        name: "BannerComponent",
        mixins: [CommonMixIn],
        components: {
            'template-00': BannerTemplate.Template00
        },
        computed: {
            getListStyle() {
                if (this.data.style && this.data.style.background) {
                    return Object.assign(
                        {},
                        this.getBackground(this.data.style.background),
                        this.getPadding(this.data.style.background.padding),
                        this.getMargin(this.data.style.background.margin)
                    )
                }
                return {}
            }
        }
    }
</script>

<style lang="less" scoped>

</style>
