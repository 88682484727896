function proxyApi(module, target, data, freeLogin) {
    return new Promise((resolve, reject) => {
        // const module = "site";
        if (window.cw) {
            window.cw(
                "ajax",
                "proxy",
                data => {
                    if (data.result && data.result.error) {
                        reject(data.result.error);
                    } else {
                        resolve(data.result);
                    }
                },
                err => reject(err),
                {
                    module,
                    target,
                    data,
                    freeLogin: target === 'findLimit' ? 1 : 0
                }
            );
        } else {
            resolve({ code: 200 });
        }
    });
}
export const getAccess = async uaId => {
    return await proxyApi('site',"access", {
        type: "site",
        uaId: uaId || window.ca("get", "tid")
    });
};

export const findLimit = async uaId => {
    return await proxyApi('site', 'findLimit', {
        type: 'site',
        uaId: uaId || window.ca('get', 'tid')
    })
}
