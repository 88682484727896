import { BasicText, BasicImage, BasicButton, BasicButtonImageCollection } from "./../basic/index"
import commonMixIn from "./../common.mixin"
import Collection from "../collection/Collection"
import CollectionMixIn from "~@/components/functions/collection.mixin"
import SearchComponent from "../SearchComponent"
import _cloneDeep from "lodash/cloneDeep"
import { SearchNormalList } from "@/components/search/search"
import { mapGetters } from "vuex"
import { getDataRows } from "@custouch/proxy-use"

export default {
    mixins: [commonMixIn, CollectionMixIn],
    props: {
        type: {},
        dataSource: {},
        hierId: {}
    },
    data() {
        return {
            listStyle: {
                common: {}
            },
            // showedData: null,
            listData: [],
            searchWord: ""
        }
    },
    components: {
        BasicText,
        BasicImage,
        BasicButton,
        Collection,
        SearchComponent,
        BasicButtonImageCollection
    },
    methods: {
        search(word) {
            this.getListData()
            //     if (!word || word.length < 1) {
            //         this.showedData = _cloneDeep(this.data)
            //     } else {
            //         let _res = SearchNormalList(this.data, word)
            //         this.showedData = _res
            //     }
        },
        getNormalData() {
            this.listData = this.data.contents.map((_) => {
                let _item = {}
                _item.id = _.id
                if (_.title.show) {
                    _item.title = _.title.content
                }
                if (_.desc.show) {
                    _item.desc = _.desc.content
                }
                if (_.button.show) {
                    _item.button = _.button.buttonText
                }
                if (_.icon.show) {
                    _item.icon = {
                        imageSrc: _.icon.imageSrc
                    }
                }
                if (_.image.show) {
                    _item.image = {
                        imageSrc: _.image.imageSrc,
                        imageAlt: _.image.imageAlt,
                        imageTitle: _.image.imageTitle
                    }
                }
                if (_.count) {
                    _item.count = _.count
                }
                if (_.createTime) {
                    _item.createTime = _.createTime
                }
                if (_.formatCreateTime) {
                    _item.formatCreateTime = _.formatCreateTime
                }
                _item.clickAction = _.clickAction
                return _item
            })
        },
        async getCMSData() {
            try {
                let _this = this
                let cmsConfigs = this.dataSource ? this.dataSource.cms : this.data.dataSource.cms
                let fieldConfigs = this.data.dataSource.cms.fields
                let { data } = await getDataRows({
                    dataHierarchyId: cmsConfigs.hierarchyId || this.hierId,
                    dataTemplateId: cmsConfigs.templateId,
                    index: 1,
                    size: 20,
                    searchKey: this.parentSearchWord || this.searchWord
                })
                let _target = []
                let _childPage = this._siteData.childPages?.filter((_) => {
                    return _.parentComponentId === this.data.id
                })
                data.forEach((item) => {
                    let _buttonText = _this.data.dataSource.cms.buttonText || (_this.data.contents.length > 0 ? _this.data.contents[0].button.buttonText : "点击查看")
                    _target.push({
                        id: item.id,
                        title: item.detail[fieldConfigs.title] || '',
                        desc: item.detail[fieldConfigs.desc] || '',
                        image: {
                            imageSrc: item.detail[fieldConfigs.image]?.split(";")[0]
                        },
                        link: item.detail[fieldConfigs.link] || '',
                        icon: {},
                        button: _buttonText,
                        clickAction: this.data.dataSource.cms.detailType === "link" ? {
                            type: "basic",
                            remark: item.detail[fieldConfigs.title] || '',
                            content: {
                                type: "",
                                content: [
                                    {
                                        title: item.detail[fieldConfigs.title] || '',
                                        url: item.detail[fieldConfigs.link] || ''
                                    }
                                ]
                            }
                        } : {
                            type: "detailLink",
                            remark: "",
                            content: {
                                type: "",
                                content: [
                                    {
                                        title: item.detail[fieldConfigs.title] || '',
                                        dataRowId: item.id,
                                        url : `${this.currentInfo.protocol}//${this.currentInfo.host}/${this._siteId}/gmp/${_childPage[0].pageId}/${item.id}/${this._lang}`

                                    }
                                ]
                            }
                        }
                    })
                })
                this.listData = _target
            } catch (e) {
                console.log(e)
            }
        },
        getListData() {
            let _inCMS = this.dataSource && this.dataSource.type === "cms"
            let _dataSource = this.data.dataSource ? this.data.dataSource.type : "default"
            if (_dataSource === "cms" || _inCMS) {
                this.getCMSData()
            } else {
                this.getNormalData()
            }
        }
    },
    created() {
        // if (this.type !== "inner") {
        //     this.data.contents.forEach((content) => {
        //         content.matched = true
        //     })
        // }
        // this.$set(this, "showedData", _cloneDeep(this.data))
    },
    mounted() {
        this.listStyle = this.data.style
        this.getListData()
    },
    computed: {
        ...mapGetters("common", ["WechatInfo", "_siteId", "_pageId", "_lang", "_appId", "_pageTitle", "_UAId"]),
        filterData() {
            let _searchWord = (this.parentSearchWord || this.searchWord || "").toLowerCase()
            if (_searchWord) {
                return this.listData.filter((_) => {
                    return _.title?.toLowerCase().indexOf(_searchWord) > -1
                })
            }
            return this.listData
        },
        isLogin() {
            return this.WechatInfo && this.WechatInfo.openid
        },
        currentInfo() {
            let host = window.location.host
            let protocol = window.location.protocol
            let _pathList = window.location.pathname.split("/")
            return {
                host: host,
                protocol: protocol,
                siteId: _pathList[1],
                pageId: _pathList[2],
                langId: this._lang
            }
        },
        getImageStyle() {
            return Object.assign({},
                this.getBackground(this.listStyle.imageStyle.background),
                this.getPadding(this.listStyle.imageStyle.padding)
            )
        },
        getDivideStyle() {
            if (this.listStyle.divideLineStyle) {
                return Object.assign({}, {
                    "width": this.listStyle.divideLineStyle.width + "%",
                    "border-bottom-style": this.listStyle.divideLineStyle.style,
                    "border-bottom-width": this.listStyle.divideLineStyle.height + "px",
                    "border-bottom-color": this.listStyle.divideLineStyle.color
                }, this.getMargin(this.listStyle.divideLineStyle.margin))
            }
        },
        getCommonStyle() {
            if (this.listStyle.common.padding) {
                return Object.assign(
                    {},
                    this.listStyle.common,
                    { borderRadius: this.listStyle.common.borderRadius + "px" },
                    this.getBackground(this.listStyle.common.background),
                    this.getPadding(this.listStyle.common.padding),
                    this.getMargin(this.listStyle.common.margin)
                )
            }
        },
        getTextContentStyle() {
            let target = {}
            if (this.listStyle.common.alignItems === "space-between") {
                target = {
                    // height: '100%',
                    justifyContent: "space-between"
                }
            }
            return target
        },
        getShowedComponent() {
            if (this.listStyle.imageStyle) {
                return {
                    image: this.listStyle.imageStyle.show,
                    title: this.listStyle.titleFontStyle.show,
                    desc: this.listStyle.descFontStyle && this.listStyle.descFontStyle.show,
                    button: this.listStyle.buttonStyle && this.listStyle.buttonStyle.show,
                    icon: this.listStyle.iconStyle && this.listStyle.iconStyle.show,
                    divideLine: this.listStyle.divideLineStyle.show,
                    collection: this.listStyle.collectionStyle && this.listStyle.collectionStyle.show,
                    count: this.listStyle.countStyle && this.listStyle.countStyle.show,
                    createTime: this.listStyle.createTimeStyle && this.listStyle.createTimeStyle.show,
                    search: this.data.style.searchStyle ? this.data.style.searchStyle.show : false
                }
            }
        }
    },
    watch: {
        // data: {
        //     deep: true,
        //     handler() {
        //         this.$nextTick(() => {
        //             this.getListData()
        //         })
        //     }
        // },
        hierId(){

                this.$nextTick(() => {
                    this.getListData()
                })
        }
// {
//             deep: true,
//             handler() {
//                 this.$nextTick(() => {
//                     this.getListData()
//                 })
//             }
//         }
    }
}
