import { render, staticRenderFns } from "./ComponentItem.vue?vue&type=template&id=57840464&scoped=true&"
import script from "./ComponentItem.vue?vue&type=script&lang=js&"
export * from "./ComponentItem.vue?vue&type=script&lang=js&"
import style0 from "./ComponentItem.vue?vue&type=style&index=0&id=57840464&lang=less&scoped=true&"
import style1 from "./ComponentItem.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57840464",
  null
  
)

export default component.exports