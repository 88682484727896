<template>
    <div class="minisite-list-01">
        <div class="minisite-list-item" v-for="item in showedData.contents" :key="item.id"
             :style="{width:listStyle.common.width+'%'}">
            <!--            <click-action :data="item.image.clickAction">-->
            <click-action class="main-item-content" :style="getCommonStyle"
                          :action-remark="item.button.buttonText"
                          :data="item.clickAction" :key="item.id" ref="listTemplate1blockItem">
                <template v-if="listStyle.imageStyle">
                    <div class="left-container" v-if="getShowedComponent.image"
                         :style="[getImageStyle,{height:blockHeight}]">
                        <div class="image-container"
                             :style="{width:listStyle.imageStyle.width+'%'}">
                            <basic-image :data="item.image"
                                         :image-style="listStyle.imageStyle"
                                         :image-title-style="listStyle.imageTitleFontStyle"
                            ></basic-image>
                        </div>
                    </div>
                    <div class="right-container">
                        <div class="title-container" v-if="getShowedComponent.title">
                            <basic-text :data="item.title.content"
                                        :font-style="listStyle.titleFontStyle"
                            ></basic-text>
                        </div>
                        <div class="desc-container" v-if="getShowedComponent.desc">
                            <basic-text :data="item.desc.content"
                                        :font-style="listStyle.descFontStyle"
                            ></basic-text>
                        </div>
                        <div class="button-container" v-if="getShowedComponent.button">
                            <basic-button :data="item.button.buttonText"
                                          :button-style="listStyle.buttonStyle"
                            ></basic-button>
                        </div>
                    </div>
                    <div class="right-icon" v-if="getShowedComponent.icon">
                        <basic-image :data="item.icon"></basic-image>
                    </div>
                </template>
            </click-action>
            <template v-if="listStyle.imageStyle">
                <div class="divide-line" v-if="getShowedComponent.divideLine"
                     :style="getDivideStyle">
                </div>
            </template>
            <!--            </click-action>-->
        </div>
        <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
        <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
        <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
        <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
    </div>
</template>

<script>
    import listMixin from './button.mixin'

    export default {
        name: "ListTemplate01",
        mixins: [listMixin],
        props: {
            data: {}
        },
        data() {
            return {
                blockHeight: 'initial'
            }
        },
        computed: {
            // blockHeight() {
            //     if (this.$refs.listTemplate1blockItem) {
            //         console.log(this.$refs.listTemplate1blockItem)
            //         this.$nextTick(() => {
            //             let targetWidth = this.$refs.listTemplate1blockItem[0].$el.offsetWidth
            //             // this.blockHeight = (targetWidth - this.listStyle.imageStyle.padding.top - this.listStyle.imageStyle.padding.bottom) + 'px'
            //             return (targetWidth - this.listStyle.imageStyle.padding.top - this.listStyle.imageStyle.padding.bottom) + 'px'
            //         })
            //     }
            //     return 'initial'
            // }
        },
        mounted() {
            if (this.$refs.listTemplate1blockItem) {
                this.$nextTick(() => {
                    let targetWidth = this.$refs.listTemplate1blockItem[0].$el.offsetWidth
                    this.blockHeight = (targetWidth - this.listStyle.imageStyle.padding.top - this.listStyle.imageStyle.padding.bottom) + 'px'
                })
            }
        },
        watch: {
            listStyle: {
                deep: true,
                handler(val) {
                    if (this.$refs.listTemplate1blockItem) {
                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                let targetWidth = this.$refs.listTemplate1blockItem[0].$el.offsetWidth
                                this.blockHeight = (targetWidth - this.listStyle.imageStyle.padding.top - this.listStyle.imageStyle.padding.bottom) + 'px'
                            })
                        })
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .minisite-list-01 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .minisite-list-item {
            /*margin-bottom: 20px;*/
        }

        .main-item-content {
            display: block;
            /*flex-direction: column;*/
            /*justify-content: space-between;*/
        }

        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
            /*width: 100%;*/
            display: flex;
            justify-content: center;
        }

        .image-container {
            display: flex;
            flex-direction: column;
        }

        .right-container {
            flex-grow: 1;
            vertical-align: top;

            .button-container {
                margin-top: 10px;
            }
        }

        .right-icon {
            flex-shrink: 0;
        }
    }

</style>

<style lang="less">
    .minisite-list-01 {
        .minisite-image-container {
            height: 100%;
        }
    }

</style>
