import Template00 from "./Template-00"
import Template01 from "./Template-01"
import Template02 from "./Template-02"
import Template03 from "./Template-03"
import Template04 from "./Template-04"

export default {
    Template00,
    Template01,
    Template02,
    Template03,
    Template04
}
