import { mapActions, mapGetters } from "vuex"

export default {
    props: {
        data: {}
    },
    components: {},
    methods: {
        ...mapActions(
            "common",
            [
                "setWechatInfo",
                "setSiteId",
                "setPageId",
                "setTagIds",
                "setLang",
                "setAppId",
                "setPageTitle",
                "setPageData",
                "setPageCMSData",
                "setSiteData"
            ]
        ),
        getBackground(data) {
            if (data) {
                return Object.assign({}, data, {
                    backgroundRepeat: data.backgroundRepeatX + " " + data.backgroundRepeatY
                })
            }
        },
        getPadding(data) {
            if (data) {
                return {
                    paddingTop: data.top + "px",
                    paddingBottom: data.bottom + "px",
                    paddingLeft: data.left + "px",
                    paddingRight: data.right + "px"
                }
            }
        },
        getMargin(data) {
            if (data) {
                return {
                    marginTop: data.top + "px",
                    marginBottom: data.bottom + "px",
                    marginLeft: data.left + "px",
                    marginRight: data.right + "px"
                }
            }
        }
    },
    computed: {
        ...mapGetters("common",
            [
                "WechatInfo", "_siteId", "_pageId", "_lang", "_appId", "_pageTitle", "_UAId", "_tagIds", "_pageData", "_pageCMSData", "_siteData"])
    }
}
