import { isEmpty } from "@/utils/object"

export default {
    computed: {
        pageTab() {
            let tab
            let tabStyle = {}
            // 【【系统微站】-【系统后台】-顶部导航组件与底部标签栏组件优化】
            // https://www.tapd.cn/49024541/prong/stories/view/1149024541001025371
            let _tabV1 = this.targetData.tab
            let _tabV2 = {}
            let dataPath
            let _siteTab = this.structure.siteInfo.siteData.settings.tab
                ? this.structure.siteInfo.siteData.settings.tab[this._lang]
                : []
            let version = !isEmpty(_tabV1) ? "v1" : "v2"

            if (!isEmpty(_siteTab)) {
                _siteTab.forEach((item, index) => {
                    if (item && item.pages.indexOf(this._pageId) > -1) {
                        _tabV2 = item
                    }
                })
            }
            tab = !isEmpty(_tabV1) ? _tabV1 : _tabV2
            let hasTab = !isEmpty(tab)
            let tabSplit = hasTab ? tab.style.tabStyle.split : false
            let tabFloat = hasTab ? tab.style.tabStyle.float : false
            if (hasTab) {
                tabStyle = tabFloat
                    ? {
                        marginBottom: tabSplit ? parseInt(this.tabHeight) + "px" : 0
                    }
                    : {
                        marginBottom: tabSplit ? 0 : -parseInt(this.tabHeight) + "px"
                    }
            }
            return {
                tab,
                version,
                hasTab,
                tabSplit,
                tabFloat,
                tabStyle,
            }
        },
        pageNav() {
            let nav = this.structure.siteInfo.siteData.settings.nav

            let navStyle = {}
            let hasNav = !isEmpty(nav) && !this.structure.settings.disableNav
            let navSplit = hasNav ? nav.style.navStyle.split : false
            let navFloat = hasNav ? nav.style.navStyle.float : false
            if (hasNav) {
                navStyle = navFloat
                    ? {
                        marginTop: navSplit ? parseInt(this.navHeight) + "px" : 0
                    }
                    : {
                        marginTop: navSplit ? 0 : -parseInt(this.navHeight) + "px"
                    }
            }
            return {
                nav,
                hasNav,
                navSplit,
                navFloat,
                navStyle
            }
        }
    }
}
