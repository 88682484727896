<template>
    <div class="minisite-tab">
        <template-00 v-if="data.style.templateId === '00'" :data="data"></template-00>
        <template-01 v-if="data.style.templateId === '01'" :data="data"></template-01>
    </div>
</template>
<script>
    import TabTemplate from './tab'

    export default {
        name: "TabComponent",
        props: {
            data: {}
        },
        components: {
            'template-00': TabTemplate.Template00,
            'template-01': TabTemplate.Template01,

        }
    }
</script>

<style lang="less" scoped>

</style>
