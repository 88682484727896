<template>
    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-00">
        <div class="list-wrapper" :key="cubeNavStyle.colums + data.contents.length">
            <div v-for="(arr, i) in cubeNavListData" :key="i" :style="listStyle" class="list-content">
                <cube-nav-item
                    v-for="(item, itemIndex) in arr"
                    :key="`${item.id}_${itemIndex}`"
                    ref="listItem"
                    :style="{
                        ...listItemStyle,
                        ...(isLastRow(arr.length) ? { maxWidth: cubeNavItemWidth + 'px' } : {})
                    }"
                    :data="data"
                    :item-data="item"
                    class="minisite-cube-nav-item">
                </cube-nav-item>
            </div>
        </div>
    </div>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import CubeNavItem from "@/components/cubeNav/CubeNavItem.vue"

    export default {
        name: "Template00",
        mixins: [cubeMixin],
        components: { CubeNavItem },
        props: {
            data: {}
        },
        data() {
            return {
                maxWidth: "auto",
                // 设备宽度
                deviceWidth: 375
            }
        },
        computed: {
            listItemStyle() {
                return {
                    marginRight: this.cubeNavStyle.gap + "px"
                }
            },
            listStyle() {
                return {
                    marginBottom: this.cubeNavStyle.gap + "px",
                    justifyContent: this.data.style.listStyle.justifyContent
                }
            },
            cubeNavListData() {
                const target = []
                const contents = this.$props.data.contents
                const columns = +this.cubeNavStyle.columns

                for (let i = 0; i < contents.length; i += columns) {
                    target.push(contents.slice(i, i + columns))
                }
                return target
            },
            // 计算模块宽度
            cubeNavItemWidth() {
                const { margin, padding: listPadding } = this.data.style.listStyle
                const { gap, columns, padding: itemPadding } = this.data.style.cubeNavStyle
                const count = this.data.contents.length
                const flag = Math.min(+columns, count)
                return (
                    (this.deviceWidth -
                        +gap * (flag - 1) -
                        listPadding.left -
                        listPadding.right -
                        margin.left -
                        margin.right) /
                    flag
                )
            }
        },
        mounted() {
            this.deviceWidth = document.querySelector('#app').offsetWidth
            window.onresize = () => this.deviceWidth = document.querySelector('#app').offsetWidth
        },
        methods: {
            _isEmpty,
            // 是否是最后一行模块，且模块数量不够填满一行
            isLastRow(length) {
                return this.data.contents.length > this.cubeNavStyle.columns && length < this.cubeNavStyle.columns
            }
        }
    }
</script>

<style lang="less" scoped>
    .minisite-cube-nav-00 {
        .list-wrapper {
            > :last-child {
                margin-bottom: 0 !important;
            }

            .list-content {
                display: flex;

                > :last-child {
                    margin-right: 0 !important;
                }
            }
        }

        .minisite-cube-nav-item {
            display: flex;
            width: 0;
            //flex-grow: 0;
            //flex-shrink: 0;
            flex-grow: 1;
            //overflow: hidden;
        }

        .main-item-content {
            display: flex;
            //justify-content: space-between;
            flex-grow: 1;
            overflow: hidden;
        }
    }
</style>
