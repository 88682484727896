import { BasicText, BasicImage, BasicButton } from './../basic/index'
import commonMixIn from './../common.mixin'
import { isWeixin } from "@/utils/userAgent";

export default {
    mixins: [commonMixIn],
    components: {
        BasicText
    },
    data() {
        return {
            isPlaying: false
        }
    },
    computed: {
        getBgMusicStyle() {
            return Object.assign({},
                this.getBackground(this.data.style.floatButtonStyle.background),
                {
                    'borderRadius': this.data.style.floatButtonStyle.border.radius + 'px',
                    'borderStyle': this.data.style.floatButtonStyle.border.style,
                    'borderWidth': this.data.style.floatButtonStyle.border.height + 'px',
                    'borderColor': this.data.style.floatButtonStyle.border.color
                }
            )
        },
        getBgMusicIconStyle() {
            return {
                width: this.data.style.iconStyle.width + 'px',
                height: this.data.style.iconStyle.height + 'px',
            }
        }
    },
    mounted() {
        this.autoPlay()
    },
    methods: {
        autoPlay() {
            if (isWeixin && window.cw) {
                let _this = this
                window.cw('afterWxReady', () => {
                    _this.$refs.audio.play()
                    _this.isPlaying = true
                })
            }
        },
        toggleStatus() {
            this.isPlaying = !this.isPlaying
            if (this.isPlaying) {
                this.$refs.audio.play()
            } else {
                this.$refs.audio.pause()
            }
        }
    }
}
