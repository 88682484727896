<template>
    <div class="minisite-tab-item tab-01" :style="getTabStyle">
        <template v-for="(tabItem,tabIndex) in data.contents">
            <click-action class="tab-item" :style="[getTabComponentStyle,getTab01DivideLineStyle]"
                          :data="tabItem.clickAction"
                          :key="tabItem.id" :class="{
                              'first-row': tabIndex < columnsCount,
                              'last-row':  Math.ceil((tabIndex + 1)/columnsCount) === rowsCount,
                              'last-column':  tabIndex === data.contents.length-1 ||(tabIndex + 1)%columnsCount === 0,
                          }">
                <!--                <div>-->
                <div class="tab-icon-container">
                    <img class="tab-icon" :style="getTabIconStyle" :src="tabItem.iconActive"
                         alt="" v-if="_pageId && tabItem.clickAction.target===_pageId">
                    <img class="tab-icon" :style="getTabIconStyle" :src="tabItem.iconNormal"
                         alt="" v-else>
                </div>
                <div class="tab-title">
                    <basic-text :data="tabItem.text"
                                :font-style="data.style.normalStyle.textStyle"
                    ></basic-text>
                </div>
                <!--                </div>-->
            </click-action>
        </template>
    </div>
</template>

<script>
    import tabMixin from './tab.mixin'

    export default {
        name: "Template-00",
        mixins: [tabMixin],
        props: {
            data: {}
        },
        computed: {
            getTab01DivideLineStyle() {
                if (this.data.style.divideLineStyle.show) {
                    return Object.assign({}, {
                        'border-style': this.data.style.divideLineStyle.style,
                        'border-width': this.data.style.divideLineStyle.width + 'px',
                        'border-color': this.data.style.divideLineStyle.color
                    })
                }
                return {}
            },
        }
    }
</script>

<style lang="less">

    .tab-01 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        position: relative;

        .tab-item {
            height: 100%;
            flex-grow: 1;
            flex-shrink: 1;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            overflow: hidden;
        }

        .tab-title {
            flex-shrink: 0;
            flex-grow: 2;
        }

        .tab-icon-container {
            flex-grow: 3;
            flex-shrink: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            width: 100%;

            .tab-icon {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .tab-item {
            border-left: none !important;
            border-bottom: none !important;

        }

        .first-row {
            border-top: none !important;
        }

        .last-column {
            border-right: none !important;

        }

        .last-row {

        }

    }

</style>
