export const COLLECTION = {
    SET_COLLECTION: 'setCollection',
    ADD_ITEM: 'addCollectionItem',
    DELETE_ITEM: 'deleteCollectionItem',
}
export const COMMON = {
    SET_WECHAT_INFO: 'setWechatInfo',
    SET_SITE_ID: 'setSiteId',
    SET_PAGE_ID: 'setPageId',
    SET_TAG_IDS: 'setTagIds',
    SET_LANG: 'setLang',
    SET_APP_ID: 'setAppId',
    SET_PAGE_TITLE: 'setPageTitle',
    SET_PAGE_DATA: 'setPageData',
    SET_PAGE_CMS_DATA: 'setPageCMSData',
    SET_SITE_DATA: 'setSiteData'
}
