<template>
    <div class="minisite-search-item search-00" :style="getSearchContainerStyle">
        <input class="search-input"
               type="text"
               v-model="searchWord"
               :placeholder="data.placeholder"
               @keyup.enter="search" :style="getSearchStyle">
        <div class="search-icon">
            <basic-image class="list-header-image" :data="data"></basic-image>
        </div>
    </div>
</template>

<script>
    import searchMixin from './search.mixin'

    export default {
        name: "Template-00",
        mixins: [searchMixin],
        components: {},
        props: {
            data: {}
        },
        computed: {},
        watch: {}
    }
</script>

<style lang="less" scoped>

    .search-00 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        position: relative;

        .search-input {
            width: 100%;
            border-width: 1px;
            border-style: solid;
            outline: none;
            height: 28px;
            line-height: 28px;
            padding: 0 30px 0 12px;
        }

        .search-icon {
            position: absolute;
            top: 4px;
            right: 10px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

</style>
