<template>
    <div class="minisite-list-03" :class="'template-' + listStyle.templateId"
         v-if="listStyle.imageStyle">
        <div class="list-search"
             v-show="getShowedComponent && getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              v-model="searchWord"
                              :search-style="listStyle.searchStyle"
            ></search-component>
        </div>
        <div class="list-content">
            <div class="minisite-list-item" v-for="(item,itemIndex) in filterData" :key="item.id"
                 :style="{width:listStyle.common.width+'%'}">
                <click-action class="main-item-content" :style="getCommonStyle"
                              :data="item.clickAction" :key="item.id">
                    <div class="image-container" v-show="getShowedComponent.image"
                         :style="{width:listStyle.imageStyle.width+'%'}">
                        <basic-image :data="item.image"
                                     :image-style="listStyle.imageStyle"
                                     :image-title-style="listStyle.imageTitleFontStyle"
                        ></basic-image>
                    </div>
                    <div class="title-container" v-show="getShowedComponent.title">
                        <basic-text :data="item.title"
                                    :font-style="listStyle.titleFontStyle"
                        ></basic-text>
                    </div>
                    <div class="desc-container" v-show="getShowedComponent.desc">
                        <basic-text :data="item.desc"
                                    :font-style="listStyle.descFontStyle"
                        ></basic-text>
                    </div>
                    <div class="button-container" v-show="getShowedComponent.button">
                        <basic-button :data="item.button"
                                      :button-style="listStyle.buttonStyle"
                        ></basic-button>
                    </div>
                    <div class="right-container">
                        <collection
                            @collection-status-change="collectionStatusChange({
                                    item,
                                    collectionId:`${_pageId}_${data.id}_${item.id}`,
                                    showedStatus: getShowedComponent
                                    })"
                            class="collection-icon icon-image"
                            v-if="isLogin && getShowedComponent.collection"
                            :item-id="`${_pageId}_${data.id}_${item.id}`"
                            :collection-style="listStyle.collectionStyle"></collection>
                    </div>
                    <div class="right-icon" v-show="getShowedComponent.icon">
                        <basic-image class="icon-image" :data="item.icon"></basic-image>
                    </div>
                </click-action>
                <div class="divide-line" :style="getDivideStyle"
                     v-show="getShowedComponent.divideLine&&itemIndex<filterData.length-1">
                </div>
            </div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
        </div>
    </div>
</template>

<script>
    import listMixin from "./list.mixin"

    export default {
        name: "ListTemplate03",
        mixins: [listMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less">

    .minisite-list-03 {
        .list-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .minisite-list-item {
            display: flex;
            flex-direction: column;
        }

        .main-item-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        .button-container, .title-container, .desc-container, .right-container {
            width: 100%;
        }

        .right-icon {
            margin-left: 10px;
            flex-shrink: 0;

            .icon-image {
                max-width: 50px;
            }
        }
    }

</style>
