<template>
    <div v-if="!_isEmpty(cubeNavStyle)" class="minisite-cube-nav-04">
        <template v-for="(_item, i) in cubeNavListData">
            <div class="list-content last-not-margin" :key="i" :style="{ marginBottom: spacing }">
                <template v-if="_item.layout === 1">
                    <cube-nav-item
                        v-for="item in _item.data"
                        :key="item.id"
                        :data="data"
                        :item-data="item"
                        :style="{ marginRight: spacing, height: moduleHeight.small }"
                        class="minisite-cube-nav-item layout-01">
                    </cube-nav-item>
                </template>
                <template v-if="_item.layout === 2">
                    <div class="last-not-margin layout-02">
                        <cube-nav-item
                            :data="data"
                            :item-data="_item.data[0]"
                            :style="{ height: moduleHeight.small, width: '30%' }"></cube-nav-item>
                        <cube-nav-item
                            :data="data"
                            :item-data="_item.data[1]"
                            :style="{ height: moduleHeight.big, width: '40%', margin: `0 ${spacing}` }"></cube-nav-item>
                        <cube-nav-item
                            :data="data"
                            :item-data="_item.data[2]"
                            :style="{ height: moduleHeight.small, width: '30%', }"></cube-nav-item>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import cubeMixin from "./cubNav.mixin"
    import _isEmpty from "lodash/isEmpty"
    import CubeNavItem from "@/components/cubeNav/CubeNavItem.vue"

    export default {
        name: "Template05",
        mixins: [cubeMixin],
        components: { CubeNavItem },
        props: {
            data: {}
        },
        data() {
            return {}
        },
        computed: {
            spacing() {
                return this.cubeNavStyle.gap + "px"
            },
            moduleHeight() {
                const heightScale = this.cubeNavStyle.heightScale.split("-")
                return {
                    small: this.cubeNavStyle.height + "px",
                    big: (+this.cubeNavStyle.height * heightScale[1]) / heightScale[0] + "px"
                }
            },
            cubeNavListData() {
                const target = []
                const contents = this.data.contents
                const columns = 3
                for (let i = 0; i < contents.length; i += columns) {
                    const list = contents.slice(i, i + columns)

                    target.push({
                        layout: list.length === columns ? 2 : 1,
                        data: list
                    })
                }
                return target
            }
        },
        mounted() {},
        methods: {
            _isEmpty
        }
    }
</script>
<style lang="less" scoped>
    .minisite-cube-nav-04 {
        .list-content {
            display: flex;

            > .layout-01 {
                width: 50%;
                flex-grow: 1;
                flex-shrink: 1;
            }

            .layout-02 {
                width: 100%;
                display: flex;
                align-items: center;

                > * {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                }
            }
        }

        .last-not-margin {
            :last-child {
                margin: 0 !important;
            }
        }

        .minisite-cube-nav-item {
            display: flex;
            flex-grow: 1;
            //overflow: hidden;
        }

        /deep/ .text-container {
            display: flex;
            flex-direction: column;
            //align-items: center;
        }

        /deep/ .main-item-content {
            display: flex;
            //justify-content: center;
            //align-items: center;
            flex-grow: 1;
            overflow: hidden;
            height: 100%;
        }
    }
</style>
