import {proxyApi} from "@/service/proxy";

function VideoApi(target, data) {
    return proxyApi('resource', target, data, 1)
}

export const GetVideosCount = async (videos) => {
    return await VideoApi('analytics', {ids: videos, type: 'video'})
}

export const GetVideosInfo = async (videos) => {
    return await VideoApi('uploadInfo', {ids: videos, type: 'video'})
}
