<template>
    <div class="minisite-button-image-container"
         :class="[collectionPositionIsButton ? '': 'button-' + buttonStyle.position]">
        <!--收藏显示的时候 按钮只能居左-->
        <div v-show="buttonStyle.show" class="button-icon-container"
             :style="getButtonStyle">
            <basic-text v-show="!buttonStyle.textHidden" :data="data"></basic-text>
            <svg-icon v-show="buttonStyle.iconStyle.show"
                      class="icon-right"
                      :style="{color: buttonStyle.iconStyle.color}"
                      :icon-class="buttonStyle.iconStyle.type"></svg-icon>

        </div>
        <collection v-if="collectionPositionIsButton"
                    v-bind="$attrs"
                    v-on="$listeners"
                    :collection-style="collectionStyle"></collection>
    </div>
</template>

<script>
    import BasicText from "./Text"
    import Collection from "../collection/Collection"
    import componentMixIn from "./component.mixin"
    import SvgIcon from "~@/assets/icons/SvgIcon"

    export default {
        name: "BasicButtonImageCollection",
        components: {
            BasicText,
            Collection,
            SvgIcon
        },
        mixins: [componentMixIn],
        props: {
            buttonStyle: {},
            collectionStyle: {}
        },
        computed: {
            collectionPositionIsButton() {
                return this?.collectionStyle?.show && this?.collectionStyle?.listPosition === "button"
            }
        }
    }
</script>

<style lang="less" scoped>
    .minisite-button-image-container {
        display: flex;
        text-align: center;

        &.button-left {
            justify-content: flex-start;
        }

        &.button-center {
            justify-content: center;
        }

        &.button-right {
            justify-content: flex-end;
        }

        .icon-right {
            font-size: 16px;
            margin-left: 4px;
            width: 1em;
            height: 1em;
        }

        .button-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

</style>
