<template>
    <div class="minisite-list-05" :class="'template-' + listStyle.templateId"
         v-if="listStyle.imageStyle">
        <div class="list-search"
             v-show="getShowedComponent && getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              v-model="searchWord"
                              :search-style="listStyle.searchStyle"
            ></search-component>
        </div>
        <div class="list-sorter">
            <div class="sorter-container">
                <img class="sorter-icon" :src="data.video.icon" alt="">
            </div>
            <div class="sorter-content" :style="listStyle.orderStyle">
                <van-dropdown-menu ref="sorter" :active-color="listStyle.orderStyle.color"
                                   :overlay="false"
                                   :style="{'--dropdown-menu-title-text-color':listStyle.orderStyle.color}">
                    <van-dropdown-item v-model="orderBy" get-container="#app"
                                       :options="data.video.sorter">
                    </van-dropdown-item>
                </van-dropdown-menu>
            </div>
        </div>
        <div class="list-list">
            <div class="minisite-list-item" v-for="(item,itemIndex) in orderList" :key="item.id"
                 :style="{width:listStyle.common.width+'%'}">
                <click-action class="main-item-content" :style="getCommonStyle"
                              ref="clickAction"
                              :data="item.clickAction" :key="item.id">
                    <div class="basic-info" :style="getBasicInfoStyle">
                        <div class="left-container" v-show="getShowedComponent.image"
                             :style="{width:listStyle.imageStyle.width+'%'}">
                            <div class="image-container">
                                <basic-image :data="item.image"
                                             :image-style="listStyle.imageStyle"
                                             :image-title-style="listStyle.imageTitleFontStyle"
                                ></basic-image>
                            </div>
                        </div>
                        <div class="right-container"
                             :style="getTextContentStyle">
                            <div v-show="getShowedComponent.title||getShowedComponent.desc">
                                <div class="title-container" v-show="getShowedComponent.title">
                                    <basic-text :data="item.title"
                                                :font-style="listStyle.titleFontStyle"
                                    ></basic-text>
                                </div>
                                <div class="desc-container" v-show="getShowedComponent.desc">
                                    <basic-text :data="item.desc"
                                                :font-style="listStyle.descFontStyle"
                                    ></basic-text>
                                </div>
                            </div>
                            <div class="button-container" v-show="getShowedComponent.button">
                                <basic-button :data="item.button"
                                              :button-style="listStyle.buttonStyle"
                                ></basic-button>
                            </div>
                            <template
                                v-if="(getShowedComponent.createTime&&item.hasOwnProperty('createTime')) || (getShowedComponent.count&&item.hasOwnProperty('count'))">
                                <div class="video-info">
                                    <div class="video-count"
                                         :style="listStyle.countStyle">
                                        <basic-text
                                            :data="data.video.count.content.replace(/\$\{count\}/g, item.count)"
                                            v-show="getShowedComponent.count && typeof item.count !== 'undefined'"
                                            :font-style="listStyle.countStyle"
                                        ></basic-text>
                                    </div>
                                    <div class="create-time">
                                        <basic-text :data="item.createTime"
                                                    v-show="getShowedComponent.createTime"
                                                    :font-style="listStyle.createTimeStyle"
                                        ></basic-text>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="right-icon">
                            <basic-image class="icon-image" :data="item.icon"
                                         v-show="getShowedComponent.icon"></basic-image>
                            <collection
                                @collection-status-change="collectionStatusChange({
                                    item,
                                    collectionId:`${_pageId}_${data.id}_${item.id}`,
                                    showedStatus: getShowedComponent
                                    })"
                                class="collection-icon icon-image"
                                :item-id="`${_pageId}_${data.id}_${item.id}`"
                                v-if="isLogin && getShowedComponent.collection"
                                :collection-style="listStyle.collectionStyle"></collection>
                        </div>
                    </div>
                </click-action>
                <div class="divide-line" :style="getDivideStyle"
                     v-show="getShowedComponent.divideLine&&itemIndex<filterData.length-1">
                </div>
            </div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
        </div>
    </div>
</template>

<script>
    import listMixin from "./list.mixin"
    import { GetVideosCount, GetVideosInfo } from "@/service/video"
    import _orderBy from "lodash/orderBy"
    import _compact from "lodash/compact"
    import _uniq from "lodash/uniq"
    import moment from "moment"
    import Vue from "vue"
    import { DropdownMenu, DropdownItem } from "vant"

    import "vant/lib/dropdown-menu/style"
    import "vant/lib/dropdown-item/style"
    import _cloneDeep from "lodash/cloneDeep"

    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    export default {
        name: "ListTemplate05",
        mixins: [listMixin],
        props: {
            data: {}
        },
        data() {
            return {
                // orderBy: 'createTimeDesc'
                orderBy: "countDesc"
            }
        },
        computed: {
            getBasicInfoStyle() {
                return {
                    alignItems: this.listStyle.common.alignItems
                }
            },
            orderList() {
                let _list = this.filterData
                switch (this.orderBy) {
                    //创建时间倒序
                    case "createTimeDesc":
                        _list = _orderBy(_list, ["formatCreateTime", "count"], ["desc", "desc"])
                        return _list
                    //创建时间正序
                    case "createTimeAscend":
                        _list = _orderBy(_list, ["formatCreateTime", "count"], ["asc", "desc"])
                        return _list
                    //播放量倒序
                    case "countDesc":
                        _list = _orderBy(_list, ["count", "formatCreateTime"], ["desc", "desc"])
                        return _list
                    //播放量正序
                    case "countAscend":
                        _list = _orderBy(_list, ["count", "formatCreateTime"], ["asc", "desc"])
                        return _list
                    default:
                        return _list
                }
            }
        },
        mounted() {
            this.getDivideLine()
            this.getSorter()
            this.getVideoInfo()
            this.$nextTick(() => {
                this.setSorterColor()
            })
        },
        methods: {
            getDivideLine() {
                //    兼容数据
                if (!this.data.style.divideLineStyle.margin) {
                    this.data.style.divideLineStyle = {
                        show: true,
                        style: "solid",
                        height: 1,
                        color: "rgba(0, 8, 17, 0.10)",
                        width: 100,
                        margin: {
                            top: 8,
                            bottom: 8,
                            left: 0,
                            right: 0
                        }
                    }
                }
            },
            setSorterColor() {
                this.$refs.sorter.$el.getElementsByClassName("van-dropdown-menu__item")[0].style.color = this.data.style.orderStyle.color
            },
            getSorter() {
                if (this.data.video) {
                    let _target = []
                    this.data.video.sorter.forEach((item) => {
                        if (item.name) {
                            _target.push({
                                text: item.name,
                                value: item.type
                            })
                        }
                    })
                    this.data.video.sorter = _target
                    this.orderBy = this.data.video.sorter[0].value
                }
            },
            async getVideoInfo() {
                let _videos = this.data.contents.map((_) => {
                    return _.video ? _.video.hashId : ""
                })
                _videos = _compact(_videos)
                _videos = _uniq(_videos)
                if (_videos.length) {
                    let { ret } = await GetVideosCount(_videos)
                    let _info = await GetVideosInfo(_videos)
                    this.data.contents.forEach((_) => {
                        if (_.video && _.video.hashId) {
                            this.$set(_, "count", parseInt(_.video.count) + parseInt(ret[_.video.hashId].pv))
                        }
                    })
                    this.data.contents.forEach((_) => {
                        if (_.video && _.video.hashId) {
                            if (_.video.createTime) {
                                this.$set(_, "createTime", _.video.createTime)
                                this.$set(_, "formatCreateTime", new Date(_.video.createTime))
                            } else {
                                this.$set(_, "createTime", moment(_info.ret[_.video.hashId].uploatAt).format("YYYY/MM/DD HH:mm:ss"))
                                this.$set(_, "formatCreateTime", new Date(_info.ret[_.video.hashId].uploatAt))
                            }
                        }
                    })
                } else {
                    this.data.contents.forEach((_) => {
                        this.$set(_, "count", parseInt(_.video.count))
                        this.$set(_, "createTime", "")
                    })
                }
                this.getListData()
            }
        }
    }
</script>

<style lang="less">

    .minisite-list-05 {


        .list-sorter {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            line-height: 24px;
            vertical-align: middle;

            .sorter-container {
                display: flex;
                align-items: center;
                justify-content: center;

                .sorter-icon {
                    width: 20px;
                }
            }

            .sorter-content {
                .van-dropdown-menu {
                    .van-dropdown-menu__bar {
                        background: none;
                        background-color: none;
                        box-shadow: none;
                        height: inherit;
                    }

                    .van-dropdown-menu__title {
                        color: inherit;

                        &:after {
                            content: none;
                        }
                    }
                }

                .van-dropdown-item__option--active {
                    color: inherit;
                }
            }
        }

        .list-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .minisite-list-item {
            display: flex;
            flex-direction: column;
        }

        .main-item-content {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .basic-info {
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
            }

            .video-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                //margin-top: 8px;
                //padding-top: 6px;

            }
        }


        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .right-container {
            flex-grow: 1;
            margin-left: 10px;
            vertical-align: top;
            display: flex;
            flex-direction: column;

            .button-container {
                margin-top: 10px;
            }
        }

        .right-icon {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            flex-shrink: 0;

            .icon-image, .collection-icon {
                max-width: 50px;
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
            }
        }
    }

</style>
