<template>
    <div class="minisite-nav-item nav-01" :style="getNavStyle">
        <div class="nav-component home-icon-container">
            <img class="nav-image nav-home" :src="data.content.home.imageSrc" alt="">
        </div>
        <div class="nav-component logo-container">
            <img class="nav-image nav-logo" :src="data.content.logo.imageSrc" alt="">
        </div>
    </div>
</template>

<script>
    import navMixin from './nav.mixin'

    export default {
        name: "Template-01",
        mixins: [navMixin],
        props: {
            data: {}
        }
    }
</script>

<style lang="less" scoped>

    @nav-00-height: 50px;
    .nav-01 {
        height: @nav-00-height;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        position: relative;

        .nav-component {
            height: 40px;
            max-height: 100%;
            max-width: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;

            .nav-image {
                height: auto;
                width: auto;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }

</style>
