<template>
    <div class="minisite-image-container" :style="getImageStyle" ref="siteImage">
        <!--        <div class="minisite-image" :style="getImage" v-if="data.imageSrc"></div>-->
        <!--        :src="`${data.imageSrc}&t=${new Date()}`"-->

        <template v-if="data.imageSrc">
            <template v-if="!isRound">
                <template v-if="lazyLoad">
                    <van-image
                        :lazy-load="lazyLoad && !disableLazyLoad"
                        class="minisite-image large-image"
                        :style="[getImageItemStyle,{height:largeSuccess?'auto':'0px'}]"
                        :src="data.imageSrc"
                        :show-loading="false"
                        :alt="data.imageAlt"
                        @load="imageLoad"
                        :fit="imageFit"
                        @error="imageError"
                    />
                    <van-image
                        v-show="!largeSuccess"
                        :lazy-load="lazyLoad && !disableLazyLoad"
                        class="minisite-image small-image"
                        :style="[getImageItemStyle,{height:largeSuccess?'0px':'auto'}]"
                        :src="imageSrc"
                        :show-loading="false"
                        :fit="imageFit"
                        :alt="data.imageAlt"
                        @error="imageError"
                    />
                </template>
                <van-image
                    v-else
                    :lazy-load="false"
                    class="minisite-image large-image"
                    :style="[getImageItemStyle]"
                    :src="data.imageSrc"
                    :show-loading="false"
                    :alt="data.imageAlt"
                    @load="imageLoad"
                    :fit="imageFit"
                    @error="imageError"
                />
            </template>
            <div v-else
                 class="minisite-image large-image minisite-image-round"
                 :style="[getImageItemStyle, { backgroundSize: '100%', backgroundImage: `url(${data.imageSrc})` }]"></div>
        </template>

        <!--        <img class="minisite-image" :style="getImageItemStyle" :src="data.imageSrc" :alt="data.imageAlt"-->
        <!--             v-if="data.imageSrc">-->
        <!--        <a-icon class="empty-image" type="picture" v-else/>-->
        <basic-text v-if="data.imageTitle && imageTitleStyle && imageTitleStyle.show" class="image-title"
                    :data="data.imageTitle"
                    :font-style="imageTitleStyle"></basic-text>
    </div>
</template>

<script>
    import Vue from "vue"
    import BasicText from "./Text"
    import componentMinIn from "./component.mixin"
    import { Image as VanImage } from "vant"
    import { Lazyload } from "vant"
    import { concatURI } from "@custouch/proxy-use"

    Vue.use(VanImage)
    Vue.use(Lazyload)

    export default {
        name: "BasicImage",
        mixins: [componentMinIn],
        components: {
            BasicText
        },
        data() {
            return {
                largeSuccess: false,
                disableLazyLoad: false
            }
        },
        props: {
            imageTitleStyle: {},
            imageStyle: {},
            lazyLoad: {
                default: true
            },
            imageFit: {
                type: String,
                default: "fill"
            },
            // 图片背景是否为圆形
            isRound: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            if(this.isRound) {
                this.imageLoad()
            }
            this.$nextTick(() => {
                // 当图片距窗口顶部为0时，lazyload无法触发
                let _offsetTop = this.$refs.siteImage?.offsetTop
                if (_offsetTop === 0) {
                    this.disableLazyLoad = true
                }
            })
        },
        computed: {
            imageSrc() {
                return concatURI(this.data.imageSrc, {
                    "x-oss-process": "image/resize,l_500"
                })
            }
        },
        methods: {
            imageError() {
                console.log("error")
            },
            imageLoad() {
                this.largeSuccess = true
            }
        }
    }
</script>

<style lang="less" scoped>

    .minisite-image-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        max-height: 100%;

        .minisite-image {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            overflow: hidden;
        }

        .image-title {
            position: absolute;
            bottom: 0;
            width: 100%;
            line-height: 1.4;
        }

        .minisite-image-round {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background-repeat: no-repeat;
            background-position: center;
        }

        .empty-image {
            font-size: 36px;
            margin: 0 auto;
        }
    }

</style>
