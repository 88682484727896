import { COLLECTION } from "~@/store/mutations_type"

const collectionStore = {
    namespaced: true,
    state: {
        list: []
    },
    getters: {
        collectionList: (state) => {
            return state.list
        },
        collectionIdList(state) {
            return state.list.map((_) => {
                if (_.detail.type === "sitePage") {
                    return `${_.detail.pageId}`
                } else if (_.detail.type === "templatePage") {
                    return `${_.detail.pageId}_${_.detail.dataRowId}`
                } else {
                    return `${_.detail.pageId}_${_.detail.componentId}_${_.detail.itemId}`
                }
            })
        },
        collectionIdDir(state) {
            let _res = {}
            state.list.forEach((_) => {
                if (_.detail.type === "sitePage") {
                    _res[`${_.detail.pageId}`] = _.id
                } else if (_.detail.type === "templatePage") {
                    return _res[`${_.detail.pageId}_${_.detail.dataRowId}`] = _.id
                } else {
                    _res[`${_.detail.pageId}_${_.detail.componentId}_${_.detail.itemId}`] = _.id
                }
            })
            return _res
        }
    },
    mutations: {
        [COLLECTION.SET_COLLECTION](state, data) {
            state.list = data
        },
        [COLLECTION.ADD_ITEM](state, data) {
            state.list.push(data)
        },
        [COLLECTION.DELETE_ITEM](state, id) {
            state.list = state.list.filter((_) => {
                return _.id !== id
            })
        }
    },
    actions: {
        setList({ state, commit }, data) {
            commit(COLLECTION.SET_COLLECTION, data)
        },
        addCollectionItem({ state, commit }, data) {
            commit(COLLECTION.ADD_ITEM, data)
        },
        deleteCollectionItem({ state, commit }, data) {
            commit(COLLECTION.DELETE_ITEM, data)
        }

    }
}

export default collectionStore
