<template>
    <div class="minisite-nav-item nav-00" :style="getNavStyle">
        <template v-for="item in data.contents">
            <div class="nav-component" v-if="item.show" :key="item.type">
                <div class="logo-container" v-if="item.type === 'logo'">
                    <click-action class="nav-image nav-home" :data="item.clickAction">
                        <img class="nav-image nav-logo" :src="item.imageSrc" alt=""
                             :style="getNavComponentStyle">
                    </click-action>
                </div>
                <div class="home-icon-container" v-if="item.type === 'home'">
                    <click-action class="nav-image nav-home" :data="item.clickAction">
                        <img class="nav-image nav-logo" :src="item.imageSrc" alt=""
                             :style="getNavComponentStyle">
                    </click-action>
                </div>
                <div class="lang-container" v-if="item.type === 'langs'">
                    <langs-component :data="item" :langs="langs"></langs-component>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import navMixin from './nav.mixin'
    import LangsComponent from "../LangsComponent";

    export default {
        name: "Template-00",
        mixins: [navMixin],
        components: {
            LangsComponent
        },
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .nav-00 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /*overflow: hidden;*/
        position: relative;

        .nav-component {
            max-height: 100%;
            height: 100%;
            max-width: 50%;
            /*overflow: hidden;*/
            display: flex;
            align-items: center;

            .nav-image {
                height: auto;
                width: auto;
                max-height: 100%;
                max-width: 100%;
            }

            .lang-container {
                height: 100%;
                width: 100%;
            }
        }
    }

</style>
