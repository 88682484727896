import {BasicText, BasicImage} from './../basic/index'
import commonMixIn from './../common.mixin'

export default {
    mixins: [commonMixIn],
    components: {
        BasicText,
        BasicImage
    },
    computed: {
        getCommonStyle() {
            return Object.assign({},
                this.data.style.common,
                this.getBackground(this.data.style.common.background),
                this.getPadding(this.data.style.common.padding),
                this.getMargin(this.data.style.common.margin),
                {
                    width: this.data.style.common.width + '%'
                }
            )
        }
    }
}
