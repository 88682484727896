import _cloneDeep from "lodash/cloneDeep"

const SearchNormalList = function(src, key) {
    let word = key.toLowerCase()
    let _src = _cloneDeep(src)
    let _res = _cloneDeep(src)
    _res.contents = []
    _res.contents = _src.contents.filter((_) => {
        return _.title.content.toLowerCase().indexOf(word) > -1
    })

    // console.log(_res)
    // .forEach((_item => {
    //
    // if (_item.title.content.toLowerCase().indexOf(word) > -1) {
    //     _item.matched = true
    //     _res.contents.push(_item)
    // }
    // }))
    return _res
}
const SearchButton = function(src, key) {
    let word = key.toLowerCase()
    let _src = _cloneDeep(src)
    let _res = _cloneDeep(src)
    _res.contents = []
    _src.contents.forEach((_item => {
        if (_item.button.buttonText.toLowerCase().indexOf(word) > -1) {
            _item.matched = true
            _res.contents.push(_item)

        }
    }))
    return _res
}

const SearchText = function(src, key) {
    let word = key.toLowerCase()
    let _src = _cloneDeep(src)
    let _res = _cloneDeep(src)
    _res.contents = []
    _src.contents.forEach((_item => {
        if (_item.text.contents.toLowerCase().indexOf(word) > -1) {
            _item.matched = true
            _res.contents.push(_item)
        }
    }))
    return _res
}
const SearchImage = function(src, key) {
    let word = key.toLowerCase()
    let _src = _cloneDeep(src)
    let _res = _cloneDeep(src)
    _res.contents = []
    _src.contents.forEach((_item => {
        if (_item.image.imageTitle.toLowerCase().indexOf(word) > -1) {
            _item.matched = true
            _res.contents.push(_item)
        }
    }))
    return _res
}

const SearchVideo = function(src, key) {
    let word = key.toLowerCase()
    let _src = _cloneDeep(src)
    let _res = _cloneDeep(src)
    _res.contents = []
    _src.contents.forEach((_item => {
        if (_item.video.title.toLowerCase().indexOf(word) > -1) {
            _item.matched = true
            _res.contents.push(_item)
        }
    }))
    return _res
}


const SearchInnerList = function(src, key) {
    let word = key.toLowerCase()
    let _src = _cloneDeep(src)
    let _res = _cloneDeep(src)
    _res.contents = []
    _src.contents.forEach((content) => {
        let matchedCount = 0
        if (content.title.content.toLowerCase().indexOf(word) > -1) {
            _res.contents.push(content)
        } else {
            let _resChild = _cloneDeep(content)
            _resChild.children = []
            content.children.forEach((child) => {
                let _r
                switch (child.type) {
                    case "list":
                        _r = SearchNormalList(child, key)
                        if (_r.contents.length > 0) {
                            _r.matched = true
                            _resChild.children.push(_r)
                        }
                        break
                    case "text":
                        _r = SearchText(child, key)
                        if (_r.contents.length > 0) {
                            _r.matched = true
                            _resChild.children.push(_r)
                        }
                        break
                    case "button":
                        _r = SearchButton(child, key)
                        if (_r.contents.length > 0) {
                            _r.matched = true
                            _resChild.children.push(_r)
                        }
                        break
                    case "image":
                    case "banner":
                        _r = SearchImage(child, key)
                        if (_r.contents.length > 0) {
                            _r.matched = true
                            _resChild.children.push(_r)
                        }
                        break
                    case "video":
                        _r = SearchVideo(child, key)
                        if (_r.contents.length > 0) {
                            _r.matched = true
                            _resChild.children.push(_r)
                        }
                        break
                }
            })

            if (_resChild.children.length) {
                _res.contents.push(_resChild)
            }
        }
        // if (matchedCount === 0 && content.children[0].contents.length > 0) {
        //     content.matched = false
        // }
    })
    return _res

}

export {
    SearchNormalList,
    SearchButton,
    SearchText,
    SearchInnerList
}
