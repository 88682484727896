<template>
    <div class="minisite-image-01">
        <div class="template01-images-group00">
            <div class="minisite-image-item template01-images00" :style="getCommonStyle">
                <div class="image-container image-item-0">
                    <click-action :data="data.contents[0].image.clickAction">
                        <basic-image :data="data.contents[0].image"
                                     :image-style="data.style.imageStyle"
                                     :image-title-style="data.style.imageTitleFontStyle"
                        ></basic-image>
                    </click-action>
                </div>
            </div>
        </div>
        <div class="template01-images-group01" v-if="data.contents[1]">
            <div class="minisite-image-item template01-images01" :style="[getCommonStyle,{
                paddingBottom:data.contents.length>2?'10px':'0',
                paddingRight:0
            }]">
                <!--                paddingRight:data.contents.length>2?'10px':'0',-->

                <div class="image-container image-item-1">
                    <click-action :data="data.contents[1].image.clickAction">
                        <basic-image :data="data.contents[1].image"
                                     :image-style="data.style.imageStyle"
                                     :image-title-style="data.style.imageTitleFontStyle"
                        ></basic-image>
                    </click-action>
                </div>
            </div>
            <div class="minisite-image-item template01-images02" :style="[getCommonStyle,{paddingRight:0}]"
                 v-if="data.contents[2]">
                <div class="image-container image-item-2">
                    <click-action :data="data.contents[2].image.clickAction">
                        <basic-image :data="data.contents[2].image"
                                     :image-style="data.style.imageStyle"
                                     :image-title-style="data.style.imageTitleFontStyle"
                        ></basic-image>
                    </click-action>
                </div>
            </div>
        </div>
        <!--        <div class="minisite-image-item" v-for="(item,itemIndex) in data.content" :key="item.id"-->
        <!--             :style="{width:data.style.common.width+'%'}">-->
        <!--            <div class="main-item-content" :style="getCommonStyle">-->
        <!--                <div class="image-wrapper"-->
        <!--                     :style="{width:data.style.imageStyle.width+'%'}">-->
        <!--                    <div class="template-01-image-00">-->
        <!--                        <div class="image-container" :class="'image-item-' + itemIndex">-->
        <!--                            <basic-image :data="item.image"-->
        <!--                                         :image-style="data.style.imageStyle"-->
        <!--                                         :image-title-style="data.style.imageTitleFontStyle"-->
        <!--                            ></basic-image>-->
        <!--                        </div>-->
        <!--                    </div>-->

        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
    import imagesMixin from './images.mixin'

    export default {
        name: "ImagesTemplate01",
        mixins: [imagesMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less">

    .minisite-image-01 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .minisite-image-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .image-container {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                overflow: hidden;
            }
        }

        .minisite-link {
            height: 100%;
            width: 100%;
        }

        .main-item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .images-wrapper {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .template01-images-group00, .template01-images-group01 {
            width: 48%;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .template01-images-group00 {
            //padding-bottom: 0 !important;

            .minisite-image-item {
                height: 100%;
            }

            .minisite-image-container {
                height: 100%;

                .minisite-image {
                    flex-grow: 1;
                }
            }
        }


        .template01-images01 {
            .minisite-image-item {
                /*height: 50%;*/
                flex-grow: 1;
                flex-shrink: 0;
                /*max-height: 50%;*/
                overflow: hidden;

            }
        }

        .template01-images02 {
            padding-bottom: 0 !important;
        }
    }

</style>
