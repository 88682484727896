<template>
    <div class="minisite-video-container" :style="getVideoStyle">
        <div ref="renderVideo" class="cv-mb-4 cv-w-full cv-h-auto" style="width: 100%; height: 100%;"></div>
    </div>
</template>

<script>
    import { render,setConfig} from '@custouch/resource-video'

    import componentMinIn from './component.mixin'

    export default {
        name: "BasicVideo",
        mixins: [componentMinIn],
        components: {},
        props: {
            videoTitleStyle: {},
            videoStyle: {}
        },
        data() {
            return {
                videoSource: '',
                instance: null
            }
        },
        computed: {
            // getVideo() {
            //     return {
            //         backgroundVideo: 'url("' + this.data.videoSrc + '")',
            //         backgroundSize: 'contain'
            //     }
            // }
        },
        mounted() {
            this.renderVideo()
        },
        methods: {
            renderVideo() {
                setConfig({ appId:this._appId })

                this.instance = render(this.$refs.renderVideo, {
                    source: this.data.videoSrc,
                    poster: this.data.poster,
                    allowSame: true,
                    aspectRatio: '16:9',
                })
            },
        }
    }
</script>

<style lang="less">
    @import "~@custouch/resource-video/vue/dist/style.css";

    .minisite-video-container {
        ul {
            list-style: none;
            padding: 0;
        }
        //videoSrceo {
        //    width: 100%;
        //    //height: 100%;
        //}
    }

    //.c-vjs-w-full {
    //    width: 100%;
    //    height: 400px!important;
    //}
</style>
<style lang="less" scoped>

    .minisite-video-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        max-height: 100%;

        .minisite-video {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            /*background-repeat: no-repeat;*/
        }

        .video-title {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        .empty-video {
            font-size: 36px;
            margin: 0 auto;
        }
    }

</style>
