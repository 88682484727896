import {BasicText, BasicImage} from './../basic/index'
// , BasicImage, BasicButton
import commonMixIn from './../common.mixin'

export default {
    mixins: [commonMixIn],
    data() {
        return {
            pageId: this._pageId,
            rowsCount: 1,
            columnsCount: 1
        }
    },
    components: {
        BasicText, BasicImage
    },
    computed: {
        getTabStyle() {
            return Object.assign(
                {},
                this.data.style.tabStyle,
                this.getBackground(this.data.style.tabStyle.background),
                this.getPadding(this.data.style.tabStyle.padding),
                {
                    'border-top-style': this.data.style.borderTopStyle.style,
                    'border-top-width': this.data.style.borderTopStyle.height + 'px',
                    'border-top-color': this.data.style.borderTopStyle.color,
                    'flex-wrap': this.data.style.tabStyle.flexWrap ? 'wrap' : 'nowrap'
                },
            )
        },
        getDivideLineStyle() {
            return Object.assign({}, {
                height: Math.round(this.data.style.commonStyle.height * this.data.style.divideLineStyle.height / 100) + 'px',
                'border-left-style': this.data.style.divideLineStyle.style,
                'border-left-width': this.data.style.divideLineStyle.width + 'px',
                'border-left-color': this.data.style.divideLineStyle.color
            })
        },
        getTabComponentStyle() {
            return {
                ...this.getBackground(this.data.style.normalStyle.background),
                ...this.getPadding(this.data.style.commonStyle.padding),
                height: this.data.style.commonStyle.height + 'px',
                // minWidth: this.data.style.commonStyle.minWidth - 2 + '%',
                width: this.data.style.commonStyle.minWidth - 2 + '%'
                // height: (this.data.style.tabStyle.height - this.data.style.commonStyle.padding.top - this.data.style.commonStyle.bottom) + 'px'
            }
        },
        getTabIconStyle() {
            return {
                width: this.data.style.iconStyle.width + 'px',
            }
        },
        getShowedComponent() {
            if (this.data.style.divideLineStyle) {
                return {
                    divideLine: this.data.style.divideLineStyle ? this.data.style.divideLineStyle.show : false,
                }
            }
            return {}
        },
    },
    mounted() {
        this.getActiveTab()
        // 分行列用于border展示
        this.getSplit()
    },
    methods: {
        getActiveTab() {
            this.data.contents.forEach((tab) => {
                if (tab.clickAction.type === 'sitePages') {
                    this.$set(tab.clickAction, 'target', tab.clickAction.content.content[0].id)
                }
            })
        },
        getSplit() {
            this.columnsCount = Math.floor(100 / this.data.style.commonStyle.minWidth)
            this.rowsCount = Math.ceil(this.data.contents.length / this.columnsCount)
        }
    }
}
