<template>
    <div v-if="listStyle.listStyle"
         class="minisite-list-15"
         :class="[{'reverse':listStyle.listStyle.flexDirection.direction === 'row-reverse'}, 'template-' + listStyle.templateId]"
    >
        <div class="list-search"
             v-show="getShowedComponent && getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              :search-style="listStyle.searchStyle"
                              v-model="searchWord"
            ></search-component>
        </div>
        <div class="list-content">
            <div v-for="(item,itemIndex) in filterData" :key="item.id" class="minisite-list-item"
                 :style="{width:listStyle.common.width+'%'}">
                <click-action class="main-item-content"
                              :style="[getCurrentCommonStyle, {flexDirection: listStyle.listStyle.flexDirection.direction}]"
                              :data="item.clickAction" :key="item.id">
                    <div v-show="getShowedComponent.image"
                         class="left-container"
                         :style="[{width:listStyle.imageStyle.width+'%'},getImageStyle]">
                        <div class="image-container">
                            <basic-image :data="item.image"
                                         :image-style="listStyle.imageStyle"
                                         :image-title-style="listStyle.imageTitleFontStyle"
                            ></basic-image>
                            <div
                                v-show="listStyle.collectionStyle.listPosition === 'image' && getShowedComponent.collection"
                                class="image-collection"
                                :style="{ backgroundColor: listStyle.collectionStyle.backgroundColor }">
                                <collection
                                    :data="item.button"
                                    @collection-status-change="collectionStatusChange({
                                    item,
                                    collectionId:`${_pageId}_${data.id}_${item.id}`,
                                    showedStatus: getShowedComponent
                                    })"
                                    :item-id="`${_pageId}_${data.id}_${item.id}`"
                                    :collection-style="listStyle.collectionStyle"></collection>
                            </div>
                        </div>
                    </div>
                    <div class="right-container" :style="getTextContentStyle">
                        <div v-show="getShowedComponent.title||getShowedComponent.desc">
                            <div v-show="getShowedComponent.title" class="title-container"
                            >
                                <basic-text :data="item.title"
                                            :font-style="listStyle.titleFontStyle"
                                ></basic-text>
                            </div>
                            <div v-show="getShowedComponent.desc" class="desc-container">
                                <basic-text :data="item.desc"
                                            :font-style="listStyle.descFontStyle"
                                ></basic-text>
                            </div>
                        </div>
                        <basic-button-image-collection
                            v-show="getShowedComponent.button || getShowedComponent.collection"
                            :style="{marginTop: getShowedComponent.desc ? '0':'18px' }"
                            :data="item.button"
                            @collection-status-change="collectionStatusChange({
                                    item,
                                    collectionId:`${_pageId}_${data.id}_${item.id}`,
                                    showedStatus: getShowedComponent
                                    })"
                            :item-id="`${_pageId}_${data.id}_${item.id}`"
                            :collection-style="listStyle.collectionStyle"
                            :button-style="listStyle.buttonStyle"
                        ></basic-button-image-collection>
                    </div>
                    <div class="bottom-container"
                         :style="[getCommonBorderStyle, {backgroundColor: listStyle.common.background.backgroundColor}]"></div>
                </click-action>
                <div class="divide-line" :style="getDivideStyle"
                     v-show="getShowedComponent.divideLine&&itemIndex<filterData.length-1">
                </div>
            </div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
        </div>
    </div>
</template>

<script>
    import listMixin from "./list.mixin"

    export default {
        name: "ListTemplate15",
        mixins: [listMixin],
        props: {
            data: {}
        },
        data() {
            return {
                listStyle: {},
                blockHeight: "initial"
            }
        },
        computed: {
            getCurrentCommonStyle() {
                const commonStyle = this.getCommonStyle
                delete commonStyle?.border
                delete commonStyle?.borderRadius
                delete commonStyle?.boxShadow
                return commonStyle
            },
            getCommonBorderStyle() {
                return Object.assign({}, {
                    border: this.listStyle.common.border,
                    boxShadow: this.listStyle.common.boxShadow,
                    borderRadius: this.listStyle.common.borderRadius + "px"
                })
            }
        },
        mounted() {
            this.listStyle = this.data.style
        },
        methods: {}
    }
</script>

<style lang="less">

    .minisite-list-15 {

        .list-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .minisite-list-item {
            display: flex;
            flex-direction: column;
            padding-left: 36px;
        }

        .main-item-content {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            background-color: transparent !important;
            position: relative;
        }

        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            margin-left: -36px;
            z-index: 1;

            .image-container {
                display: flex;
                align-items: center;
                justify-content: center;

                .image-collection {
                    width: 32px;
                    height: 32px;
                    border-radius: 0 0 4px 4px;;
                    position: absolute;
                    top: 0;
                    right: 8px;
                }
            }
        }

        &.reverse {
            .minisite-list-item {
                padding-left: 0;
                padding-right: 36px;
            }

            .left-container {
                margin-left: 0;
                margin-right: -36px;
            }
        }

        .right-container {
            flex-grow: 1;
            padding: 0 12px 12px;
            vertical-align: top;
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            z-index: 1;

            overflow: hidden;
            .title-container, .desc-container {
                overflow: hidden;
                width: 100%;
                .text-container, .text-container p  {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .button-container {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

        }

        .right-icon {
            height: 100%;
            margin-left: 10px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .icon-image {
                max-width: 30px;
            }
        }

        .bottom-container {
            width: 100%;
            position: absolute;
            height: 84px;
            z-index: 0;
        }
    }

</style>
