<template>
    <div class="minisite-langs">
        <template-00 v-if="data.templateId === '00'" :data="data" :langs="langs"></template-00>
        <template-01 v-if="data.templateId === '01'" :data="data" :langs="langs"></template-01>
    </div>
</template>

<script>
    import CommonMixIn from "./common.mixin";
    import LangsTemplate from './langs'

    export default {
        name: "LangsComponent",
        mixins: [CommonMixIn],
        props: {
            langs: {}
        },
        components: {
            'template-00': LangsTemplate.Template00,
            'template-01': LangsTemplate.Template01
        }
    }
</script>

<style lang="less" scoped>
    .minisite-langs {
        height: 100%;
        width: 100%;
    }

</style>
