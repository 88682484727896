<template>
    <div class="minisite-list-01">
        <div class="list-search"
             v-show="getShowedComponent && getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              :search-style="listStyle.searchStyle"
                              v-model="searchWord"
            ></search-component>
        </div>
        <div class="list-content">
            <div class="minisite-list-item" v-for="item in filterData" :key="item.id"
                 :style="{width:listStyle.common.width+'%'}">
                <!--            <click-action :data="item.image.clickAction">-->
                <click-action class="main-item-content" :style="getCommonStyle"
                              :data="item.clickAction" :key="item.id"
                              ref="listTemplate1blockItem">
                    <template v-if="listStyle.imageStyle">
                        <div class="left-container" v-if="getShowedComponent.image"
                             :style="[getImageStyle,{height:blockHeight}]">
                            <div class="image-container"
                                 :style="{width:listStyle.imageStyle.width+'%'}">
                                <basic-image :data="item.image"
                                             :image-style="listStyle.imageStyle"
                                             :image-title-style="listStyle.imageTitleFontStyle"
                                ></basic-image>
                            </div>
                        </div>
                        <div class="right-container">
                            <div class="title-container" v-if="getShowedComponent.title">
                                <basic-text :data="item.title"
                                            :font-style="listStyle.titleFontStyle"
                                ></basic-text>
                            </div>
                            <div class="desc-container" v-if="getShowedComponent.desc">
                                <basic-text :data="item.desc"
                                            :font-style="listStyle.descFontStyle"
                                ></basic-text>
                            </div>
                            <div class="button-container" v-if="getShowedComponent.button">
                                <basic-button :data="item.button"
                                              :button-style="listStyle.buttonStyle"
                                ></basic-button>
                            </div>
                        </div>
                        <div class="right-icon">
                            <collection
                                @collection-status-change="collectionStatusChange({
                                    item,
                                    collectionId:`${_pageId}_${data.id}_${item.id}`,
                                    showedStatus: getShowedComponent
                                    })"
                                class="collection-icon icon-image"
                                v-if="isLogin && getShowedComponent.collection"
                                :item-id="`${_pageId}_${data.id}_${item.id}`"
                                :collection-style="listStyle.collectionStyle"></collection>
                            <basic-image :data="item.icon"
                                         v-show="getShowedComponent.icon"></basic-image>
                        </div>
                    </template>
                </click-action>
                <template v-if="listStyle.imageStyle">
                    <div class="divide-line" v-if="getShowedComponent.divideLine"
                         :style="getDivideStyle">
                    </div>
                </template>
                <!--            </click-action>-->
            </div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
        </div>
    </div>
</template>

<script>
    import listMixin from "./list.mixin"

    export default {
        name: "ListTemplate01",
        mixins: [listMixin],
        props: {
            data: {}
        },
        data() {
            return {
                blockHeight: "initial"
            }
        },
        computed: {},
        mounted() {
            this.getBlockHeight()
        },
        watch: {
            listStyle: {
                deep: true,
                handler(val) {
                    this.getBlockHeight()
                }
            }
        },
        methods: {
            getBlockHeight() {
                if (this.$refs.listTemplate1blockItem) {
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            let targetWidth = this.$refs.listTemplate1blockItem[0].$el.offsetWidth
                            this.blockHeight = `${targetWidth}px`
                        })
                    })
                }

            }
        }
    }
</script>

<style lang="less" scoped>

    .minisite-list-01 {
        .list-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .minisite-list-item {
            /*margin-bottom: 20px;*/
        }

        .main-item-content {
            display: block;
            overflow: hidden;
            /*flex-direction: column;*/
            /*justify-content: space-between;*/
        }

        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
            /*width: 100%;*/
            display: flex;
            justify-content: center;
        }

        .image-container {
            display: flex;
            flex-direction: column;
        }

        .right-container {
            flex-grow: 1;
            vertical-align: top;

            .button-container {
                margin-top: 10px;
            }
        }

        .right-icon {
            flex-shrink: 0;
        }
    }

</style>

<style lang="less">
    .minisite-list-01 {
        .minisite-image-container {
            height: 100%;

            .minisite-image {
                max-height: initial;
            }
        }

    }

</style>
