<template>
    <div class="minisite-cube-nav">
        <component :is="currentComponent" :data="data" :key="data.style.templateId"></component>
    </div>
</template>

<script>
    import CubeNavTemplate from '~@/components/cubeNav/index'
    import CommonMixIn from "@/components/common.mixin"

    export default {
        name: "CubeNav",
        mixins: [CommonMixIn],
        props: {
            data: {}
        },
        computed: {
            currentComponent() {
                return  Object.entries(CubeNavTemplate).find(([key, val]) => key.includes(this.data.style.templateId))[1]
            }
        }
    }
</script>

<style lang="less" scoped>

</style>
