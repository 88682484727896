var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"langs-list template-01"},[_c('multiselect',{staticClass:"langs-select",attrs:{"track-by":"id","label":"name","options":_vm.langs.list,"searchable":false,"allow-empty":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('click-action',{staticClass:"langs-link",class:{'current': _vm.langs.current === option.id},attrs:{"data":{
                                type:'sitePages',
                                content:{
                                    type:'langs',
                                    content:[
                                        {
                                            url:option.id
                                        }
                                    ]
                                }
                            }}},[_vm._v(" "+_vm._s(option.name)+" ")])]}}]),model:{value:(_vm.currentLang),callback:function ($$v) {_vm.currentLang=$$v},expression:"currentLang"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }