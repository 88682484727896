<template>
    <div class="minisite-list-02" :class="'template-' + listStyle.templateId"
         v-if="listStyle.imageStyle">
        <div class="list-search"
             v-show="getShowedComponent && getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              v-model="searchWord"
                              :search-style="listStyle.searchStyle"
            ></search-component>
        </div>
        <div class="list-content">
            <div class="minisite-list-item" v-for="(item,itemIndex) in filterData"
                 :key="item.id"
                 :style="{width:listStyle.common.width+'%'}">
                    <click-action class="main-item-content" :style="getCommonStyle"
                                  :data="item.clickAction" :key="item.id">
                        <div class="left-container" v-show="getShowedComponent.image"
                             :style="{width:listStyle.imageStyle.width+'%'}">
                            <div class="image-container">
                                <basic-image :data="item.image"
                                             :image-style="listStyle.imageStyle"
                                             :image-title-style="listStyle.imageTitleFontStyle"
                                ></basic-image>
                            </div>
                        </div>
                        <div class="right-container" :style="getTextContentStyle">
                            <div v-show="getShowedComponent.title||getShowedComponent.desc">
                                <div class="title-container" v-show="getShowedComponent.title">
                                    <basic-text :data="item.title"
                                                :font-style="listStyle.titleFontStyle"
                                    ></basic-text>
                                </div>
                                <div class="desc-container" v-show="getShowedComponent.desc">
                                    <basic-text :data="item.desc"
                                                :font-style="listStyle.descFontStyle"
                                    ></basic-text>
                                </div>
                            </div>
                            <div class="button-container">
                                <basic-button style="flex-grow: 1" :data="item.button"
                                              v-show="getShowedComponent.button"
                                              :button-style="listStyle.buttonStyle"
                                ></basic-button>
                                <collection style="width: initial;flex-grow: 1"
                                            @collection-status-change="collectionStatusChange({
                                    item,
                                    collectionId:`${_pageId}_${data.id}_${item.id}`,
                                    showedStatus: getShowedComponent
                                    })"
                                            class="collection-icon icon-image"
                                            v-if="isLogin && getShowedComponent.collection"
                                            :item-id="`${_pageId}_${data.id}_${item.id}`"
                                            :collection-style="listStyle.collectionStyle"></collection>
                            </div>
                        </div>
                        <div class="right-icon"
                             v-show="getShowedComponent.icon">
                            <basic-image class="icon-image" :data="item.icon"></basic-image>
                        </div>
                    </click-action>
                    <div class="divide-line" :style="getDivideStyle"
                         v-show="getShowedComponent.divideLine&&itemIndex<filterData.length-1">
                    </div>
            </div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
            <div :style="{width:listStyle.common.width+'%'}"></div>
        </div>
    </div>
</template>

<script>
    import listMixin from './list.mixin'

    export default {
        name: "ListTemplate02",
        mixins: [listMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .minisite-list-02 {

        .list-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .minisite-list-item {
            display: flex;
            flex-direction: column;
        }

        .main-item-content {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            flex-grow: 1;
        }

        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .right-container {
            flex-grow: 1;
            margin-left: 10px;
            vertical-align: top;
            display: flex;
            flex-direction: column;

            .button-container {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .right-icon {
            height: 100%;
            margin-left: 10px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .icon-image {
                max-width: 30px;
            }
        }
    }

</style>
