<template>
    <div class="nav-wrapper" :style="getNavStyle">
        <template v-for="(_item, key) in navData">
            <div
                v-if="_item.length || logoAlign === 'center'"
                :key="key"
                class="minisite-nav-item nav-00"
                :class="{ [key]: !!logoAlign }">
                <template v-for="item in _item">
                    <div class="nav-component" :key="item.type">
                        <div class="logo-container" v-if="item.type === 'logo'">
                            <click-action class="nav-image nav-home" :data="item.clickAction">
                                <img
                                    class="nav-image nav-logo"
                                    :src="item.imageSrc"
                                    alt=""
                                    :style="getNavComponentStyle" />
                            </click-action>
                        </div>
                        <div class="home-icon-container" v-if="item.type === 'home'">
                            <click-action class="nav-image nav-home" :data="item.clickAction">
                                <img
                                    class="nav-image nav-logo"
                                    :src="item.imageSrc"
                                    alt=""
                                    :style="getNavComponentStyle" />
                            </click-action>
                        </div>
                        <div class="lang-container" v-if="item.type === 'langs'">
                            <langs-component :data="item" :langs="langs"></langs-component>
                        </div>
                        <div class="home-icon-container" v-if="item.type === 'menu'">
                            <div class="nav-image nav-home" @click="showSideNav = !showSideNav">
                                <img
                                    class="nav-image nav-logo"
                                    :src="showSideNav ? closeButtonImage : item.imageSrc"
                                    alt=""
                                    :style="getNavComponentStyle" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <van-popup
            v-model="showSideNav"
            :position="sideNavPosition"
            :get-container="getContainer"
            :overlay-style="{top: navHeight + 'px'}"
            class="van-popup-wrapper"
            :style="{height: `calc(100% - ${navHeight}px)`, top: `calc(50% + ${navHeight / 2}px)`}"
            style="width: 50%;">
<!--            <div :style="`height: ${navHeight}px;pointerEvents:none`"></div>-->
            <div class="side-nav-list">
                <click-action
                    v-for="nav in sideNavData.list"
                    :key="nav.id"
                    :data="nav.clickAction"
                    :class="{'active': isCurrentPage(nav)}"
                    class="side-nav-wrapper">
                    <div class="icon" v-if="sideNavData.showIcon">
                        <img :src="isCurrentPage(nav) ? nav.iconActive : nav.iconNormal" alt="" />
                    </div>
                    <span class="text">{{ nav.text }}</span>
                </click-action>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Vue from "vue"
    import navMixin from "./nav.mixin"
    import LangsComponent from "../LangsComponent"
    import { Popup } from "vant"
    import "vant/lib/popup/style"

    Vue.use(Popup)

    export default {
        name: "Template-02",
        mixins: [navMixin],
        components: {
            LangsComponent
        },
        props: {
            data: {}
        },
        data() {
            return {
                showSideNav: false,
                getContainer: () => document.querySelector('#app'),
                closeButtonImage: "https://oss.cloud.custouch.com/static/cloud/images/minisite/close.png"
            }
        },
        computed: {
            // logo 位置
            // left / center / right | undefined
            logoAlign() {
                return this.data.style.navStyle?.logoAlign
            },
            // 导航栏数据分块处理
            navData() {
                // left / center / right
                const targetData = {
                    left: [],
                    center: [],
                    right: []
                }
                const list = this.data.contents.filter((v) => v.show)
                const logoData = list.filter((v) => v.type === "logo")
                const noneLogoList = list.filter((v, i) => v.type !== "logo")

                switch (this.logoAlign) {
                    case "left":
                        targetData.left = [...logoData]
                        targetData.right = noneLogoList
                        break
                    case "center":
                        targetData.center = [...logoData]
                        if (noneLogoList.length === 1) {
                            targetData.right = noneLogoList
                        } else {
                            targetData.left = [noneLogoList[0]]
                            targetData.right = noneLogoList.slice(1)
                        }
                        break
                    case "right":
                        targetData.left = noneLogoList
                        targetData.right = [...logoData]
                        break
                    default:
                        targetData.center = list
                        break
                }
                return targetData
            },
            // 侧边导航栏位置
            sideNavPosition() {
                let align = "left"
                Object.entries(this.navData).forEach(([key, val]) => {
                    if (~val.findIndex((v) => v.type === "menu")) {
                        return (align = key)
                    }
                })
                if (align === "center") {
                    align = "left"
                }

                return align
            },
            // 侧边导航的相关数据，如：是否展示 icon 等
            sideNavData() {
                const target = this.data.contents.find((v) => v.type == "menu" && v.show)

                return {
                    showIcon: target?.menuType === "icon",
                    list: target?.contents ?? []
                }
            },
            // 导航栏高度
            navHeightValue() {
                const { navStyle } = this.data.style
                return +navStyle.height + +navStyle.padding.top + +navStyle.padding.bottom
            }
        },
        methods: {
            isCurrentPage(nav) {
                const  homePage = this._siteData.siteInfo.siteData.settings?.baseInfo?.homePage
                if(nav.clickAction.content.content[0].url !== 'index') {
                    return this._pageId && nav.clickAction.content.content[0].path === this._pageId
                } else {
                    return homePage === this._pageId
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .van-popup-wrapper {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        .side-nav-list {
            flex: 1;
            overflow-y: auto;
            background-color: white;
        }
    }
    .nav-wrapper {
        display: flex;
        justify-content: space-between;

        .nav-00 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            /*overflow: hidden;*/
            position: relative;
            flex: 1;
            flex-shrink: 0;

            &.left {
                justify-content: flex-start;
                > :not(:first-child) {
                    margin-left: 16px;
                }
            }

            &.center {
                justify-content: center;
            }

            &.right {
                justify-content: flex-end;
                > :not(:last-child) {
                    margin-right: 16px;
                }
            }

            .nav-component {
                max-height: 100%;
                height: 100%;
                // max-width: 50%;
                /*overflow: hidden;*/
                display: flex;
                align-items: center;

                .nav-image {
                    height: auto;
                    width: auto;
                    max-height: 100%;
                    max-width: 100%;
                }

                .lang-container {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
    .side-nav-list {
        .side-nav-wrapper {
            display: flex;
            align-items: center;
            padding: 13px 16px;
            border-bottom: 1px solid #f0f0f0;
            text-align: left;
            
            &.active {
                color: white;
                background-color: var(--c-site-primary-color-);
            }

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                flex-shrink: 0;
                img {
                    max-width: 100%;
                }
            }
        }
    }
</style>
