<template>
    <div class="minisite-image-00">
        <div class="minisite-image-item" v-for="item in data.contents" :key="item.id"
             :style="[getImageContainerStyle, {width:data.style.common.width+'%'}]">
            <click-action :data="item.image.clickAction">
                <div class="main-item-content" :style="getCommonStyle">
                    <div class="left-container"
                         :style="{}">
                        <div class="image-container">
                            <basic-image :data="item.image"
                                         :style="{'align-self':data.style.imageStyle.alignItems}"
                                         :image-style="data.style.imageStyle"
                                         :image-title-style="data.style.imageTitleFontStyle"
                            ></basic-image>
                        </div>
                    </div>
                </div>
            </click-action>
        </div>
        <div class="minisite-list-item" :style="{width:data.style.common.width+'%'}"></div>
        <div class="minisite-list-item" :style="{width:data.style.common.width+'%'}"></div>
        <div class="minisite-list-item" :style="{width:data.style.common.width+'%'}"></div>
        <div class="minisite-list-item" :style="{width:data.style.common.width+'%'}"></div>
    </div>
</template>

<script>
    import imagesMixin from "./images.mixin"

    export default {
        name: "ImagesTemplate00",
        mixins: [imagesMixin],
        props: {
            data: {}
        },
        computed: {}
    }
</script>

<style lang="less" scoped>

    .minisite-image-00 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .main-item-content {
            width: 100% !important;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
        }

        .left-container {
            width: 100%;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .right-container {
            flex-grow: 1;
            margin-left: 10px;
            vertical-align: top;

            .button-container {
                margin-top: 10px;
            }
        }

        .right-icon {
            margin-left: 10px;
            flex-shrink: 0;
        }

        .image-container {
            display: flex;
            flex-direction: column;
        }
    }

</style>
