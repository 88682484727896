<template>
    <div v-if="listStyle.common" class="minisite-list-11" :class="'template-' + listStyle.templateId">
        <div class="list-search"
             v-show="getShowedComponent && getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              v-model="searchWord"
                              :search-style="listStyle.searchStyle"
            ></search-component>
        </div>
        <div class="list-content">
            <div v-for="(item,itemIndex) in filterData" :key="item.id" class="minisite-list-item"
                 :style="{width:listStyle.common.width+'%'}">
                <click-action class="main-item-content"
                              :style="[getCommonStyle, {flexDirection: listStyle.listStyle.flexDirection.direction}]"
                              :data="item.clickAction" :key="item.id">
                    <div v-show="getShowedComponent.image"
                         class="left-container"
                         :style="[{width:listStyle.imageStyle.width+'%'},getImageStyle, getImageSpacing]">
                        <div class="image-container">
                            <basic-image :data="item.image"
                                         :image-style="listStyle.imageStyle"
                                         :image-title-style="listStyle.imageTitleFontStyle"
                            ></basic-image>
                            <div
                                v-show="listStyle.collectionStyle.listPosition === 'image' && getShowedComponent.collection"
                                class="image-collection"
                                :style="{ backgroundColor: listStyle.collectionStyle.backgroundColor }">
                                <collection
                                    :data="item.button"
                                    @collection-status-change="collectionStatusChange({
                                    item,
                                    collectionId:`${_pageId}_${data.id}_${item.id}`,
                                    showedStatus: getShowedComponent
                                    })"
                                    :item-id="`${_pageId}_${data.id}_${item.id}`"
                                    :collection-style="listStyle.collectionStyle"></collection>
                            </div>
                        </div>
                    </div>
                    <div class="right-container" :style="getTextContentStyle">
                        <div v-show="getShowedComponent.title||getShowedComponent.desc">
                            <div v-show="getShowedComponent.title" class="title-container">
                                <basic-text :data="item.title"
                                            :font-style="listStyle.titleFontStyle"
                                ></basic-text>
                            </div>
                            <div v-show="getShowedComponent.desc" class="desc-container" :style="getDescMargin">
                                <basic-text :data="item.desc"
                                            :font-style="listStyle.descFontStyle"
                                ></basic-text>
                            </div>
                        </div>
                        <div v-show="getShowedComponent.button || getShowedComponent.collection"
                             class="button-container" :style="getButtonMargin">
                            <basic-button-image-collection
                                :data="item.button"
                                @collection-status-change="collectionStatusChange({
                                    item,
                                    collectionId:`${_pageId}_${data.id}_${item.id}`,
                                    showedStatus: getShowedComponent
                                    })"
                                :item-id="`${_pageId}_${data.id}_${item.id}`"
                                :collection-style="listStyle.collectionStyle"
                                :button-style="listStyle.buttonStyle"
                            ></basic-button-image-collection>
                        </div>
                    </div>
                </click-action>
                <div class="divide-line" :style="getDivideStyle"
                     v-show="getShowedComponent.divideLine&&itemIndex<filterData.length-1">
                </div>
            </div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
            <div class="minisite-list-item" :style="{width:listStyle.common.width+'%'}"></div>
        </div>
    </div>
</template>

<script>
    import listMixin from "./list.mixin"

    export default {
        name: "ListTemplate11",
        mixins: [listMixin],
        props: {
            data: {}
        },
        data() {
            return {
                listStyle: {},
                blockHeight: "initial"
            }
        },
        computed: {
            // 获取图片距离左右文本的距离
            getImageSpacing() {
                let classType = "marginLeft"
                if (this.listStyle.listStyle.flexDirection.direction === "row") classType = "marginRight"
                return {
                    [classType]:
                        this.listStyle.common.width <= 47.5 ? "8px" : "12px"
                }
            },
            getDescMargin() {
                return {
                    marginTop:
                        this.listStyle.common.width <= 47.5 ? "2px" : "4px"
                }
            },
            getButtonMargin() {
                return {
                    marginTop:
                        this.listStyle.common.width <= 47.5 ? "4px" : "8px"
                }
            }
        },
        mounted() {
            this.listStyle = this.data.style
        }
    }
</script>

<style lang="less" scoped>

    .minisite-list-11 {

        .list-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .minisite-list-item {
            display: flex;
            flex-direction: column;
        }

        .main-item-content {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            overflow: hidden;
        }

        .left-container {
            flex-grow: 0;
            flex-shrink: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;

            .image-container {
                display: flex;
                align-items: center;
                justify-content: center;

                .image-collection {
                    width: 32px;
                    height: 32px;
                    border-radius: 0 0 4px 4px;;
                    position: absolute;
                    top: 0px;
                    right: 8px;
                }
            }
        }

        .right-container {
            flex-grow: 1;
            vertical-align: top;
            display: flex;
            flex-direction: column;

            .desc-container, .title {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
        }

        .right-icon {
            height: 100%;
            margin-left: 10px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .icon-image {
                max-width: 30px;
            }
        }
    }

</style>
<style lang="less">
    .minisite-list-11 {
        p {
            margin: 0;
        }
    }
</style>
