<template>
    <div class="minisite-tag-list tag-list-02" v-if="showedData" :style="getToggleListStyle">
        <div class="list-search"
             v-show="getShowedComponent.search">
            <search-component @search="search" :settings="data.search"
                              v-model="searchWord"
                              :search-style="showedData.style.searchStyle"
            ></search-component>
        </div>
        <template
            v-if="showedData && showedData.contents&& showedData.contents.length>0">
            <van-tabs
                v-model="activeKey"
                @click="tabChange"
                type="card"
                :color="showedData.style.listStyle.active.background.backgroundColor || `var(--c-site-primary-color-)`"
                :background="showedData.style.listStyle.normal.background.backgroundColor"
                :title-inactive-color="showedData.style.listStyle.normal.color"
                :title-active-color="showedData.style.listStyle.active.color"
                animated
                :ellipsis="false"
                swipeable
                class="custom-tab"
                :lazy-render="false"
                :class="{
                wrap: showedData.style.listStyle.tabs ? showedData.style.listStyle.tabs.wrap.enable : false,
                grow: showedData.style.listStyle.tabs ? showedData.style.listStyle.tabs.wrap.col : false
            }"
                :style="{
                '--active-color': showedData.style.listStyle.background.color || `var(--c-site-primary-color-)`,
                '--c-site-tab-height': `${showedData.style.listStyle.tabs.height}px`
            }">
                <van-tab
                    v-for="item in showedData.contents"
                    :key="item.id"
                    :title="item.title.content"
                    :title-style="{
                    fontSize: showedData.style.listStyle.normal.fontSize + 'px',
                    fontWeight: showedData.style.listStyle.normal.fontWeight ? 'bold' : 'normal',
                    width: getTitleWidth
                }">
                    <inner-list :data="item" :data-source="data.dataSource"
                                :search-word="searchWord"></inner-list>
                </van-tab>
            </van-tabs>
        </template>
    </div>
</template>

<script>
    import tagList from "./tagList.mixin"
    import Vue from "vue"
    import Tabs from "vant/lib/tabs"
    import Tab from "vant/lib/tab"
    import "vant/lib/tabs/style"
    import "vant/lib/tab/style"

    Vue.use(Tab)
    Vue.use(Tabs)

    export default {
        name: "Template-02",
        mixins: [tagList],
        props: {
            data: {}
        },
        computed: {
            getToggleListStyle() {
                return {}
            }
        },
        mounted() {

        }
    }
</script>

<style lang="less">
    .minisite-tag-list {

        .image_resized {
            width: initial;
            display: flex;
            margin: 0;

            img {
                width: 100%;
                height: initial;
            }
        }

        figure {

            &.image {
                margin: 0;
                height: fit-content;
                display: flex;
                align-items: center;

                img {
                    margin: 0 auto;
                }
            }
        }

        .minisite-tag-list-item {
            /*display: flex;*/
            /*flex-direction: column;*/

            .editor-item-content {
                line-height: 2;
            }

            .list-header {
                /*width: 100%;*/
                display: flex;
                align-items: center;
                /*flex-grow: 1;*/

                .list-header-title {
                    flex-grow: 1;
                    vertical-align: middle;
                    line-height: 1.5;
                }

                .list-header-icon {
                    transition: all .3s ease;
                    flex-shrink: 0;
                    max-width: 30px;
                }
            }

            .list-body {
                max-height: 0;
                overflow: hidden;
                transition: height .3s ease;
                padding: 0;
                margin: 0;
            }

            &.closed {
                .list-body {
                    margin: 0 !important;
                    padding: 0 !important;
                    border: none !important;
                }
            }

            &.open {
                .list-body {
                    max-height: inherit;
                }

                .list-header-icon {
                    transform: rotate(90deg);
                }
            }
        }

    }

    .tag-list-02 {
        .van-tabs__nav--card {
            height: var(--c-site-tab-height);
            border: none;
            margin: 0;
        }

        .van-tabs__wrap {
            height: var(--c-site-tab-height);
        }

        .van-tabs__nav--complete {
            padding: 0;
        }

        .van-tab__pane,
        .van-tab__pane-wrapper {
            -webkit-flex-shrink: 0;
            flex-shrink: 0;
            box-sizing: border-box;
            width: 100%;
        }

        .van-tab {
            border: none;
            position: relative;
            transition: none;

            &:before {
                content: " ";
                height: 12px;
                width: 0;
                border-right: 1px solid #d6d6d6;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                transition: none;
            }
        }

        .van-tab:first-child,
        .van-tab--active,
        .van-tab--active + .van-tab {
            &:before {
                content: none;
            }
        }
    }
</style>
